/**
 * @description
 * 设置声音
 */
import { FC } from "react";
import { Row } from "@/components/flex";
import MyButton from "@/components/MyButton";
import Dialog from "@/components/Dialog";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Icon from "@/components/Icon";
import OSwitch from "@/components/OSwitch";
import store from "@/store";
import styled from "styled-components";
import { useSetState } from "ahooks";
import { setAudio, setMusic, setBetType } from "@/store/slices/config.slice";
import { useAppDispatch } from "@/store";
import { useTranslation } from "react-i18next";

interface Props {
  visible?: boolean;
  onClose?: () => void;
}

const Label = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
`;

const SetVoice: FC<Props> = ({ visible, onClose }) => {
  // const { audio, music } = useAppSelector((state) => state.config)
  const [{ music, audio, betType }, setState] = useSetState<any>({
    audio: store.getState().config.audio,
    music: store.getState().config.music,
    betType: store.getState().config.betType,
  });

  // const [{bgMusic, audio}, setState] = useSetState({ bgMusic: true, audio: true });
  // const { audio, music } = useAppSelector((state) => state.config);
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const dispatch = useAppDispatch();
  const { t } = useTranslation()

  const confirm = () => {
    // todo
    dispatch(setMusic(music))
    dispatch(setAudio(audio))
    // dispatch(setBetType(betType))
    setIsShow(false)
  };

  const toggleMusic = (music) => setState({ music });

  const toggleAudio = (audio) => setState({ audio });

  // const toggleBetType = (betType) => setState({ betType });

  return (
    <Dialog
      title={t('setSound:title')}
      visible={isShow}
      buttons={[
        {
          text: t('setSound:buttonCancel'),
          action: () => setIsShow(false)
        },
        {
          text: t('setSound:buttonConfirm'),
          action: confirm
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <div
        style={{
          width: 420,
          margin: "24px 24px 0",
          padding: ' 38px 0 46px',
          borderRadius: 8,
          backgroundColor: "#1D1D1D",
        }}
      >
        <Row
          justify="space-between"
          ailgn="center"
          style={{ padding: "0 102px" }}
        >
          <Label>
            <Icon
              type="icon-sound"
              color="#fff"
              style={{ marginRight: "10px" }}
            />
            {t('setSound:music')}
          </Label>
          <OSwitch status={music} onToggle={toggleMusic}/>
        </Row>
        <Row
          justify="space-between"
          ailgn="center"
          style={{ padding: "0 102px", marginTop: "38px" }}
        >
          <Label>
            <Icon
              type="icon-sound"
              color="#fff"
              style={{ marginRight: "10px" }}
            />
            {t('setSound:sound')}
          </Label>
          <OSwitch status={audio} onToggle={toggleAudio}/>
        </Row>
        {/* <Row
          justify="space-between"
          ailgn="center"
          style={{ padding: "0 102px", marginTop: "38px" }}
        >
          <Label>
            <Icon
              type="icon-sound"
              color="#fff"
              style={{ marginRight: "10px" }}
            />
            投注
          </Label>
          <OSwitch status={betType} onToggle={toggleBetType}/>
        </Row> */}
      </div>
      {/* <Row */}
      {/*   style={{ */}
      {/*     width: "325px", */}
      {/*     margin: "10px auto 18px", */}
      {/*     padding: "15px 10px", */}
      {/*   }} */}
      {/*   justify="center" */}
      {/*   ailgn="center" */}
      {/* > */}
      {/*   <MyButton */}
      {/*     cancel */}
      {/*     name={t('setSound:buttonCancel')} */}
      {/*     width={140} */}
      {/*     onClick={() => setIsShow(false)} */}
      {/*   /> */}
      {/*   <MyButton */}
      {/*     styles={{ marginLeft: "25px" }} */}
      {/*     width={140} */}
      {/*     name={t('setSound:buttonConfirm')} */}
      {/*     onClick={confirm} */}
      {/*   /> */}
      {/* </Row> */}
    </Dialog>
  );
};

export default SetVoice;
