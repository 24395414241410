export const ROAD_CONFIG = {
  breadplate: {
    rows: 6,
    cols: 18,
  },
  bigroad: {
    rows: 6,
    cols: 46,
  },
  bigeyeboy: {
    rows: 6,
    cols: 92,
  },
  smallroad: {
    rows: 6,
    cols: 46,
  },
  cockroachPig: {
    rows: 6,
    cols: 46,
  },
  cellWidth: 1360 / 34,
  gridLineWidth: 1,
};

// nats连接的默认配置 主要用于配置断线重连
export const WS_CONNECT_CONFIG = {
  /** 是否进行重连 是 */
  reconnect: true,
  /** ping的时间间隔 */
  pingInterval: 5000,
  /** 如果disconnected 进行下次重连的时间间隔 */
  reconnectTimeWait: 3000,
  /** 最大重连次数 */
  maxReconnectAttempts: 10,
  /** 客户端等待与服务端建立ws连接的超时时间 */
  timeout: 10000,
};
