import { t } from "i18next";

/** 桌台状态 */
export enum DeskStatus {
  /**  禁用 */
  CloseDesk = 1,
  /**  游戏中 */
  GamingDesk = 2,
  /**  维护中 */
  MaintainDesk = 3,
  /**  换靴 */
  ChangingCardDesk = 4,
}

/** 庄 闲 和 庄对 闲对 */
export enum BetType {
  BANKER = "banker",
  PLAYER = "player",
  TIE = "tie",
  PDOUBLE = "playerDouble",
  BDOUBLE = "bankerDouble",
}

export type Shoes = "banker" | "player" | "tie" | "playerDouble" | "bankerDouble";

/** 游戏类型 */
export enum GameMod {
  /** 快速 */
  FastGame = 1,
  /** 传统 */
  BasicGame = 2,
  /** 包台 */
  VipGame = 3,
}

/** 豪华主题 */
export enum LuxuryTheme {
  /** 快速 */
  blue,
  green,
  purple,
  red,
}

/** 申请专属服务的状态 */
export enum ApplyServiceStatus {
  /** 传统模式需要申请 */
  NotApply = 1,
  /** 已经申请 */
  Applying = 2,
  /** 申请已经通过 */
  Passed = 3,
  /** 申请失败 */
  Failed = 4,
}

/** 好路推荐类型 */
export enum HotRoadType {
  /** 长庄 */
  LB = "lb",
  /** 长闲 */
  Lp = "lp",
  /** 单跳 */
  Dt = "dt",
  /** 双跳 */
  St = "st",
  /** 一房两厅 */
  Tf = "tf",
  Bt = "bt",
  /** 逢庄跳 */
  Pt = "pt",
  /** 逢闲跳 */
  Bl = "bl",
  Pl = "pl",
  Ll = "ll",
  Gb = "gb",
  Gp = "gp",
}

export const HotRoadMaps: Record<HotRoadType, string> = {
  [HotRoadType.LB]: "goodRoadTips:roadMapChangzhuangTitle",
  [HotRoadType.Lp]: "goodRoadTips:roadMapLongLeisureTitle",
  [HotRoadType.Dt]: "goodRoadTips:roadMapSingleHopTitle",
  [HotRoadType.St]: "goodRoadTips:roadMapDoubleJumpTitle",
  [HotRoadType.Tf]: "goodRoadTips:roadMap1hall2BedroomsTitle",
  [HotRoadType.Bt]: "goodRoadTips:roadMapFengzhuangDanceTitle",
  [HotRoadType.Pt]: "goodRoadTips:roadMapEveryFreeDanceTitle",
  [HotRoadType.Bl]: "goodRoadTips:roadMapFengzhuangGuiTitle",
  [HotRoadType.Pl]: "goodRoadTips:roadMapEveryFreeTimeTitle",
  [HotRoadType.Ll]: "goodRoadTips:roadMapPatChiTitle",
  [HotRoadType.Gb]: "goodRoadTips:roadMapGeqiZhuangTitle",
  [HotRoadType.Gp]: "goodRoadTips:roadMapFreeTimeTitle",
};

/** 桌台信息 */
export interface DeskInfo {
  /** 厅名称 */
  club_name: string;
  club_id: number;
  /** 申请专属服务 */
  apply_basic_ing: ApplyServiceStatus;
  /** 桌id */
  desk_id: number;
  /** 限红 */
  desk_limits: any[];
  desk_no: string;
  /** 桌台状态 */
  desk_status: DeskStatus;
  /** 游戏类型 */
  game_mod: GameMod;
  game_no: string;
  seat_no: number;
  /** 统计数据 */
  game_result_statistics: Record<string, number>;
  history_result: string;
  /** 投注倒计时 */
  stop_bet_at: number;
  /** 视频直播流 flv */
  streaming_url1: string;
  streaming_url2: string;
  lastBet?: any[];
  videl_urls?: any[];
  TimingBet?: number;
  /** 当桌是否已经飞牌 */
  flyCard?: boolean;
  player_bet_info: any[];
}

/** 开奖结果 */
export interface LotteryResult {
  /** 桌台号 */
  desk_no: string;
  desk_id: number;
  /** 牌局结果 */
  game_result: string;
  /** 闲 庄点 */
  b_point: number;
  p_point: number;
  /** 闲 庄牌 */
  b_cards: string[];
  p_cards: string[];
}

/** 好路推荐的 */
export interface HotRoadProps {
  id: string;
  desk_no: string;
  desk_id: number;
  stop_bet_at: number;
  TimingBet: number;
  hot_load_type: string;
  game_no: string;
  game_mod: number;
  history_result: string;
}

/** 多台模式玩家缓存 */
export type MultiDesksParams = Record<string, number[]>;
