// WS事件列表
export const WS_Event = {
  /** 游戏大厅台桌事件 */
  LobbyGameEvent: "lobby_game_event",
  /** 好路推荐事件 */
  HotLoadEvent: "hot_load_event",
  /** 牌局开奖结果 */
  GameResult: "game_result_event",
  /** 牌局倒计时事件 */
  StartGameBet: "start_game_event",
  /** 停止投注 */
  EndBet: "end_bet_event",
  /** 踢出房间进大厅 */
  OutDesk: "out_desk_event",
  /** 玩家进入房间事件 */
  IntoGamingDeskEvent: "into_desk_event",
  /** 玩家离开房间事件 */
  LeavingDeskEvent: "leaving_desk_event",
  /** 桌台输赢 */
  DeskResultCountEvent: "desk_result_count_event",
  /** 玩家牌桌投注事件 */
  DeskBettingsEvent: "desk_bettings_event",
  // 处理申请专属服务
  DealWithBasicApply: "deal_with_basic_apply_event",
  // 登录挤下线事件
  OutLoginEvent: "out_login_event",
  /** 桌台状态改变事件 维护 包桌 需要踢出用户 */
  DeskStatusChangeEvent: "desk_state_change_event",
  /** 枪手拒绝订单 */
  RejectedOrderEvent: "rejected_order_event",
  /** 代客投注 */
  BetByOwnWokerEvent: "bet_by_own_woker_event",
  // 投手接受飞牌
  AcceptedFreeGameEvent: "accepted_free_game_event",
  // 投手拒绝飞牌
  RefusedFreeGameEvent: "refused_free_game_event",
  /** 投手收工 */
  BetterEndWork: "better_end_work",
  /** 玩家撤注事件 */
  RevokeBettings: "revoke_bettings_event",
  /** 余额变化事件 */
  PlayerBalance: "player_balance",
  /** 传统模式 枪手处理换桌 */
  BetterDealChangeEvent: "better_deal_change_event",
  /** 传统模式 枪手申请换桌 */
  BetterApplyChangeEvent: "better_apply_change_event",
  /** 包桌状态改变 */
  SetVipEvent: 'set_vip_event',
};

// 游戏结果推送参数
export interface GameResultParams {
  desk_id: number;
  desk_no: string;
  game_result: "bank" | "player" | "tie";
  b_point: number;
  p_point: number;
  b_cards: string[];
  p_cards: string[];
}

export interface BetterEndWorkParams {
  player_id: string;
  desk_no: string;
}

// 大厅牌桌信心推送
interface LobbyGameParams {
  desk_id: number;
  desk_no: string;
  game_no: string;
  club_name: string;
  /** 当前靴的历史牌局输赢 */
  game_history_result: string;
  /** 输赢统计 */
  game_result_statistics: string;
  desk_limits: any[];
  desk_players: number;
  stop_bet_at: number;
}

interface DeskGameTypeStatisticsParams {
  bank: number;
  player: number;
  tie: number;
  bpair: number;
  ppair: number;
  game_count: number;
}

export interface DeskPlayerInfoParams {
  desk_no: string;
  desk_id: number;
  player_id: string;
  player_account: string;
  seat_no: number;
  balance: number;
  nickname: string;
  currency_id: number;
}

export interface RejectedOrderDetail {
  bet_amount: string;
  bet_id: number;
  bet_no: string;
  desk_id: number;
  game_type: number;
  player_account: string;
  seat_no: number;
  currency_id: number;
}

export interface RejectedOrderParams {
  rejected_orders: RejectedOrderDetail[];
}

export interface DeskBettingsParams {
  desk_no: string;
  desk_id?: string;
  player_account: string;
  nickname: string;
  seat_no: number;
  bet_amount: number;
  game_type: number;
  currency_id?: number;
}
// HotRoadType
export interface HotLoadEventParams {
  desk_no: string;
  desk_id: number;
  hot_load_type: string;
  history_result: string;
  game_no: string;
  game_mod: number;
  stop_bet_at: number;
  TimingBet: number;
}

// 申请专属服务 ws
export interface ApplyServiceParams {
  apply_id: number;
  apply_status: number; // 1:未接受 2:已接受 3:已拒绝 4:取消申请
  better_id: number;
  chips: number;
  desk_id: number;
  desk_no: string;
  member_account: string;
  member_id: string;
  stop_bet_at: number;
}

export interface RefusedFreeGameParams {
  desk_no: string;
  player_account: string;
  player_id: string;
  game_no: string;
}

export interface RevokeBettingsParams {
  player_id: number;
  player_account: string;
  desk_no: string;
  game_no: string;
}

export interface ApplyChangeDeskParams {
  player_id: string;
  old_club_id: number;
  new_club_id: number;
  new_desk_id: number;
  old_desk_id: number;
  new_desk_no: string;
  old_desk_no: string;
  /** 是否换厅 */
  is_change_club: number; // 1 换厅  2 不换
}

export interface DealChangeDeskParams extends ApplyChangeDeskParams {
  state: number; // 1 同意  2拒绝
}
