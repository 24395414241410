import Dialog from "@/components/Dialog";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import { FC } from "react";
import styled from "styled-components";
// import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import { useTranslation } from "react-i18next";

const DlgTitle = styled.h3`
  padding: 45px 30px;
  width: 368px;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  text-align: center;
`;

export interface Props {
  title?: string;
  content?: any;
  cancel?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  visible?: boolean;
  onClose?: () => void;
  showCloseIcon?: boolean;
}

const Confirm: FC<Props> = (props) => {
  const { showCloseIcon = true } = props;
  const { isShow, setIsShow, close } = useDlgHooks({ visible: props.visible, onClose: props.onClose });
  const { t } = useTranslation();
  const cancel = () => {
    setIsShow(false);
    props.onCancel && props.onCancel();
  };
  const confirm = () => {
    setIsShow(false);
    props.onConfirm && props.onConfirm();
  };
  return (
    <Dialog
      title={props.title ?? ""}
      visible={isShow}
      showCloseIcon={showCloseIcon}
      buttons={[
        props.cancel && {
          text: t("buttonCancel"),
          action: cancel,
        },
        {
          text: t("buttonConfirm"),
          action: confirm,
        },
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <DlgTitle>{props.content}</DlgTitle>
      {/* <Row style={{ width: '325px', margin: '10px auto 18px', padding: '15px 10px' }} justify="center" ailgn="center"> */}
      {/*   {props.cancel && <MyButton cancel name={t('buttonCancel')} width={140} onClick={cancel}/>} */}
      {/*   <MyButton styles={{ marginLeft: props.cancel ? '25px' : '0' }} width={140} name={t('buttonConfirm')} */}
      {/*     onClick={confirm}/> */}
      {/* </Row> */}
    </Dialog>
  );
};

export default Confirm;
