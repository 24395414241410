import { history } from "@/routers";
import { gameAPI } from "@/services";
import { RootState } from "@/store";
import { flatHotRoadList } from "@/utils/functions";
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { BetType, DeskInfo, HotRoadProps, Shoes } from "../types/bet.d";
import { RoadProps } from "./mock";

export enum DeskStatus {
  COUNT = "COUNT",
  DISABLE = "DISABLE",
}

export enum DeskResult {
  BANKER = "BANKER",
  PLAYER = "PLAYER",
  TIE = "TIE",
  NONE = "",
}

interface BetItem {
  bet_amount: number;
  desk_no: string;
  game_mod: number;
  game_type: number;
}

interface State {
  showResult: DeskResult;
  /** 桌台状态 倒计时 停止下注 */
  deskStatus: DeskStatus;
  chipsStack: Record<Shoes, number[]>;
  /** 是否处于开奖 */
  isNowLottery: boolean;
  /** 是否已经飞牌 */
  flyCard: boolean;
  /** 截止目前所有的开奖 */
  currentResult: string;
  // tableList: any[];
  /** 大厅所有桌台列表 */
  lobbyDesksInfo: any;
  /** 换桌所有桌台列表 */
  changeDesksInfo: any;
  /** 好路推荐 */
  recommendRoad: RoadProps[];
  /** 桌台展示 */
  showTablePick: boolean;
  showWaitConfirm: boolean;
  /** 大厅桌台选择筛选器 */
  deskPickOptions: {
    club_id: number;
    game_mod: number;
    sort_rule: string;
  };
  /** 换桌桌台选择筛选器 */
  deskPickOptions2: {
    club_id: number;
    game_mod: number;
    sort_rule: string;
  };
  /** 展示开奖结果 */
  lotteryResult: BetType[];
  gameResult: any;
  /** 当前桌台信息 */
  deskInfo: DeskInfo;
  /** 玩家上次投注内容 */
  lastBetContent: BetItem[];
  hotRoadList: HotRoadProps[];
  announce?: any[];
  isinit: boolean;
  specialServiceFlag: boolean;
  reConnecting: boolean;
  betType: boolean;
  quickBetDeskNos: string[];
}

const name = "bet";

// 获取大厅所有桌台
export const fetchLobbyDesks = createAsyncThunk("game/getAlldesks", async (params: any, thunkAPI) => {
  const response = await gameAPI.getAlldesks(params);
  return { ...response, params };
});
// 获取换桌所有桌台
export const fetchChangeDeskList = createAsyncThunk("game/getChangeDeskList", async (params: any, thunkAPI) => {
  const response = await gameAPI.getAlldesks(params);
  return { ...response, params };
});

// 获取游戏公告
export const getGameAnnouncement = createAsyncThunk("game/getGameAnnouncement", async (params: any, thunkAPI) => {
  const response = await gameAPI.getGameAnnouncement(params);
  return response;
});

// 获取玩家上一次投注信息
export const getLastBet = createAsyncThunk("game/getLastBet", async (params?: any) => {
  const response = await gameAPI.getLastBet(params);
  return response;
});

// 获取好路推荐列表
export const getHotRoadList = createAsyncThunk("game/getHotRoadList", async (params?: any) => {
  const response = await gameAPI.getHotRoadList(params);
  return response;
});

// 获取玩家所在赌桌的信息
export const getGameDeskById = createAsyncThunk("game/getGameDeskById", async (params: any, thunkAPI) => {
  try {
    const response = await gameAPI.getGameDeskById(params);
    return response;
  } catch (err) {
    if (err.msg === "g-10015") {
      history.replace("/tablePick");
      return err;
    }

    return err;
  }
});

// 换桌的时候进桌调用
export const changeEnterDesk = createAsyncThunk("game/changeEnterDesk", async (params: any, thunkAPI) => {
  try {
    const response = await gameAPI.changeEnterDesk(params);
    return response;
  } catch (err) {
    if (err.msg === "g-10015") {
      history.replace("/tablePick");
      return err;
    }

    return err;
  }
});

const initialState: State = {
  // tableList,
  deskStatus: DeskStatus.DISABLE,
  flyCard: false,
  chipsStack: {
    playerDouble: [],
    tie: [],
    bankerDouble: [],
    player: [],
    banker: [],
  },
  showTablePick: false,
  showWaitConfirm: false,
  isNowLottery: false,
  isinit: false,
  currentResult: "tpbppbbtttwefijkbpbghijkbbpt",
  showResult: DeskResult.NONE,
  recommendRoad: [],
  gameResult: {},
  lobbyDesksInfo: {},
  changeDesksInfo: {},
  deskInfo: {}! as DeskInfo,
  deskPickOptions: {
    // 这是大厅的赛选条件
    club_id: 0,
    game_mod: 0,
    sort_rule: "",
  },
  deskPickOptions2: {
    // 这是换桌时候的筛选条件
    club_id: 0,
    game_mod: 0,
    sort_rule: "",
  },
  lastBetContent: [],
  announce: [],
  hotRoadList: [],
  lotteryResult: [] as BetType[],
  specialServiceFlag: true,
  reConnecting: false,
  betType: false,
  quickBetDeskNos: [],
};

const betSlice = createSlice({
  name,
  initialState,
  reducers: {
    setShowResult(state, action: PayloadAction<DeskResult>) {
      state.showResult = action.payload;
    },
    setQuickBetDeskNos(state, action: PayloadAction<string[]>) {
      state.quickBetDeskNos = action.payload;
    },
    setLotteryResult(state, action: PayloadAction<BetType[]>) {
      state.lotteryResult = action.payload;
    },
    setDeskStatus(state, action: PayloadAction<DeskStatus>) {
      state.deskStatus = action.payload;
    },
    setFlyCard(state, action: PayloadAction<boolean>) {
      state.flyCard = action.payload;
    },
    setDeskInfo(state, action: PayloadAction<DeskInfo>) {
      state.deskInfo = action.payload;
    },
    setGameResult(state, action: PayloadAction<any>) {
      state.gameResult = action.payload;
    },
    setInit(state, action: PayloadAction<boolean>) {
      state.isinit = action.payload;
    },
    setLastBetContent(state, action: PayloadAction<BetItem[]>) {
      state.lastBetContent = action.payload;
    },
    setChipsStack(state, action: PayloadAction<typeof initialState.chipsStack>) {
      state.chipsStack = action.payload;
    },
    resetChipsStack(state) {
      state.chipsStack = {
        playerDouble: [],
        tie: [],
        bankerDouble: [],
        player: [],
        banker: [],
      };
    },
    setIsLottery(state, action: PayloadAction<boolean>) {
      state.isNowLottery = action.payload;
    },
    setShowWaitConfirm(state, action: PayloadAction<boolean>) {
      state.showWaitConfirm = action.payload;
    },
    setReConnecting(state, action: PayloadAction<boolean>) {
      state.reConnecting = action.payload;
    },
    setLobbyDesksInfo(state, action: PayloadAction<any>) {
      state.lobbyDesksInfo = action.payload;
    },
    setChangeDesksInfo(state, action: PayloadAction<any>) {
      state.changeDesksInfo = action.payload;
    },
    setDeskPickOptions(state, action: PayloadAction<any>) {
      state.deskPickOptions = action.payload;
    },
    setDeskPickOptions2(state, action: PayloadAction<any>) {
      state.deskPickOptions2 = action.payload;
    },
    setShowTablePick(state, action: PayloadAction<boolean>) {
      if (!action.payload) {
        state.deskPickOptions2 = {
          club_id: 0,
          game_mod: 0,
          sort_rule: "",
        }
      }
      state.showTablePick = action.payload;
    },
    setCurrentResult(state, action: PayloadAction<string>) {
      state.currentResult = action.payload;
    },
    addToRecommendRoad(state, action: PayloadAction<RoadProps>) {
      state.recommendRoad = [...state.recommendRoad, action.payload];
    },
    addToHotRoad(state, action: PayloadAction<HotRoadProps>) {
      state.hotRoadList = [...state.hotRoadList, action.payload];
    },
    setHotRoadList(state, action: PayloadAction<HotRoadProps[]>) {
      state.hotRoadList = action.payload;
    },
    removeRecommendRoad(state, action: PayloadAction<string>) {
      console.log(action.payload, "action.payloadaction.payload");
      state.recommendRoad = state.recommendRoad?.filter((item) => item.id !== action.payload);
    },
    removeRecommendRoadByDeskNo(state, action: PayloadAction<string>) {
      state.recommendRoad = state.recommendRoad?.filter((item) => item.deskNo !== action.payload);
    },
    removeRecommendRoadByDeskId(state, action: PayloadAction<number>) {
      state.recommendRoad = state.recommendRoad?.filter((item) => item.deskId !== action.payload);
    },
    removeHotRoadByDeskId(state, action: PayloadAction<number>) {
      state.hotRoadList = state.hotRoadList?.filter((item) => item.desk_id !== action.payload);
    },
    setSpecialServiceFlag(state, action: PayloadAction<boolean>) {
      state.specialServiceFlag = action.payload;
    },
    setAnnounces(state, action: any) {
      state.announce = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLobbyDesks.fulfilled, (state, action: PayloadAction<any>) => {
        const params = { ...action.payload.params }
        const { club_id, game_mod, sort_rule } = state.deskPickOptions
        if (params.club_id === club_id && params.game_mod === game_mod && params.sort_rule === sort_rule) {
          delete action.payload.params
          state.lobbyDesksInfo = action.payload;
        }
      })
      .addCase(fetchChangeDeskList.fulfilled, (state, action: PayloadAction<any>) => {
        const params = { ...action.payload.params }
        const { club_id, game_mod, sort_rule } = state.deskPickOptions2
        if (params.club_id === club_id && params.game_mod === game_mod && params.sort_rule === sort_rule) {
          delete action.payload.params
          state.changeDesksInfo = action.payload;
        }
      })
      .addCase(getGameDeskById.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload && action.payload?.data && action.payload?.msg === "success")
          state.deskInfo = action.payload.data;
      })
      .addCase(changeEnterDesk.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload && action.payload?.data && action.payload?.msg === "success")
          state.deskInfo = action.payload.data;
      })
      .addCase(getGameAnnouncement.fulfilled, (state, action: PayloadAction<any>) => {
        state.announce = action.payload.data?.list || [];
      })
      .addCase(getHotRoadList.fulfilled, (state, action: PayloadAction<any>) => {
        // const data = [
        //   {
        //     hot_load_type: "lb,lp,",
        //     history_result: "bwqw",
        //     desk_id: 65,
        //     desk_no: "MB583",
        //     game_mod: 1,
        //     game_no: "05",
        //     desk_status: 2,
        //     TimingBet: 20,
        //   },
        // ]
        state.hotRoadList = flatHotRoadList(action.payload?.data || []);
      })
      .addCase(getLastBet.fulfilled, (state, action: PayloadAction<any>) => {
        const { msg, data } = action.payload;

        if (msg === "success") {
          state.lastBetContent = data;
        }
      });
  },
});

export const selectChipsStack = (state: { [x: string]: any }) => state.bet.chipsStack;
export const selectIsLottery = (state: { [x: string]: any }) => state.bet.isNowLottery;
export const selectCurrentResult = (state: { [x: string]: any }) => state.bet.currentResult;

export const betSelector = createSelector(
  (rootState: RootState) => rootState[name],
  (state) => state
);

export const {
  setChipsStack,
  setInit,
  setFlyCard,
  setLastBetContent,
  setGameResult,
  setLotteryResult,
  resetChipsStack,
  setIsLottery,
  setShowWaitConfirm,
  setDeskStatus,
  setDeskInfo,
  setShowTablePick,
  setLobbyDesksInfo,
  setChangeDesksInfo,
  setDeskPickOptions,
  setDeskPickOptions2,
  addToRecommendRoad,
  addToHotRoad,
  setHotRoadList,
  removeRecommendRoad,
  removeRecommendRoadByDeskNo,
  removeRecommendRoadByDeskId,
  removeHotRoadByDeskId,
  setSpecialServiceFlag,
  setReConnecting,
  setAnnounces,
} = betSlice.actions;

export const betPersistConfig = {
  key: "bet",
  storage,
  whitelist: ["testKey"],
};

export default betSlice;
