import React, { FC } from "react";
import Dialog from "@/components/Dialog";
import { useTranslation } from "react-i18next";
import { useDlgHooks } from "./hooks";
import styled from "styled-components";
import { quitTraditionMod } from "@/services/game";
import { message } from "@/components/Message";

interface Props {
  visible?: boolean;
  onClose?: () => void;
  enterGame?: () => void;
  deskIn: string;
  deskOut: string;
}

const Text = styled.p`
  font-size: 18px;
  /* max-width: 200px; */
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;

  span {
    color: yellowgreen;
  }
`;

const ChangeTradition: FC<Props> = ({ visible, onClose, enterGame, deskIn, deskOut }) => {
  const { t } = useTranslation();
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });

  const quitTradition = async (desk_no) => {
    try {
      console.log(desk_no, "desk_nodesk_nodesk_no");
      const { msg } = await quitTraditionMod({ desk_no, _isNeedLoading: true });

      if (msg === "success") {
        message.success({ message: t("game:playerExistTraditionSuccess"), onAnimationend: confirm });
      }
    } catch (err) {}
  };

  const confirm = () => {
    setIsShow(false);
    enterGame();
  };

  return (
    <Dialog
      title={t("game:switchTraditionTable")}
      visible={isShow}
      buttons={[
        {
          text: t("modalChipChoose:buttonCancel"),
          action: () => setIsShow(false),
        },
        {
          text: t("modalChipChoose:buttonConfirm"),
          action: () => quitTradition(deskOut),
        },
      ]}
    >
      <Text>
        <div>
          {t("game:exitTableOrNot", { deskNo: deskOut })}
          {/* 是否退出<span>{deskOut}</span>的桌台 */}
        </div>
        <div>
          {t("game:reapplyTraditionMode", { deskNo: deskIn })}
          {/* 重新申请<span>{deskIn}</span>传统电投专属服务 */}
        </div>
      </Text>
    </Dialog>
  );
};

export default ChangeTradition;
