import { useEffect, useRef, useState } from "react";

const useCount = (count: number, interval: number) => {
  const [remain, setRemain] = useState(count);
  const timer = useRef(null);

  function stop() {
    clearTimeout(timer.current);
    timer.current = null;
  }

  useEffect(() => {
    if (remain >= 1) {
      timer.current = setTimeout(() => setRemain(remain - 1), interval);
    }

    return stop;
  }, [remain]);

  return {
    stop,
    remain,
  };
};

export default useCount;
