import Dialog from "@/components/Dialog";
import { Column, Row } from "@/components/flex";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Icon from "@/components/Icon";
import OSwitch from "@/components/OSwitch";
import { hotRoadRecommend } from "@/config/const";
import { useAppDispatch, useAppSelector } from "@/store";
import { setRoadTipOpen } from "@/store/slices/config.slice";
import { IStorage } from "@/utils";
import { Checkbox, SxProps } from "@mui/material";
import { useSetState } from "ahooks";
import isEmpty from "lodash/isEmpty";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const checkedSx: SxProps = {
  color: "#D2AF6E",
  "&": {
    padding: 0,
    marginLeft: "-2px",
  },
  "& .MuiSvgIcon-root": { fontSize: 24 },
  "&.Mui-checked": {
    color: "#D2AF6E",
  },
};
const Item = styled.div`
  margin-top: 24px;
  margin-right: 24px;
  position: relative;
  box-sizing: content-box;
  width: 120px;
  padding: 12px 13px;
  background-color: #201e1b;
  border-radius: 4px;
`;
const Img = styled.img`
  width: 100%;
  background-color: #fff;
`;

const Label = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;
const TipsBox = styled(Column)`
  position: absolute;
  left: 0;
  top: 0;

  i {
    display: block;
    width: 44px;
    height: 35px;
    background: #211f1c;
    border-top-right-radius: 4px;
  }
`;
const Content = styled.div`
  display: block;
  background: #211f1c;
  width: 146px;
  height: 109px;
  padding: 15px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  word-break: break-all;
`;

interface ItemProps {
  title?: string;
  src?: string;
  checked?: boolean;
  tips?: string;

  // hotType: HotRoadType;
  [key: string]: any;
}

const RoadItem: FC<ItemProps> = ({ title = "", src = "", index, checked = false, tips = "", onToggle }) => {
  const [hover, setHover] = useState(false);

  const onChange = (e, check) => {
    console.log(check, "checkcheckcheckcheck");
    onToggle(index, check);
  };

  return (
    <Item>
      <Row ailgn="center" justify="space-between" style={{ width: "100%", paddingBottom: "2px" }}>
        <Checkbox checked={checked} sx={checkedSx} onChange={onChange}></Checkbox>
        <p style={{ fontWeight: "400", fontSize: "15px", color: "#fff" }}>{title}</p>
        <Icon
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          type="icon-alert-circle-rect"
          color="#D2AF6E"
          style={{ cursor: "pointer", marginRight: "-3px", position: "relative", zIndex: "100" }}
        />
      </Row>
      <Img src={src} />
      {hover && (
        <TipsBox ailgn="flex-end">
          <i></i>
          <Content>{tips}</Content>
        </TipsBox>
      )}
    </Item>
  );
};

interface Props {
  visible?: boolean;
  onClose?: () => void;
}

const RoadTips: FC<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  // console.log(IStorage.getItem("roadTipMap"), 'IStorage.getItem(但是水电费第三方第三方的算法.getItem("roadTipMap")')
  const dispatch = useAppDispatch();
  const { roadTipOpen } = useAppSelector((state) => state.config);

  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const [{ roadMap, showRoadTip }, setState] = useSetState<any>({
    roadMap: [],
    showRoadTip: roadTipOpen,
  });

  const isAllChecked = useMemo(() => roadMap.filter((road) => !road.checked).length === 0, [roadMap]);

  useEffect(() => {
    (async () => {
      try {
        const data = await IStorage.getItem("roadTipMap");
        const maps = isEmpty(data) ? hotRoadRecommend : data;
        setState({ roadMap: maps });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const toggleRoadCheckedAll = () => {
    setState({
      roadMap: roadMap.map((road) => ({
        ...road,
        checked: isAllChecked ? false : true,
      })),
    });
  };

  const toggleRoadChecked = (index, check) => {
    const tempRoadMap = [...(roadMap as any)];
    const chooseMap = tempRoadMap[index];
    tempRoadMap[index] = {
      ...chooseMap,
      checked: check,
    };
    setState({
      roadMap: tempRoadMap as any,
    });
  };

  const confirm = () => {
    console.log(1111, roadMap);
    IStorage.setItem("roadTipMap", roadMap);
    dispatch(setRoadTipOpen(showRoadTip));
    setIsShow(false);
  };

  return (
    <Dialog
      title={t("goodRoadTips:title")}
      visible={isShow}
      buttons={[
        {
          text: t("goodRoadTips:buttonCancel"),
          action: () => setIsShow(false),
        },
        {
          text: t("goodRoadTips:buttonConfirm"),
          action: confirm,
        },
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <div style={{ padding: "0 72px" }}>
        <Row justify={"space-between"} style={{ margin: "20px 0" }}>
          <Label>
            全选:
            <OSwitch style={{ marginLeft: "22px" }} status={isAllChecked} onToggle={toggleRoadCheckedAll} />
          </Label>
          <Label>
            {t("goodRoadTips:tipSwitch")}
            <OSwitch
              style={{ marginLeft: "22px" }}
              status={showRoadTip}
              onToggle={() => setState({ showRoadTip: !showRoadTip })}
            />
          </Label>
        </Row>
        <div style={{ display: "flex", flexWrap: "wrap", width: 680, marginTop: -24, marginRight: -24 }}>
          {roadMap &&
            Array.isArray(roadMap) &&
            roadMap?.map((item, index) => (
              <RoadItem
                key={index}
                src={item.imgSrc}
                title={t(item.title)}
                tips={t(item.tips)}
                index={index}
                checked={item.checked}
                onToggle={toggleRoadChecked}
              />
            ))}
        </div>
      </div>
      {/* <Row style={{ width: "325px", margin: "10px auto 18px", padding: "15px 10px" }} justify="center" ailgn="center"> */}
      {/*   <MyButton cancel name={t("goodRoadTips:buttonCancel")} width={140} onClick={() => setIsShow(false)} /> */}
      {/*   <MyButton */}
      {/*     styles={{ marginLeft: "25px" }} */}
      {/*     width={140} */}
      {/*     name={t("goodRoadTips:buttonConfirm")} */}
      {/*     onClick={confirm} */}
      {/*   /> */}
      {/* </Row> */}
    </Dialog>
  );
};

export default RoadTips;
