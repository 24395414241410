import store, { useAppSelector } from "@/store";
import { useMemo } from "react";
import { chipsUnit, chipsUnitKRW } from "@/utils/tool";

const useTradeType = () => {
  const { currentTradeType } = useAppSelector((state) => state.auth);

  // 是否是韩币交收方案
  const isKRW = useMemo(() => currentTradeType?.currency_type === 3, [currentTradeType]);

  return isKRW;
};

export const useChipsUnit = () => {
  const { currentTradeType } = useAppSelector((state) => state.auth);

  // 是否是韩币交收方案
  const isKRW = useMemo(() => currentTradeType?.currency_type === 3, [currentTradeType]);

  return isKRW ? chipsUnitKRW : chipsUnit;
};

export default useTradeType;
