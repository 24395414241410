import { message } from "@/components/Message";
import { hotRoadRecommend } from "@/config/const";
import { history } from "@/routers";
import { gameAPI } from "@/services";
import { quitTraditionMod } from "@/services/game";
import store from "@/store";
import { getBalance } from "@/store/slices/auth.slice";
import { fetchLobbyDesks, getHotRoadList, setChipsStack } from "@/store/slices/bet.slice";
import { setMultiDeskConfig } from "@/store/slices/config.slice";
import { setChipsStack as setChipsStackLuxury, setQuickBet } from "@/store/slices/luxury.slice";
import { setChipsStack as setChipsStackMulti, setCurrentDesk } from "@/store/slices/multi.slice";
import { BetType, HotRoadProps, Shoes } from "@/store/types/bet.d";
import { t } from "i18next";
import isEmpty from "lodash/isEmpty";
import { CALC } from "..";
import { DeskProps } from "../hooks/useBetInfo";
import {
  Communicator,
  betIntercept,
  betInterceptMulti,
  canAddChipsNew,
  formatAmount,
  getBeishu,
  getNumberPart,
  getPlayerBets,
  getUuid,
  isBeyondLimitNew,
  isOrderInValid,
  resultMaps,
  resultMapsReverse,
  retain,
  seatNos,
  sumChipsAmount,
  sumNumberPart,
  transformBet,
} from "../tool";
import { RejectedOrderDetail, RejectedOrderParams } from "../wsEvent";
import { enterClassicDesk, enterLuxuryDesk, leaveDesk } from "./enterDesk";

interface Bets {
  category: BetType;
  deskLimit: any[];
  betContent: number[];
}

export const limitMsgs = {
  type1: "limit over banker - player",
  type2: "limit over playerSelf",
  type3: "bet amount not enough",
  type4: "bet amount cannot repeat",
  type5: "bet amount incorrect",
};

// 往游戏桌添加筹码
function setChipsToStack(chipsArr: number[], category: BetType, isLuxury = false) {
  const { chipsStack: chipsStackLuxury } = store.getState().luxury;
  const {
    chipsStack,
    deskInfo: { seat_no },
  } = store.getState().bet;

  // 豪华桌
  if (isLuxury) {
    const tempChipsStack = { ...chipsStackLuxury[seatNos.indexOf(seat_no)] };
    console.log(134134134, tempChipsStack)

    store.dispatch(
      setChipsStackLuxury({
        desk: [seatNos.indexOf(seat_no)],
        chipStack: {
          ...tempChipsStack,
          [category]: chipsArr,
        },
      })
    );
  } else {
    const tempChipsStack = { ...chipsStack };
    store.dispatch(
      setChipsStack({
        ...tempChipsStack,
        [category]: chipsArr,
      })
    );
  }
}

// 快捷投注筹码堆
function setChipsToQuickBetStack(chipsArr: number[], category: BetType) {
  const {
    quickBet,
    quickBet: { chipsStack },
  } = store.getState().luxury;

  store.dispatch(
    setQuickBet({
      ...quickBet,
      chipsStack: {
        ...chipsStack,
        [category]: chipsArr,
      },
    })
  );
}

export function setChipsToMultiStack(chipsArr: number[], category: BetType, index: number) {
  const tempChipStack = store.getState().multi.chipsStack[index];
  store.dispatch(
    setChipsStackMulti({
      desk: index,
      chipStack: {
        ...tempChipStack,
        [category]: chipsArr,
      },
    })
  );
}

// 往筹码堆里放筹码
export function addChipsToStack(chipsStack: Record<Shoes, number[]>, chipsArr: Partial<Bets>[]) {
  const tempChipsStack = { ...chipsStack };

  for (const [category, val] of Object.entries(chipsStack || {})) {
    const chip = chipsArr.find((item) => item.category === category);

    if (chip) {
      tempChipsStack[category] = [...val, ...(chip.betContent || [])];
    }
  }

  return tempChipsStack;
}

// 把筹码堆转化为投注
export function chipsStackToBets(chipsStack: Record<Shoes, number[]>) {
  const {
    deskInfo: { desk_no, game_mod },
  } = store.getState().bet;
  const lastBets = [];
  for (const [category, val] of Object.entries(chipsStack)) {
    if (val && val.length) {
      lastBets.push({
        desk_no,
        game_type: resultMaps[category],
        game_mod,
        bet_amount: sumChipsAmount(val),
      });
    }
  }

  console.log(lastBets, "lastBetslastBetslastBetslastBets");
  return lastBets;
}

const getAmountFmt = (balance) => {
  const { locale } = store.getState().config;

  if (locale === "cn") {
    return `${retain(CALC.divide(Number(balance), 10000), 4)}万`;
  } else {
    return formatAmount(CALC.divide(Number(balance), 1));
  }
};

/**
 * 判断是否可以下注
 * 1 余额是否充足
 * 2 庄闲和 下注金额校验
 * 3 如果是点击投注 判断是否达到该玩法的限红要求
 * 4 投注额是否超过个人限红
 * 5 庄闲对打绝对值限红判断
 * @param bets 需要投注的注单 Array
 * @param betDataList 当前局已经下注的列表信息 Array
 * @param options
 * @returns
 */
export function judgeCanbet(
  bets: Bets[],
  betDataList: any[],
  options: Partial<{
    orderInValid: boolean;
    betAmountInvalid: boolean;
    isQuickBet: boolean;
    isSmallBet: boolean;
  }>,
  isCanRepeat = true
) {
  const isLuxury = history.location.pathname.includes("luxuryBet");
  const { account } = store.getState().auth;

  const { orderInValid, betAmountInvalid, isQuickBet, isSmallBet } = options;

  // const totalBet = bets.reduce((prev, next) => prev + sumChipsAmount(next.betContent), 0);

  // if (Number(balance) < totalBet) {
  //   message.error(t("errMsgs:balanceNotEnough"));
  //   return false;
  // }

  console.log(bets, "每一笔投注都要过滤每一笔投注都要过滤每一笔投注都要过滤");
  // 每一笔投注都要过滤
  for (const bet of bets) {
    const { deskLimit, category, betContent } = bet;
    const currentBetTotal = getPlayerBets(account, betDataList, category);

    // 庄闲和 下注金额校验
    if (betAmountInvalid && (!isSmallBet && betIntercept({ [category]: sumChipsAmount(betContent) } as any))) return limitMsgs.type5;

    // 庄闲对打绝对值限红判断
    const msg = isBeyondLimitNew(betContent, category, deskLimit, betDataList);

    if (msg) {
      message.error(msg);
      // return false;
      return limitMsgs.type1;
    }

    // 如果是点击投注 判断是否达到该玩法的限红要求
    if (orderInValid) {
      const remainBetAmount = isOrderInValid(deskLimit, category, betContent, currentBetTotal);
      if (remainBetAmount) {
        if (!isCanRepeat) {
          message.error(t("game:betAmountNotEnough", { amount: getAmountFmt(remainBetAmount) }));
          return limitMsgs.type4;
        }
        message.error(t("game:betAmountNotEnough", { amount: getAmountFmt(remainBetAmount) }));
        !isQuickBet && setChipsToStack(betContent, category, isLuxury);
        isQuickBet && setChipsToQuickBetStack(betContent, category);

        return limitMsgs.type3;
      }
    }

    console.log(category, betContent, currentBetTotal, "判断是否达到该玩法的限红要求判断是否达到该玩法的限红要求");
    // 投注额是否超过个人限红
    if (!canAddChipsNew(deskLimit, category, betContent, currentBetTotal)) {
      message.error(t("game:reachedBettingAmountLimit"));
      return limitMsgs.type2;
    }
  }

  return false;
}

export function judgeCanbetMulti(
  bets: Bets[],
  betDataList: any[],
  deskIndex: number,
  alertMsg,
  options = {
    orderInValid: false,
    betAmountInvalid: false,
    isSmallBet: false,
  },
  isCanRepeat = true
) {
  const { balance, account } = store.getState().auth;
  const { orderInValid, betAmountInvalid, isSmallBet } = options;

  // const totalBet = bets.reduce((prev, next) => prev + sumChipsAmount(next.betContent), 0);

  // if (Number(balance) < totalBet) {
  //   alertMsg(t("errMsgs:balanceNotEnough"), "error");
  //   return false;
  // }

  // 每一笔投注都要过滤
  for (const bet of bets) {
    const { deskLimit, category, betContent } = bet;
    const currentBetTotal = getPlayerBets(account, betDataList, category);

    // 庄闲和 下注金额校验
    const msgs = betInterceptMulti({ [category]: sumChipsAmount(betContent) } as any);
    if ((!isSmallBet && msgs) && betAmountInvalid) {
      alertMsg(t(msgs), "error");
      return limitMsgs.type5;
    }

    // 庄闲对打绝对值限红判断
    const msg = isBeyondLimitNew(betContent, category, deskLimit, betDataList);

    if (msg) {
      // message.error(msg);
      alertMsg(msg, "error");
      return limitMsgs.type1;
    }

    // 如果是点击投注 判断是否达到该玩法的限红要求
    if (orderInValid) {
      const remainBetAmount = isOrderInValid(deskLimit, category, betContent, currentBetTotal);
      if (remainBetAmount) {
        if (!isCanRepeat) {
          alertMsg(t("game:betAmountNotEnough", { amount: getAmountFmt(remainBetAmount) }), "error");

          return limitMsgs.type4;
        }
        alertMsg(t("game:betAmountNotEnough", { amount: getAmountFmt(remainBetAmount) }), "error");

        setChipsToMultiStack(betContent, category, deskIndex);
        return limitMsgs.type3;
      }
    }

    // 投注额是否超过个人限红
    if (!canAddChipsNew(deskLimit, category, betContent, currentBetTotal)) {
      // message.error(t("game:reachedBettingAmountLimit"));
      alertMsg(t("game:reachedBettingAmountLimit"), "error");

      return limitMsgs.type2;
    }
  }

  return false;
}

// 把撤销注单转化为筹码堆
export function transformBetList(payload: RejectedOrderDetail[] = []) {
  const betTotal = {
    banker: 0,
    player: 0,
    tie: 0,
    bankerDouble: 0,
    playerDouble: 0,
  };

  const { currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;

  for (const item of payload) {
    betTotal[resultMapsReverse.get(item.game_type)] =
      betTotal[resultMapsReverse.get(Number(item.game_type))] +
      CALC.multiply(Number(item.bet_amount), getBeishu(isKRW, item.currency_id || 1));
  }

  return betTotal;
}

// 从筹码堆中移除
export function removeFromChipStack(chipStack: Record<BetType, number[]>, rejectBetInfo: RejectedOrderDetail[]) {
  const rejectedChipStack = transformBetList(rejectBetInfo);

  const tempChipStack = { ...chipStack };

  console.log(rejectedChipStack, "从筹码堆中移除从筹码堆中移除从筹码堆中移除从筹码堆中移除");
  for (const [key, val] of Object.entries(chipStack)) {
    if (val.length) {
      const amount = sumChipsAmount(val);
      const subAmount = rejectedChipStack[key];
      const chipArrs = sumNumberPart(getNumberPart(Math.max(amount - subAmount, 0)));
      tempChipStack[key] = chipArrs;
    }
  }

  return tempChipStack;
}

/**
 * 当枪手拒绝玩家注单时，筹码堆需要改变
 */
export function restoreChipsAfterRejectOrders(data: RejectedOrderParams) {
  const { betType } = store.getState().bet;
  const isLuxury = history.location.pathname.includes("luxuryBet");

  if (betType) return;

  const {
    chipsStack,
    deskInfo: { seat_no },
  } = store.getState().bet;
  const { chipsStack: chipsStackLuxury } = store.getState().luxury;

  const chipStack = removeFromChipStack(
    isLuxury ? chipsStackLuxury[seatNos.indexOf(seat_no)] : chipsStack,
    data.rejected_orders
  );

  if (!isLuxury) {
    store.dispatch(setChipsStack(chipStack));
  } else {
    console.log(134134134, chipStack)
    store.dispatch(
      setChipsStackLuxury({
        desk: seatNos.indexOf(seat_no),
        chipStack,
      })
    );
  }
}

/**
 * 打平好路列表
 * @param hotRoadList
 */
export function flatHotRoadList(hotRoadList: HotRoadProps[]) {
  const flattenHotRoadList: HotRoadProps[] = [];
  try {
    const data = JSON.parse(localStorage.getItem("roadTipMap"));
    const hotRoads = isEmpty(data) ? hotRoadRecommend : data;
    console.log(hotRoads, "好路推荐好路推荐好路推荐好路推荐好路推荐", isEmpty(data), data);

    for (const hotRoad of hotRoadList) {
      const { hot_load_type } = hotRoad;
      const types = hot_load_type
        .slice(0, -1)
        .split(",")
        .filter((type) => hotRoads?.find((hotRoad) => hotRoad.hotType === type)?.checked);

      if (types.length) {
        types?.forEach((type) =>
          flattenHotRoadList.push({
            ...hotRoad,
            hot_load_type: type,
            id: getUuid(),
          })
        );
      }
    }
  } catch (err) {
    console.log(err);
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return flattenHotRoadList.sort((prev, next) => prev.TimingBet - next.TimingBet);
  }
}

/**
 * 根据索引 移除多台桌子
 * @param index
 */
export function removeDeskByIndex(index: number) {
  const { isVisitor, account = 'isVisitor' } = store.getState().auth;
  const { multiDeskConfig } = store.getState().config;
  const { currentDesk } = store.getState().multi;

  // const tempCurrentDesk = isVisitor ? [...currentDesk] : [...(multiDeskConfig[account] || [0, 0, 0, 0])];
  const tempCurrentDesk = [...(multiDeskConfig[account] || [0, 0, 0, 0])];
  tempCurrentDesk[index] = 0;

  // if (isVisitor) {
  //   store.dispatch(setCurrentDesk(tempCurrentDesk));
  // } else {
  const tempMultiDeskConfig = { ...multiDeskConfig };
  tempMultiDeskConfig[account] = tempCurrentDesk as number[];
  store.dispatch(setMultiDeskConfig(tempMultiDeskConfig));
  // }
}

/**
 * 桌台模式切换时重设 快捷投注倒计时时间
 * @param deskId
 */
export function resetHotroadTimeBet(deskId: number) {
  store.dispatch(getHotRoadList({})).then((data: any) => {
    console.log(data, "当前桌台的信息当前桌台的信息");

    const { payload } = data || {};

    if (payload && payload.msg === "success") {
      const hotRoadList = payload.data || [];
      const { showQuickBet, quickBet } = store.getState().luxury;

      if (showQuickBet) {
        const { deskId } = quickBet;
        const targetDesk = hotRoadList.find((road) => road.desk_id === deskId);

        if (targetDesk) {
          store.dispatch(
            setQuickBet({
              ...quickBet,
              countDown: targetDesk.TimingBet,
            })
          );
        }
      }
    }
  });
}

/**
 * 经典桌台进入分配筹码
 * @param playerBetInfo 玩家投注信息
 */
export function distributeChipsIntoDesk(playerBetInfo: any[]) {
  const { account, currentTradeType } = store.getState().auth;

  // 经典模式
  const { chipsStack } = store.getState().bet;
  const isKRW = currentTradeType?.currency_type === 3;

  const tempChipsStack = { ...chipsStack };
  const currentPlayerBetInfo = transformBet(
    playerBetInfo
      .filter((betInfo) => betInfo.player_account === account)
      .reduce((prev, next) => {
        prev[next.game_type] = CALC.multiply(Number(next.bet_amount), getBeishu(isKRW, next.currency_id));
        return prev;
      }, {})
  );

  for (const [key, val] of Object.entries(currentPlayerBetInfo)) {
    tempChipsStack[key] = sumNumberPart(getNumberPart(val));
  }

  store.dispatch(setChipsStack(tempChipsStack));
}

/**
 * 豪华桌台进入分配筹码
 * @param playerBetInfo 玩家投注信息
 * @param playersInfo 当桌在线玩家信息
 */
export function distributeChipsIntoLuxuryDesk(playerBetInfo: any[], playersInfo: any[]) {
  const { currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;
  const { game_mod } = store.getState().bet.deskInfo
  for (const player of playersInfo) {
    const { seat_no, player_account } = player;

    const index = seatNos.indexOf(Number(Math.min(seat_no || 7, 7)));
    let playerBets = playerBetInfo.filter((bet) => bet.player_account === player_account);
    const tempChipsStack = {};
    if (game_mod === 3) {
      const myCurrency = store.getState().auth.currentTradeType?.currency_type
      playerBets = playerBets.filter(item => item.currency_id === myCurrency)
    }

    const currentPlayerBetInfo = transformBet(
      playerBets.reduce((prev, next) => {
        prev[next.game_type] = CALC.multiply(Number(next.bet_amount), getBeishu(isKRW, next.currency_id));
        return prev;
      }, {})
    );

    for (const [key, val] of Object.entries(currentPlayerBetInfo)) {
      tempChipsStack[key] = sumNumberPart(getNumberPart(val));
    }
    console.log(134134, { desk: index, chipStack: tempChipsStack })
    store.dispatch(setChipsStackLuxury({ desk: index, chipStack: tempChipsStack }));
  }
}

/**
 * 多台台桌分配筹码
 * @param playerBetInfo 玩家投注信息
 * @param index 台桌索引
 */
export function distributeChipsIntoMultiDesk(playerBetInfo: any[], index: number) {
  const { account, currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;

  const tempChipsStack = {};

  const currentPlayerBetInfo = transformBet(
    playerBetInfo
      .filter((betInfo) => betInfo.player_account === account)
      .reduce((prev, next) => {
        prev[next.game_type] = CALC.multiply(Number(next.bet_amount), getBeishu(isKRW, next.currency_id));
        return prev;
      }, {})
  );

  for (const [key, val] of Object.entries(currentPlayerBetInfo)) {
    tempChipsStack[key] = sumNumberPart(getNumberPart(val));
  }

  store.dispatch(setChipsStackMulti({ desk: index, chipStack: tempChipsStack }));
}

/**
 * 传统换桌
 * @param param0
 * @returns
 */
export async function quitEnterTradition({ is_change_club, old_desk_no, old_desk_id, new_desk_id, new_desk_no }) {
  const betPreference = localStorage.getItem("betPreference");
  const pathname = history.location.pathname;
  const inGameDesk = pathname.includes("/luxuryBet") || pathname.includes("/classic");
  const isLuxury = pathname.includes("luxuryBet");

  const { balance } = store.getState().auth;
  const { openLuxury } = store.getState().config

  // 是否换厅
  if (is_change_club === 1) {
    const { msg } = await quitTraditionMod({ desk_no: old_desk_no, _isNeedLoading: true });
    if (msg !== "success") return;
  }

  const leaveDeskSuccess = await leaveDesk(old_desk_id);
  if (!leaveDeskSuccess) return;

  is_change_club === 1 &&
    (await gameAPI.applySpecialService({
      desk_id: new_desk_id,
      desk_no: new_desk_no,
      area_code: "",
      phone: "",
      chips_amount: Number(balance),
    }));
  if (inGameDesk) {
    if (isLuxury) {
      await enterLuxuryDesk(new_desk_id);
    } else {
      await enterClassicDesk(new_desk_id);
    }
  } else {
    if (openLuxury && !!Number(betPreference)) {
      await enterLuxuryDesk(new_desk_id);
    } else {
      await enterClassicDesk(new_desk_id);
    }
  }
}

// 短信重连后获取信息
export async function reconnectSuccessed() {
  const pathname = history.location.pathname;
  const { deskPickOptions } = store.getState().bet;

  // 刷新余额
  store.dispatch(getBalance()).catch(() => {
    console.log("访客没有余额");
  });

  // 大厅界面处理
  if (pathname.includes("/tablePick")) {
    store.dispatch(fetchLobbyDesks(deskPickOptions));
    store.dispatch(getHotRoadList({}));
    return;
  }

  const inGameDesk = pathname.includes("/luxuryBet") || pathname.includes("/classic");
  const isMultiBet = pathname.includes("/multiBet");

  // 在桌子里面
  if (inGameDesk) {
    const deskId = history.location.pathname.match(/\/(\d+)/)[1];
    const isLuxury = history.location.pathname.includes("luxuryBet");

    if (isLuxury) {
      enterLuxuryDesk(deskId);
    } else {
      enterClassicDesk(deskId);
    }
  }

  // 在多台模式
  if (isMultiBet) {
    const { isVisitor, account } = store.getState().auth;
    const { multiDeskConfig } = store.getState().config;
    const { currentDesk } = store.getState().multi;

    const deskIds = isVisitor ? currentDesk : multiDeskConfig[account] || [0, 0, 0, 0];

    for (const deskId of deskIds) {
      Communicator.action(`refresh_desk_${deskId}`, {});
    }
  }
}

// 玩家是否投注庄闲
export function hasBetType(type: "banker" | "player", betDataList: DeskProps[]) {
  const { account } = store.getState().auth;

  if (type === "banker") {
    const betList = betDataList[0].list;

    return !!betList.find((bet) => bet.player_account === account);
  }

  if (type === "player") {
    const betList = betDataList[1].list;

    return !!betList.find((bet) => bet.player_account === account);
  }
}
