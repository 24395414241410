import { FC } from "react";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Dialog from "@/components/Dialog";
import MyButton from "@/components/MyButton";
import { Row } from "@/components/flex";
import styled from "styled-components";
import store, { useAppDispatch } from "@/store";
import { logout } from "@/store/slices/auth.slice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Text = styled.p`
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;
`;

interface Props {
  visible?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}
const QuitDlg: FC<Props> = ({ visible, onClose, onConfirm, onCancel }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const confirm = () => {
    dispatch(logout()).finally(() => {
      onConfirm && onConfirm();
      setIsShow(false);
      setTimeout(() => {
        window.location.href = "/auth";
      }, 0);
    });
  };

  return (
    <Dialog
      title={t("exitGame:title")}
      visible={isShow}
      buttons={[
        {
          text: t("exitGame:buttonCancel"),
          action: () => setIsShow(false),
        },
        {
          text: t("exitGame:buttonConfirm"),
          action: confirm,
        },
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <Text>{t("exitGame:exitGameLabel")}</Text>
      {/* <Row style={{padding: '0 30px 25px'}} justify="center" ailgn="center"> */}
      {/*   <MyButton cancel name={t('exitGame:buttonCancel')} onClick={() => setIsShow(false)}/> */}
      {/*   <MyButton styles={{marginLeft: '25px'}} name={t('exitGame:buttonConfirm')} onClick={confirm}/> */}
      {/* </Row> */}
    </Dialog>
  );
};

export default QuitDlg;
