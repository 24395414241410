import "@/i18n";
import { history } from "@/routers";
import store, { useAppDispatch, useAppSelector } from "@/store";
import { authSelector, getPlayerLimit, refreshToken } from "@/store/slices/auth.slice";
import { fetchLobbyDesks, setShowWaitConfirm } from "@/store/slices/bet.slice";
import { Locale } from "@/store/slices/config.slice";
import useEventSubscribe from "@/utils/hooks/useEventSubscribe";
import { isLogin } from "@/utils/tool";
import { WS_Event } from "@/utils/wsEvent";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import GlobalView from "./components/GlobalkStyle";
import "./Layout/style.sass";
import Router from "./routers";
import { gameAPI } from "./services";
import { getLocaleSelector } from "./store/slices/config.slice";
import { betterApplyChangeDesk, betterDealChangeDesk, DealWithBasicApply, outLogin, setVipEvent } from "./utils/ws/handler";
// import reportWebVitals from './reportWebVitals';
import { useTranslation } from "react-i18next";
import ErrorBoundary from "./components/common/errorBoundary";
import { enterClassicDesk, enterLuxuryDesk } from "./utils/functions/enterDesk";
import WaitConfirmDlg from "./components/Header/Modals/WaitForConfirm";

declare let buildTime: string;
console.log(`构建时间 ${buildTime}`);

// 引入styled
function App() {
  // 获取多语言
  const { t } = useTranslation();
  const locale: Locale = useSelector(getLocaleSelector);

  const { deskPickOptions } = useAppSelector((state) => state.bet);

  const dispatch = useAppDispatch();
  const authState = useAppSelector(authSelector);

  useEffect(() => {
    document.title = t("common:title");
  }, [locale]);

  // 玩家离开牌桌
  // 登录挤下线事件
  useEventSubscribe(WS_Event.OutLoginEvent, outLogin);

  /** 投手处理玩家的换桌申请 */
  useEventSubscribe(WS_Event.BetterDealChangeEvent, betterDealChangeDesk);

  /** 枪手申请换桌 */
  useEventSubscribe(WS_Event.BetterApplyChangeEvent, betterApplyChangeDesk);

  /** 申请专属服务ws */
  useEventSubscribe(WS_Event.DealWithBasicApply, DealWithBasicApply);

  // 包桌事件
  useEventSubscribe(WS_Event.SetVipEvent, setVipEvent)

  // 桌台信息查询
  function queryDeskList() {
    dispatch(fetchLobbyDesks({ ...deskPickOptions, _isNeedLoading: true }));
  }

  // 处理刷新页面 玩家离桌事件
  function beforeUnload() {
    const pathname = history.location.pathname;

    if (!isLogin()) return;

    if (pathname.includes("/classic") || pathname.includes("/luxuryBet")) {
      const deskId = history.location.pathname.match(/\/(\d+)/)[1];
      const {
        deskInfo: { desk_no, game_mod },
      } = store.getState().bet;
      (game_mod === 1 || game_mod === 3) && gameAPI.leaveDesk({ desk_id: Number(deskId), desk_no });
    }

    if (pathname?.includes("/multiBet")) {
      const { currentDesk, deskInfos } = store.getState().multi;

      currentDesk?.forEach((deskId, index) => {
        if (deskId) {
          gameAPI.leaveDesk({ desk_id: deskId, desk_no: deskInfos[index].desk_no });
        }
      });
    }
  }

  useEffect(() => {
    isLogin() && !history.location.pathname.includes("/auth") && queryDeskList();
  }, [deskPickOptions]);

  // 初始化
  useEffect(() => {
    const pathname = history.location.pathname;

    window.addEventListener("beforeunload", beforeUnload);

    const inGameDesk = pathname.includes("/luxuryBet") || pathname.includes("/classic");
    const isLuxury = pathname.includes("luxuryBet");

    const deskId = inGameDesk ? pathname.match(/\/(\d+)/)[1] : "";

    if (authState.access_token) {
      dispatch(refreshToken())
        .then((res) => {})
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (inGameDesk) {
      if (isLuxury) {
        enterLuxuryDesk(deskId);
      } else {
        enterClassicDesk(deskId);
      }
    }

    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, []);

  const { isVisitor } = useAppSelector(state => state.auth)

  useEffect(() => {
    if (!history.location.pathname.includes("/auth") && isLogin() && !isVisitor) {
      dispatch(getPlayerLimit());
    }
  }, [isVisitor])

  const { showWaitConfirm } = useAppSelector(state => state.bet)

  return (
    <ErrorBoundary>
      <GlobalView />
      <Router />
      <WaitConfirmDlg visible={showWaitConfirm} onClose={() => dispatch(setShowWaitConfirm(false))} />
    </ErrorBoundary>
  );
}

export default App;
