import en from "@/assets/images/locales/en.png";
import kr from "@/assets/images/locales/kr.png";
import cn from "@/assets/images/locales/zh-cn.png";
import i18n from "@/i18n";
import { RootState } from "@/store";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import { LuxuryTheme, MultiDesksParams } from "../types/bet.d";

type Locale = "cn" | "ko" | "en";

export type Theme = "dark" | "bright";
export interface LocalItem {
  value: Locale;
  label?: string;
  icon?: string;
}

interface State {
  music: boolean; // 背景音乐
  audio: boolean; // 音效
  betType: boolean; // 音效
  roadTipOpen: boolean; // 好路推荐展示
  locales: LocalItem[];
  locale: Locale; // 语言
  chipType: 0 | 1 | 2; // 筹码类型,
  deskAmount: 9 | 12;
  deskStyle: Theme; // 桌台风格 暗黑 明亮
  theme: LuxuryTheme;
  multiDeskConfig: MultiDesksParams;
  quickBetDeskNos: string[];
  // 是否开启豪华模式桌台样式
  openLuxury: boolean;
}

// 多语言数据
const locales: LocalItem[] = [
  {
    value: "cn",
    label: "中文",
    icon: cn,
  },
  {
    value: "en",
    label: "English",
    icon: en,
  },
  {
    value: "ko",
    label: "한국어",
    icon: kr,
  },
  /* {
    value: 'jp',
    label: '日本-JP ',
    icon: jp
  },
  {
    value: 'th',
    label: '泰国人-th',
    icon: th
  }, */
];

const name = "config";

const initialState: State = {
  music: true,
  audio: true,
  roadTipOpen: true,
  locale: "en",
  chipType: 0,
  deskAmount: 9,
  locales,
  deskStyle: "bright",
  theme: LuxuryTheme.green,
  betType: true,
  multiDeskConfig: {},
  quickBetDeskNos: [],
  openLuxury: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setMusic(state, action: PayloadAction<State["music"]>) {
      state.music = action.payload;
    },
    setAudio(state, action: PayloadAction<State["audio"]>) {
      state.audio = action.payload;
    },
    setBetType(state, action: PayloadAction<State["betType"]>) {
      state.betType = action.payload;
    },
    setDeskStyle(state, action: PayloadAction<Theme>) {
      state.deskStyle = action.payload;
    },
    setLocale(state, action: PayloadAction<Locale>) {
      state.locale = action.payload;
      i18n.changeLanguage(action.payload);
    },
    setRoadTipOpen(state, action: PayloadAction<boolean>) {
      state.roadTipOpen = action.payload;
    },
    setChipType(state, action: PayloadAction<State["chipType"]>) {
      state.chipType = action.payload;
    },
    setDeskAmount(state, action: PayloadAction<State["deskAmount"]>) {
      if (action.payload !== state.deskAmount) {
        state.deskAmount = action.payload;
      }
    },
    setLuxuryTheme(state, action: PayloadAction<LuxuryTheme>) {
      state.theme = action.payload;
    },
    setMultiDeskConfig(state, action: PayloadAction<MultiDesksParams>) {
      state.multiDeskConfig = action.payload;
    },
    setQuickBetDeskNos(state, action: PayloadAction<string[]>) {
      state.quickBetDeskNos = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(REHYDRATE, (state, action: any) => {
      const locale = action.payload?.config?.locale;
      // const locale = action.payload.config.locale
      // state.locale = locale
      if (locale) {
        i18n.changeLanguage(locale);
      }
    });
  },
});

const {
  setMusic,
  setAudio,
  setLocale,
  setChipType,
  setDeskAmount,
  setRoadTipOpen,
  setDeskStyle,
  setLuxuryTheme,
  setBetType,
  setMultiDeskConfig,
  setQuickBetDeskNos,
} = slice.actions;

// 选择器
const getMusicSelector = createSelector(
  (state: RootState) => state[name],
  (state: any) => state.music
);
const getAudioSelector = createSelector(
  (state: RootState) => state[name],
  (state: any) => state.audio
);
const getLocaleSelector = createSelector(
  (state: RootState) => {
    return state[name];
  },
  (state: any) => {
    return state.locale;
  }
);
const getChipTypeSelector = createSelector(
  (state: RootState) => state[name],
  (state: any) => state.chipType
);

const getDeskAmountSelector = createSelector(
  (state: RootState) => state[name],
  (state) => state.deskAmount
);

const getLocaleListSelector = createSelector(
  (state: RootState) => state[name],
  (state) => state.locales
);

const getDeskStyle = createSelector(
  (state: RootState) => state[name],
  (state) => state.deskStyle
);

export type { State, Locale };
export {
  setMusic,
  setAudio,
  setBetType,
  setLocale,
  setChipType,
  setRoadTipOpen,
  setDeskAmount,
  setDeskStyle,
  setLuxuryTheme,
  getMusicSelector,
  getAudioSelector,
  getLocaleSelector,
  getLocaleListSelector,
  getChipTypeSelector,
  getDeskAmountSelector,
  getDeskStyle,
  setMultiDeskConfig,
  setQuickBetDeskNos,
};

export default slice;
