import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC, forwardRef, ReactElement, Ref, useRef } from "react";
import Nodata from "@/assets/images/bet/noData.png";
import { Column, Row } from "@/components/flex";

const StyledTableContainer = styled(TableContainer)(() => ({
  "&": {
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(255,255,255, .2)",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, .5)",
    },
  },
  "&::-webkit-scrollbar-track": {
    padding: "0 2px",
    backgroundColor: "transparent",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#262626",
    color: "#E5C180",
    fontWeight: "400",
    fontSize: 15,
    border: "none",
    height: 36,
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: "400",
    fontSize: 15,
    color: "#fff",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#202020",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#262626",
  },
  "& td": {
    border: 0,
    height: 36,
    padding: "0 16px;",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const c = styled(Column)`
  position: absolute;
`;

const NoDataBg = styled(Row)`
  width: 236px;
  height: 189px;
  background: ${(props: any) => `url(${Nodata}) no-repeat center`};
  background-size: 236px 189px;

  ~ div {
    color: #e5c180;
    font-size: 15px;
  }
`;

const NoRecordContainer = styled(Column)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 80px;
`;

const NoRecordWrap = () => (
  <NoRecordContainer>
    <NoDataBg />
    <Row>暂无任何内容</Row>
  </NoRecordContainer>
);

interface Props<T = any> {
  stickyHeader?: boolean;
  rows: T[];
  noData?: boolean;
  columns: {
    dataIndex: string;
    align?: "left" | "right" | "center";
    title?: string;
    render?: (value: any, row: Object) => string | number | ReactElement | unknown;
    [key: string]: any;
  }[];
}
const OTables = forwardRef<HTMLTableElement, Props>(({ stickyHeader, rows, columns, noData }, ref) => {
  return (
    <StyledTableContainer ref={ref} style={{}} sx={{ height: "382px", overflowX: "hidden" }}>
      <Table stickyHeader sx={{ minWidth: 700, position: "relative" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns?.map((item, index) => (
              <StyledTableCell key={index} align={item.align ?? "left"}>
                {item.title}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>

        {noData ? (
          <NoRecordWrap />
        ) : (
          <TableBody>
            {rows?.map((row, index) => (
              <StyledTableRow key={index}>
                {columns?.map((item, index) => (
                  <StyledTableCell key={index} scope="row" align={item.align ?? "left"}>
                    {item.render ? item.render(row[item.dataIndex], row) : row[item.dataIndex] ?? ""}
                  </StyledTableCell>
                ))}

                {/* <StyledTableCell align="right">{row.calories}</StyledTableCell> */}
                {/* <StyledTableCell align="right">{row.fat}</StyledTableCell> */}
                {/* <StyledTableCell align="right">{row.carbs}</StyledTableCell> */}
                {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {/* <EmptyText justify="center"  >暂无记录</EmptyText> */}
    </StyledTableContainer>
  );
});

export default OTables;
