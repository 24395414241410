import Nodata from "@/assets/images/bet/noData.png";
import Dialog from "@/components/Dialog";
import { Row } from "@/components/flex";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Icon from "@/components/Icon";
import OTable from "@/components/OTable";
import { useAppDispatch, useAppSelector } from "@/store";
import { authSelector, getPlayerRecord } from "@/store/slices/auth.slice";
import { getLocaleSelector } from "@/store/slices/config.slice";
import { CALC } from "@/utils";
import { formatAmount, retain } from "@/utils/tool";
import { useInfiniteScroll } from "ahooks";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

const NoDataBg = styled(Row)`
  min-width: 700px;
  height: 382px;
  background: ${(props: any) => `url(${Nodata}) no-repeat center`};
  background-size: 236px 189px;

  ~ div {
    color: #e5c180;
    font-size: 15px;
    transform: translateY(-130px);
  }
`;

const RecordBottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #0d0d0d;

  .notice {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    flex-grow: 2;
    padding: 0 10px;
    font-weight: 500;
    font-size: 15px;
    color: #cb5460;

    .notice-icon {
      font-size: 24px;
      color: #cb5460;
      margin-right: 10px;
    }
  }

  .totalInfo {
    position: relative;
    width: 25%;
    height: 100%;
    padding: 10px 10px 0;

    .top {
      color: #fff;
      padding-bottom: 10px;
      line-height: 20px;
      font-weight: 500;
      font-size: 18px;
    }

    .bottom {
      color: #d1ad6d;
      line-height: 20px;
      font-weight: 400;
      font-size: 15px;
    }

    &:after {
      position: absolute;
      top: 13px;
      left: 0;
      width: 45px;
      height: 1px;
      content: "\\20";
      transform: rotate(90deg);
      transform-origin: 0 0;
      background: linear-gradient(90deg, rgba(229, 193, 128, 0.2) 0%, #e5c180 49.71%, rgba(229, 193, 128, 0.2) 100.47%);
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    content: "\\20";
    background: linear-gradient(90deg, rgba(229, 193, 128, 0.2) 0%, #e5c180 49.71%, rgba(229, 193, 128, 0.2) 100.47%);
  }
`;
interface Record {
  list: any[];
  page_num: number;
  page_size: 10 | 20;
  total: number;
  total_bet_amount: string;
  total_prize: string;
  total_changed_chips: string;
}

interface Props {
  visible?: boolean;
  onClose?: () => void;
}

const RecordDlg: FC<Props> = ({ visible, onClose }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const [noData, setNoData] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const state = useAppSelector(authSelector);
  const locale = useAppSelector(getLocaleSelector);

  const ref = useRef<HTMLTableElement>(null);

  const getLoadMoreList = (d): Promise<Record> => {
    let page_num = 1;
    if (d?.page_num) {
      page_num = d.page_num + 1;
    }
    return dispatch(
      getPlayerRecord({
        _isNeedLoading: true,
        page_num,
        page_size: d?.page_size ?? 10,
      })
    ).then((res) => {
      const payload = res.payload as any;
      console.log(payload.record.data.game_record_list);

      if (!payload.record?.data?.game_record_list || !payload.record?.data?.game_record_list?.length) setNoData(true);

      return {
        list: payload.record.data.game_record_list,
        page_num,
        page_size: d?.page_size ?? 10,
        total: payload.record.data.total,
        total_bet_amount: payload.statistics.data.total_bet_amount,
        total_prize: payload.statistics.data.total_prize,
        total_changed_chips: payload.statistics.data.total_changed_chips,
      };
    });
  };

  const { data, loading, loadMore, loadingMore } = useInfiniteScroll(getLoadMoreList, {
    target: ref,
    isNoMore: (d) => {
      return d?.list.length === d?.total;
    },
  });

  const totalChangedChips = useMemo(() => {
    // if (!data?.total_bet_amount) return "";

    if (locale === "cn") {
      return `${retain(CALC.divide(Number(data?.total_changed_chips), 10000), 4)}万`;
    } else {
      return formatAmount(Number(data?.total_changed_chips));
    }
  }, [data?.total_changed_chips, locale]);

  useEffect(() => {
    setIsShow(true);
  }, []);
  const cols = [
    {
      dataIndex: "bet_time",
      title: t("modalGameRecord:time"),
      align: "center",
      render(value, row) {
        const d = dayjs(value);
        return d.format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      dataIndex: "game_no",
      align: "center",
      title: t("modalGameRecord:bureauNumber"),
    },
    // 游戏模式1:快速｜2:传统｜3:包桌]
    {
      dataIndex: "game_mod",
      title: t("modalGameRecord:gameType"),
      align: "center",
      render(value, row) {
        switch (value) {
          case 1:
            return t("modalGameRecord:gameTypeFast");
          case 2:
            return t("modalGameRecord:gameTypeTradition");
          case 3:
            return t("deskPick:gameModSmall");
        }
      },
    },
    {
      dataIndex: "desk_no",
      align: "center",
      title: t("modalGameRecord:table"),
    },
    // 下注项[1:B庄|2:P闲｜3:和｜4:庄对｜5:闲对]
    {
      dataIndex: "game_type",
      align: "center",
      title: t("modalGameRecord:bettingContent"),
      render(value) {
        switch (value) {
          case 1:
            return t("modalGameRecord:bettingContentVillage");
          case 2:
            return t("modalGameRecord:bettingContentIdle");
          case 3:
            return t("modalGameRecord:bettingContentAnd");
          case 4:
            return t("modalGameRecord:bettingContentZhuangDui");
          case 5:
            return t("modalGameRecord:bettingContentFreePair");
        }
      },
    },
    {
      dataIndex: "confirm_amount",
      align: "center",
      title: t("modalGameRecord:totalBetAmount"),
      render(value) {
        if (locale === "cn") {
          return `${retain(CALC.divide(Number(value), 10000), 4)}万`;
        } else {
          return formatAmount(Number(value));
        }
      },
    },
    {
      dataIndex: "state",
      align: "center",
      title: t("modalGameRecord:betStatus"),
      render(value) {
        switch (value) {
          case 1:
          case 2:
            return t("modalGameRecord:success");
          case 3:
            return <span style={{ color: "#cb5460" }}>{t("common:buttonCancel")}</span>;
          case 4:
            return <span style={{ color: "#cb5460" }}>{t("modalGameRecord:cancelations")}</span>;
          case 5:
            return <span style={{ color: "#cb5460" }}>{t("modalGameRecord:backRevoked")}</span>;
          case 6:
            return <span style={{ color: "#cb5460" }}>{t("modalGameRecord:frozenBet")}</span>;
          default:
            return <span style={{ color: "#cb5460" }}>{t("modalGameRecord:backRevoked")}</span>;
        }
      },
    },
    {
      dataIndex: "game_result",
      align: "center",
      title: t("modalGameRecord:result"),
      render(value) {
        switch (value) {
          case 14:
            return t("modalGameRecord:resultZhuangYing");
          case 24:
            return t("modalGameRecord:resultFreeToWin");
          case 34:
            return t("modalGameRecord:resultAnd");
          case 11:
            return t("modalGameRecord:resultZhuangWinZhuangPair");
          case 13:
            return t("modalGameRecord:resultBankerWinBankerPairPlayerPair");
          case 12:
            return t("modalGameRecord:resultZhuangYingFreePair");
          case 21:
            return t("modalGameRecord:resultFreeToWinBankerPair");
          case 23:
            return t("modalGameRecord:resultPlayerWinBankerPairPlayerPair");
          case 22:
            return t("modalGameRecord:resultFreeToWinFreeToPair");
          case 31:
            return t("modalGameRecord:resultAndZhuangPair");
          case 33:
            return t("modalGameRecord:resultAndZhuangPairLeisurePairs");
          case 32:
            return t("modalGameRecord:resultAndIdlePair");
        }
      },
    },
    {
      dataIndex: "win_amount",
      align: "center",
      title: t("modalGameRecord:Updown"),
      render(value) {
        if (locale === "cn") {
          return `${retain(CALC.divide(Number(value), 10000), 4)}万`;
        } else {
          return formatAmount(Number(value));
        }
      },
    },
  ];

  return (
    <Dialog
      title={t("modalGameRecord:title")}
      visible={isShow}
      buttons={[
        {
          type: "primary",
          text: t("modalGameRecord:buttonClose"),
          action: () => setIsShow(false),
        },
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <div style={{ padding: "30px 60px 0" }}>
        <OTable ref={ref} rows={data?.list ?? []} columns={cols as any} noData={noData}></OTable>
        <RecordBottom>
          <div className="notice">
            <Icon className="notice-icon" type="icon-alert-circle"></Icon>
            <span>{t("modalGameRecord:alert")}</span>
          </div>
          <div className="totalInfo">
            <p className="top">{totalChangedChips}</p>
            <p className="bottom">{t("modalGameRecord:totalUpdown")}</p>
          </div>
          {/* <div className="totalInfo">
            <p className="top">{totalPrize}</p>
            <p className="bottom">{t("modalGameRecord:totalPayout")}</p>
          </div> */}
        </RecordBottom>
        {/* <Row justify="center" style={{padding: '25px 0'}}> */}
        {/*   <MyButton name={t('modalGameRecord:buttonClose')} width={140} onClick={() => setIsShow(false)}/> */}
        {/* </Row> */}
      </div>
    </Dialog>
  );
};

export default RecordDlg;
