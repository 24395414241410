/** 桌台状态 */
export enum DeskStatus {
  /**  禁用 */
  CloseDesk = 1,
  /**  游戏中 */
  GamingDesk = 2,
  /**  维护中 */
  MaintainDesk = 3,
  /**  换靴 */
  ChangingCardDesk = 4,
  /** 包台 */
  VipGame = 5,
  /** 围数 */
  WeiShu = 6,
}

/** 包桌类型 */
export enum PackedDeskStatus {
  /**  包桌 */
  Packed = 1,
  /**  取消包桌 */
  UnPacked = 2,
}

/** 游戏类型 */
export enum GameMod {
  /** 快速 */
  FastGame = 1,
  /** 传统 */
  BasicGame = 2,
  /** 小额 */
  SmallGame = 3,
}

export const deskStatusMap = new Map([
  [1, "禁用"],
  [2, "游戏中"],
  [3, "game:maintain"],
  [4, "game:changingShoe"],
  [6, "game:weishu"],
]);

export const PackedDeskStatusMap =  new Map([
  [1, "game:packingGame"],
]);

export const gameModMap = new Map([
  [1, "modalGameRecord:gameTypeFast"],
  [2, "modalGameRecord:gameTypeTradition"],
  [3, "deskPick:gameModSmall"],
]);
