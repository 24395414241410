import Dialog from "@/components/Dialog";
import { logoMap } from "@/config/const";
import { gameAPI } from "@/services";
import { useAppDispatch } from "@/store";
import { setShowTablePick, setShowWaitConfirm } from "@/store/slices/bet.slice";
import { enterClassicDesk, enterLuxuryDesk } from "@/utils/functions/enterDesk";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDlgHooks } from "./hooks";

interface Props {
  visible?: boolean;
  isChangeClub?: boolean;
  onClose?: () => void;
  clubId: number;
  oldClubId?: number;
  deskNo: string;
  oldDeskNo?: string;
  deskId: number;
  oldDeskId: number;
}

const Text = styled.div`
  font-size: 18px;
  /* max-width: 200px; */
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;

  span {
    color: #e5c180;
  }
`;

const SwitchDesk: FC<Props> = ({
  clubId,
  oldClubId,
  oldDeskNo,
  deskNo,
  deskId,
  oldDeskId,
  visible,
  onClose,
  isChangeClub = false,
}) => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { t } = useTranslation();
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });

  const confirm = async () => {
    try {
      const { msg } = await gameAPI.changeDesk({
        old_desk_id: Number(oldDeskId),
        new_desk_id: Number(deskId),
        _isNeedLoading: true,
      });

      if (msg === "success") {
        dispatch(setShowTablePick(false));
        dispatch(setShowWaitConfirm(true));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsShow(false);
    }
  };

  return (
    <Dialog
      title={t("game:switchTraditionTable")}
      visible={isShow}
      buttons={[
        {
          text: t("modalChipChoose:buttonCancel"),
          action: () => {
            const betPreference = localStorage.getItem("betPreference");
            setIsShow(false);

            // if (oldClubId) {
            //   if (!!Number(betPreference)) {
            //     enterClassicDesk(oldDeskId);
            //   } else {
            //     enterLuxuryDesk(oldDeskId);
            //   }
            // }

            onClose && onClose();
          },
        },
        {
          text: t("modalChipChoose:buttonConfirm"),
          action: confirm,
        },
      ]}
    >
      <Text>
        {isChangeClub ? <div>{t("SwitchDesk:switchCasinoEndGame")}</div> : null}
        <div>{t("SwitchDesk:confirmChangeTable")}</div>
        {oldClubId ? (
          <div>
            原赌场: <span>{logoMap.get(oldClubId)}</span>
          </div>
        ) : null}
        {oldDeskNo ? (
          <div>
            原桌台: <span>{oldDeskNo}</span>
          </div>
        ) : null}

        <div>
          {t("SwitchDesk:Casino")}: <span>{logoMap.get(clubId)}</span>
        </div>
        <div>
          {t("SwitchDesk:Table")}: <span>{deskNo}</span>
        </div>
      </Text>
    </Dialog>
  );
};

export default SwitchDesk;
