import React, { Component } from "react";
import styled from "styled-components";
import errorBg from "@/assets/images/error_bg.jpg";
import { Column, Row } from "../flex";
import { history } from "@/routers";

const Wrapper = styled(Column)`
  background: url(${errorBg}) no-repeat center top;
  background-size: contain;
  height: 100vh;
  color: #fff;
`;


const Btn = styled.div`
  width: 130px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: linear-gradient(180deg, #625b4e 14.58%, #353028 54.69%, #51483c 78.65%, #786952 100%);
  box-shadow: 0px 3px 5px rgba(32, 29, 24, 0.75), inset 0px 3px 2px rgba(0, 0, 0, 0.5);
  border-radius: 50px;
`;

export class ErrorBoundary extends Component<any> {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo, "errorInfoerrorInfoerrorInfo");
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper ailgn="center">
          <Row style={{ marginTop: 20 }}>系统内部发生异常</Row>
          <Btn
            style={{ marginTop: 20 }}
            onClick={() => {
              window.location.href = "/auth";
            }}
          >
            返回登录
          </Btn>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
