import {FC, useMemo} from "react";
import {useDlgHooks} from "@/components/Header/Modals/hooks";
import Dialog from "@/components/Dialog";
import { useTranslation } from "react-i18next";
import store, { useAppSelector } from "@/store";
import ListSelect from '@/components/ListSelect/Index'
import { useLocation } from "react-router-dom";

interface Props {
  visible?: boolean,
  onClose?: () => void,
  onChoose?: (data) => void
}
const BetLimit:FC<Props> = ({visible, onClose, onChoose}) => {
  const { isShow, setIsShow, close } = useDlgHooks({visible, onClose})
  const {t} = useTranslation()
  const { pathname } = useLocation();
  const sort_rule = useMemo(() => {
    const { deskPickOptions, deskPickOptions2 } = store.getState().bet
    if (pathname.includes('/tablePick')) {
      return deskPickOptions.sort_rule
    }
    return deskPickOptions2.sort_rule
  }, [pathname])

  const sort = [
    { label: t('betLimit:betLimitNotSortedLabel'), value: '' },
    { label: t('betLimit:betLimitLowToHighLabel'), value: 'asc' },
    { label: t('betLimit:betLimitHighToLowLabel'), value: 'desc' },
  ];

  const active = useMemo(() => {
    const index = sort.findIndex(item => item.value === sort_rule);
    return index
 }, [sort_rule])

  return (
    <Dialog title={t('betLimit:title')} visible={isShow} zIndex={8000} onClose={() => setIsShow(false)} onAnimationend={close}>
      <ListSelect list={sort} onChoose={(item) => {setIsShow(false);onChoose(item.value);}} active={active}/>
    </Dialog>
  )
}

export default BetLimit
