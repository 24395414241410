import { FC, useMemo } from "react";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Dialog from "@/components/Dialog";
import { useTranslation } from "react-i18next";
import store, { useAppSelector } from "@/store";
import { useLocation } from "react-router-dom";
import ListSelect from "@/components/ListSelect/Index";

interface Props {
  visible?: boolean;
  onClose?: () => void;
  onChoose?: (data) => void;
}
const BetType: FC<Props> = ({ visible, onClose, onChoose }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const betTypes = [
    { label: t("betType:typeAllServicesLabel"), value: 0 },
    { label: t("betType:typeFastLabel"), value: 1 },
    { label: t("betType:typeTraditionalLabel"), value: 2 },
    // { label: t('betType:typeSmallLabel'), value: 3 },
  ];

  const game_mod = useMemo(() => {
    const { deskPickOptions, deskPickOptions2 } = store.getState().bet;
    if (pathname.includes("/tablePick")) {
      return deskPickOptions.game_mod;
    }
    return deskPickOptions2.game_mod;
  }, [pathname]);

  const active = useMemo(() => {
    const index = betTypes.findIndex((item) => item.value === game_mod);
    return index;
  }, [game_mod]);

  return (
    <Dialog
      title={t("betType:title")}
      visible={isShow}
      zIndex={8000}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <ListSelect
        list={betTypes.filter((item) => {
          if (pathname === "/multiBet") {
            return item.value === 1 || item.value === 0;
          } else {
            return item;
          }
        })}
        onChoose={(item) => {
          setIsShow(false);
          onChoose(item.value);
        }}
        active={active}
      />
    </Dialog>
  );
};

export default BetType;
