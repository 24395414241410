import { showSwitchDesk } from "@/components/Header/Modals";
import { message } from "@/components/Message";
import { history } from "@/routers";
import { gameAPI } from "@/services";
import store from "@/store";
import { getBalance } from "@/store/slices/auth.slice";
import { changeEnterDesk, getGameDeskById, setShowTablePick } from "@/store/slices/bet.slice";
import { resetAllChipsStack, setDeskBetInfo, setDeskPlayers } from "@/store/slices/luxury.slice";
import { delay } from "@/utils";
import _ from "lodash";
import { distributeChipsIntoDesk, distributeChipsIntoLuxuryDesk } from ".";
import { isLogin } from "../tool";
import { t } from "i18next";

// 桌台跟新，维护 包桌
export async function leaveDesk(deskId) {
  // if (isEmpty(store.getState().bet.deskInfo) || !store.getState().bet.deskInfo) return;

  const { deskInfo } = store.getState().bet;
  // 刷新余额
  store.dispatch(getBalance()).catch(() => {
    console.log("访客没有余额");
  });

  if (!isLogin()) return true;

  try {
    const { msg } = await gameAPI.leaveDesk({
      desk_id: Number(deskId),
      desk_no: deskInfo.desk_no,
      _isNeedLoading: true,
    });

    console.log(msg, "gameAPIgameAPIgameAPIgameAPI");
    msg !== "success" && message.error("离桌失败，请重试");
    return msg === "success";
  } catch (err) {
    console.log(err);
    return false;
  }
}

/** 经典模式进桌 */
export async function enterClassicDesk(deskId) {
  return store
    .dispatch(getGameDeskById({ desk_id: deskId, _isNeedLoading: true }))
    .then((res) => {
      const {
        payload: { msg, data },
        type,
      }: any = res;

      if (type.endsWith("fulfilled") && msg === "success") {
        const { player_bet_info, game_mod } = data;

        distributeChipsIntoDesk(player_bet_info || []);

        // 设置玩家投注信息
        store.dispatch(
          setDeskBetInfo(
            player_bet_info
              ?.filter((betInfo) => game_mod !== 3 || betInfo.currency_id === store.getState().auth.currentTradeType?.currency_type)
              ?.filter((betInfo) => betInfo.player_account && !!Number(betInfo.bet_amount))
              ?.map((betInfo) => _.omit(betInfo, ["desk_id", "player_id"]))
              ?.map((betInfo) => ({
                ...betInfo,
                bet_amount: Number(betInfo.bet_amount),
              }))
          )
        );
        store.dispatch(setShowTablePick(false));

        history.push(`/classic/${deskId}`);
      }

      if (type.endsWith("fulfilled") && msg === "g-10024") {
        const { old_desk_id, old_desk_no, desk_id, desk_no, club_id, old_club_id } = data;
        // showSwitchDesk(club_id, desk_no, desk_id, old_desk_id, club_id !== old_club_id, old_club_id, old_desk_no);
        message({
          message: t('SwitchDesk:changeDeskToast', { oldDeskNo: old_desk_no, newDeskNo: desk_no }),
          duration: 5000
        })
        enterClassicDesk(old_desk_id)
      }
    })
    .catch((err) => {
      console.log(err, "payloadpayloadpayloadpayloadpayloadpayload");
    });
}

/** 豪华模式进桌 */
export async function enterLuxuryDesk(deskId) {
  const { account } = store.getState().auth;

  return store.dispatch(getGameDeskById({ desk_id: deskId, _isNeedLoading: true })).then(async (res) => {
    const {
      payload: { msg, data },
      type,
    }: any = res;

    console.log(msg, type, data, "datadatadatadatadata");
    if (type.endsWith("fulfilled") && msg === "success") {
      const { players_info = [], player_bet_info = [], seat_no, apply_basic_ing, game_mod } = data;
      store.dispatch(resetAllChipsStack());
      await delay(10);

      // seatNos
      // 传统模式下 申请传统中或还未申请传统的不弹 欢迎提示框
      // if (
      //   game_mod === GameMod.BasicGame &&
      //   [ApplyServiceStatus.Applying, ApplyServiceStatus.NotApply, ApplyServiceStatus.Failed].includes(
      //     apply_basic_ing
      //   )
      // ) {
      //   setShow(false);
      // } else {
      //   setShow(true);
      // }

      let totalPlayers = [];
      // 常规玩家
      const normalPlayers = players_info?.filter((player) => player.seat_no < 7) || [];
      // 旁注玩家
      const sidePlayers = players_info?.filter((player) => player.seat_no >= 7) || [];

      // 旁注玩家是否包含自己
      const index = sidePlayers?.findIndex((player) => player.player_account === account);

      if (sidePlayers.length) {
        if (index !== -1) {
          totalPlayers = [...normalPlayers, sidePlayers[index]];
        } else {
          totalPlayers = [...normalPlayers, sidePlayers[0]];
        }
      } else {
        totalPlayers = [...normalPlayers];
      }

      store.dispatch(setShowTablePick(false));

      distributeChipsIntoLuxuryDesk(player_bet_info || [], totalPlayers);

      // 保存玩家概况
      store.dispatch(
        setDeskPlayers(
          totalPlayers?.map((player) => _.omit(player, ["desk_id"]))?.filter((player) => player.seat_no !== 0)
        )
      );

      // 设置玩家投注信息
      store.dispatch(
        setDeskBetInfo(
          player_bet_info
            ?.filter((betInfo) => game_mod !== 3 || betInfo.currency_id === store.getState().auth.currentTradeType?.currency_type)
            ?.filter((betInfo) => betInfo.player_account && !!Number(betInfo.bet_amount))
            ?.map((betInfo) => _.omit(betInfo, ["desk_id", "player_id"]))
            ?.map((betInfo) => ({
              ...betInfo,
              bet_amount: Number(betInfo.bet_amount),
            }))
        )
      );

      history.push(`/luxuryBet/${deskId}`);
    }

    if (type.endsWith("fulfilled") && msg === "g-10024") {
      const { old_desk_id, old_desk_no, desk_id, desk_no, club_id, old_club_id } = data;
      // showSwitchDesk(club_id, desk_no, desk_id, old_desk_id, club_id !== old_club_id, old_club_id, old_desk_no);
      message({
        message: t('SwitchDesk:changeDeskToast', { oldDeskNo: old_desk_no, newDeskNo: desk_no }),
        duration: 5000
      })
      enterLuxuryDesk(old_desk_id)
    }
  });
}

/** 经典模式进桌 */
export async function changeEnterClassicDesk(deskId) {
  store
    .dispatch(changeEnterDesk({ desk_id: deskId, _isNeedLoading: true }))
    .then((res) => {
      const {
        payload: { msg, data },
        type,
      }: any = res;

      if (type.endsWith("fulfilled") && msg === "success") {
        const { player_bet_info, game_mod } = data;

        distributeChipsIntoDesk(player_bet_info || []);

        // 设置玩家投注信息
        store.dispatch(
          setDeskBetInfo(
            player_bet_info
              ?.filter((betInfo) => game_mod !== 3 || betInfo.currency_id === store.getState().auth.currentTradeType?.currency_type)
              ?.filter((betInfo) => betInfo.player_account && !!Number(betInfo.bet_amount))
              ?.map((betInfo) => _.omit(betInfo, ["desk_id", "player_id"]))
              ?.map((betInfo) => ({
                ...betInfo,
                bet_amount: Number(betInfo.bet_amount),
              }))
          )
        );
        store.dispatch(setShowTablePick(false));

        history.push(`/classic/${deskId}`);
      }
    })
    .catch((err) => {
      console.log(err, "payloadpayloadpayloadpayloadpayloadpayload");
    });
}

/** 豪华模式进桌 */
export async function changeEnterLuxuryDesk(deskId) {
  const { account } = store.getState().auth;

  store.dispatch(changeEnterDesk({ desk_id: deskId, _isNeedLoading: true })).then(async (res) => {
    const {
      payload: { msg, data },
      type,
    }: any = res;

    console.log(msg, type, data, "datadatadatadatadata");
    if (type.endsWith("fulfilled") && msg === "success") {
      const { players_info = [], player_bet_info = [], seat_no, apply_basic_ing, game_mod } = data;
      store.dispatch(resetAllChipsStack());
      await delay(10);

      // seatNos
      // 传统模式下 申请传统中或还未申请传统的不弹 欢迎提示框
      // if (
      //   game_mod === GameMod.BasicGame &&
      //   [ApplyServiceStatus.Applying, ApplyServiceStatus.NotApply, ApplyServiceStatus.Failed].includes(
      //     apply_basic_ing
      //   )
      // ) {
      //   setShow(false);
      // } else {
      //   setShow(true);
      // }

      let totalPlayers = [];
      // 常规玩家
      const normalPlayers = players_info?.filter((player) => player.seat_no < 7) || [];
      // 旁注玩家
      const sidePlayers = players_info?.filter((player) => player.seat_no >= 7) || [];

      // 旁注玩家是否包含自己
      const index = sidePlayers?.findIndex((player) => player.player_account === account);

      if (sidePlayers.length) {
        if (index !== -1) {
          totalPlayers = [...normalPlayers, sidePlayers[index]];
        } else {
          totalPlayers = [...normalPlayers, sidePlayers[0]];
        }
      } else {
        totalPlayers = [...normalPlayers];
      }

      store.dispatch(setShowTablePick(false));

      distributeChipsIntoLuxuryDesk(player_bet_info || [], totalPlayers);

      // 保存玩家概况
      store.dispatch(
        setDeskPlayers(
          totalPlayers?.map((player) => _.omit(player, ["desk_id"]))?.filter((player) => player.seat_no !== 0)
        )
      );

      // 设置玩家投注信息
      store.dispatch(
        setDeskBetInfo(
          player_bet_info
            ?.filter((betInfo) => game_mod !== 3 || betInfo.currency_id === store.getState().auth.currentTradeType?.currency_type)
            ?.filter((betInfo) => betInfo.player_account && !!Number(betInfo.bet_amount))
            ?.map((betInfo) => _.omit(betInfo, ["desk_id", "player_id"]))
            ?.map((betInfo) => ({
              ...betInfo,
              bet_amount: Number(betInfo.bet_amount),
            }))
        )
      );

      history.push(`/luxuryBet/${deskId}`);
    }
  });
}
