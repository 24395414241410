import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "animate.css";
import { MyWS } from "@/utils/websocket";
import "@/assets/js/poker.min";
import "@/assets/css/common.scss";
import { WS_CONNECT_CONFIG } from "./config";

caches
  .keys()
  .then((keys) => Promise.all(keys.map(async (key) => console.log("caches.delete", key, await caches.delete(key)))))
  .then(() => console.log("DONE"))
  .catch(console.error);

navigator.serviceWorker.getRegistrations().then((sws) => {
  sws.forEach((sw) => {
    sw.unregister();
    console.log("sw unregister", sw);
  });
});

export const ws = new MyWS({
  ...WS_CONNECT_CONFIG,
  servers: process.env.REACT_APP_WS_URL,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       .then((registration) => {
//         console.log("SW registered: ", registration);
//       })
//       .catch((registrationError) => {
//         console.log("SW registration failed: ", registrationError);
//       });
//   });
// }
