import { message } from "@/components/Message";
import { gameAPI } from "@/services";
import store from "@/store";
import { BetType, Shoes } from "@/store/types/bet.d";
import { createAsyncThunk, createEntityAdapter, EntityAdapter } from "@reduxjs/toolkit";
import { t } from "i18next";
import { v1 as uuid } from "uuid";
import { CALC } from ".";

export const chipsUnit = [25, 50, 100, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000];
export const chipsUnitKRW = chipsUnit.map((chip) => chip * 200);

export const seatNos = [1, 2, 3, 5, 6, 7];

const categoryMap = {
  [BetType.BANKER]: 1,
  [BetType.PLAYER]: 1,
  [BetType.TIE]: 2,
  [BetType.BDOUBLE]: 3,
  [BetType.PDOUBLE]: 3,
};

export const resultMapsReverse = new Map([
  [1, "banker"],
  [2, "player"],
  [3, "tie"],
  [4, "bankerDouble"],
  [5, "playerDouble"],
]);

// 1庄  2闲 3和 4庄对 5闲对
export const resultMaps = {
  banker: 1,
  player: 2,
  tie: 3,
  bankerDouble: 4,
  playerDouble: 5,
};

export const DESIGN_WIDTH = 414;
let windowW: number | null = null;
export const getWndowWidth = () => {
  const windowWidth = window.innerWidth;
  if (windowWidth >= 768) return 768;
  return windowWidth;
};

export const getRem = (num: number) => {
  const windowWidth = getWndowWidth();
  return (windowWidth / DESIGN_WIDTH) * num;
};
window.onresize = () => {
  if (windowW === getWndowWidth()) return;
  windowW = getWndowWidth();
  document.documentElement.style.fontSize = `${windowW / DESIGN_WIDTH}px`;
};
interface IAsyncThunk {
  storeName: string;
  funcName: string;
  ascyncFunc: (...args: any[]) => Promise<any>;
  fulfilled: (...args: any[]) => void;
  pending: (...args: any[]) => void;
  rejected: (...args: any[]) => void;
  adapter?: boolean;
  options?: any;
}
export class GetAsyncThunk {
  [key: string]: any;
  extraReducers = {};
  constructor({ storeName, ascyncFunc, funcName, fulfilled, pending, rejected, adapter, options }: IAsyncThunk) {
    this[funcName] = createAsyncThunk(`${storeName}/${funcName}`, ascyncFunc);
    if (adapter) {
      this.adapter = createEntityAdapter(options ? options : null) as EntityAdapter<any>;
    }
    this.extraReducers = {
      [this[funcName].pending]: pending,
      // 结束
      [this[funcName].fulfilled]: fulfilled,
      // 错误
      [this[funcName].rejected]: rejected,
    };
  }
}
type ActionType = (action: string, data: Record<string, any>) => boolean;
interface EventItemType {
  key: string;
  func: Function;
}
export class Communicator {
  static [key: string]: any;
  static action: ActionType = (action, data) => {
    const listKey = action;
    if (this[listKey] && Array.isArray(this[listKey])) {
      this[listKey]?.forEach(({ func }: EventItemType) => {
        func(data);
      });
      return true;
    }
    return false;
  };
  /**
   *
   * @param {string} ListenerName 事件名
   * @returns {array} 事件列表
   */
  static getListenerList = (ListenerName: string): Array<any> | null => {
    try {
      const listKey = ListenerName;
      if (!this[listKey]) throw new Error(`${ListenerName}not Fund`);
      return this[listKey];
    } catch (error) {
      return null;
    }
  };
  /**
   *
   * @param {string} ListenerName 事件名
   * @param {func} func 回调函数
   */
  static removeListener = (ListenerName: string, func: Function) => {
    try {
      const listKey = ListenerName;
      if (!Communicator[listKey] || !Communicator[listKey].length) throw new Error("if want Remove ,create first");
      const index = Communicator[listKey].indexOf(func);
      Communicator[listKey].splice(index, 1);
    } catch (error: any) {
      error.message && console.error(error.message);
    }
  };
  /**
   *
   * @param {string} ListenerName 事件名
   * @param {func} func 回调函数
   * @returns 事件实例
   */
  static createListener = (ListenerName: string, func: Function): EventItemType => {
    try {
      const listKey = ListenerName;
      if (!this[listKey]) this[listKey] = [];
      const item: EventItemType = {
        key: ListenerName,
        func,
      };
      this[listKey].push(item as never);
      return item;
    } catch (error) {
      throw error;
    }
  };
}

export function getNumberPart(total: number) {
  const chipsUnit = getChipsUnit();
  const result: number[] = [];
  chipsUnit.reduceRight((prev: number, next: number) => {
    const unit = Math.floor(prev / next);
    result.unshift(unit);
    return prev % next;
  }, total);

  return result;
}

export function sumNumberPart(result: number[]) {
  const arrs: number[] = [];

  result?.forEach((element, index) => {
    if (element) {
      arrs?.unshift(...new Array(element).fill(index));
    }
  });

  return arrs;
}

/**
 * 格式化金额 3分位
 * @param amount
 * @returns
 */
export function formatAmount(amount: number) {
  const x = String(amount).split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : "";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

export function getChipsUnit() {
  const { currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;

  return isKRW ? chipsUnitKRW : chipsUnit;
}

/**
 * 格式化路图canvas数据
 * @param obj
 * @returns
 */
// export function formatResultList(obj: Record<string, Record<string, any>>) {
//   return Object.keys(obj)?.map((key) => formatResultItem(obj[key]));

//   function formatResultItem(item: any) {
//     const obj: InitData = {
//       pair: item.pair,
//       winner: "tie",
//     };
//     if (item.banker_val > item.player_val) {
//       obj.winner = "banker"; // 庄赢
//     } else if (item.banker_val < item.player_val) {
//       obj.winner = "player"; // 闲赢
//     }

//     return obj;
//   }
// }

// 初始化画布
export function initCanvas(
  id: string | HTMLCanvasElement,
  useRem = false,
  gridWidth: number,
  gridHeight: number,
  bgColor = "#FAF8F4"
) {
  const canvas = typeof id === "string" ? (document.getElementById(id) as HTMLCanvasElement) : id;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  let dpr = window.devicePixelRatio;
  dpr = Math.ceil(dpr);
  dpr = dpr >= 2 ? dpr : 1;

  canvas.width = gridWidth * dpr;
  canvas.height = gridHeight * dpr;
  canvas.style.setProperty("background", bgColor);

  canvas.style.setProperty("width", useRem ? `${gridWidth / 100}rem` : `${gridWidth}px`);
  canvas.style.setProperty("height", useRem ? `${gridHeight / 100}rem` : `${gridHeight}px`);

  ctx.scale(dpr, dpr);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  return ctx;
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * @description 获取uuid
 * @return string
 */
export function getUuid() {
  return uuid();
}

/**
 * 给一个dom添加动画
 * @param ele
 * @param animateParams
 * @param onFinish
 * @returns
 */
export function animateEle(ele: HTMLElement, animateParams, onFinish) {
  const { keyframes, options } = animateParams;

  const animation = ele.animate(keyframes, options);

  animation.onfinish = (e: AnimationPlaybackEvent) => {
    onFinish && onFinish();
  };

  return () => (animation as Animation).cancel();
}

function convertNumber(amount: number) {
  const { locale } = store.getState().config;

  // if (amount >= 1000 && amount < 1000000) return `${amount / 1000}K`;
  if (locale === "cn") {
    return `${CALC.divide(amount, 10000)}万`;
  } else {
    return formatAmount(amount);
  }
}

/**
 * 获取限红的展示数据
 * @param deskLimits 桌台限红
 * @param myLimits 我的限红
 */
export const getLimitsArr = (deskLimits: any[], myLimits: any[]) => {
  const types = [t("game:lotteryTypes.bp"), t("game:lotteryTypes.tie"), t("game:lotteryTypes.pair")];
  const options = [];

  const isKRW = store.getState().auth.currentTradeType?.currency_type === 3;
  const beishu = isKRW ? 200 : 1;

  deskLimits
    ?.map((limit) => ({
      ...limit,
      min_amount: CALC.multiply(limit.min_amount, beishu),
      max_amount: CALC.multiply(limit.max_amount, beishu),
    }))
    .forEach((item, index) => {
      const tableRed = `${item.min_amount}-${item.max_amount}`;
      const tableRedFmt = `${convertNumber(item.min_amount)}-${convertNumber(item.max_amount)}`;
      const limitRed = `${item.min_amount}-${item.max_amount}`;
      const limitRedFmt = `${convertNumber(item.min_amount)}-${convertNumber(item.max_amount)}`;
      const interval = `${item.min_amount}-${item.max_amount}`;
      const intervalFmt = `${convertNumber(item.min_amount)}-${convertNumber(item.max_amount)}`;

      options.push({
        betType: item.bet_type,
        area: types[index],
        limitRed,
        tableRed,
        interval,
        tableRedFmt,
        limitRedFmt,
        intervalFmt,
      });
    });

  return options;
};

/**
 * 根据桌台限红判断是否可以添加筹码
 * @param chipsStack 筹码堆
 * @param deskLimits 限红参数
 * @param category 玩法 庄 闲 和 对子
 * @param chipNum 筹码值
 * @returns boolean
 */
export function canAddChips(
  chipsStack: Record<BetType, number[]>,
  deskLimits: any[],
  category: BetType,
  chipNum: number[]
) {
  const deskLimit = deskLimits.find((deskLimit) => deskLimit.betType === categoryMap[category]);
  const chipsUnit = getChipsUnit();

  if (deskLimit) {
    const { interval } = deskLimit;

    if (interval === "-") return false;

    const maxAmount = interval.split("-")[1];

    for (const [key, value] of Object.entries(chipsStack)) {
      if (key === category) {
        const bet_amount =
          (value as number[]).reduce((prev: number, next: number) => prev + chipsUnit[next], 0) +
          chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);
        if (bet_amount <= Number(maxAmount)) return true;
      }
    }
    return false;
  }

  return false;
}

// 求数组交集
export const getRangeIntersection = (arr1: [number, number], arr2: [number, number] | [], fmt = false) => {
  const [x1, y1] = arr1;
  const [x2, y2] = arr2;

  if (!arr2.length) {
    return "-";
    // return fmt
    //   ? [convertNumber(Math.max(x1, 0)), convertNumber(Math.max(y1, 0))].join("-")
    //   : [Math.max(x1, 0), Math.max(y1, 0)].join("-");
  }

  if (y1 < x2 || x1 > y2) {
    return "-";
  } else {
    return fmt
      ? [convertNumber(Math.max(x1, x2)), convertNumber(Math.min(y1, y2))].join("-")
      : [Math.max(x1, x2), Math.min(y1, y2)].join("-");
  }
};

// 1 b 2 p 3 t 4 bp 5 pp

/**
 * 判断当前桌台是否可以提交
 * @param chipsStack
 * @param deskLimits
 * @returns
 */
export function isOrderCompleted(chipsStack: Record<BetType, number[]>, deskLimits: any[], betDataList) {
  let betCount = 0;

  const chipsUnit = getChipsUnit();
  const { account } = store.getState().auth;

  for (const [key, value] of Object.entries(chipsStack || {})) {
    const deskLimit = deskLimits.find((deskLimit) => deskLimit.betType === categoryMap[key]);

    if (!deskLimit) return false;

    const { interval } = deskLimit;

    if (interval === "-") {
      return false;
    }

    // const currentBetTypeTotal = betDataList[key];
    const currentBetTotal = getPlayerBets(account, betDataList, key as Shoes);

    const minAmount = Number(interval.split("-")[0]);

    const bet_amount =
      (value as number[]).reduce((prev: number, next: number) => prev + chipsUnit[next], 0) + currentBetTotal;
    betCount += value.length;
    if (bet_amount && bet_amount < minAmount) return false;
  }

  console.log(betCount, "bet_amountbet_amountbet_amountbet_amountbet_amountbet_amountbet_amount");
  return !!betCount;
}

export function sumChipsAmount(chips: number[]) {
  const chipsUnit = getChipsUnit();
  return chips?.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);
}

/**
 * 判断是否超过桌台限红
 */
export function isBeyondLimit(chipsStack: Record<BetType, number[]>, deskLimits: any[], betDataList: any[]) {
  const chipsUnit = getChipsUnit();

  // 庄下注总和
  const totalBanker =
    betDataList[0].chip + chipsStack.banker.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

  // 闲下注总和
  const totalPlayer =
    betDataList[1].chip + chipsStack.player.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

  // 庄对下注总和
  const totalBankerDouble =
    betDataList[2].chip + chipsStack.bankerDouble.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

  // 闲对下注总和
  const totalPlayerDouble =
    betDataList[3].chip + chipsStack.bankerDouble.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

  // 和下注总和
  const totalTie =
    betDataList[4].chip + chipsStack.tie.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

  const bpInterval = deskLimits[0].interval;
  const tieInterval = deskLimits[1].interval;
  const doubleInterval = deskLimits[2].interval;

  // 判断有没有超过庄闲
  if (/\d+\-\d+/.test(bpInterval)) {
    const maxGap = bpInterval.split("-")[1];

    if (Math.abs(totalBanker - totalPlayer) > Number(maxGap)) {
      return t("game:betLimit1");
    }
  }

  // 判断有没有超过和
  if (/\d+\-\d+/.test(tieInterval)) {
    const maxGap = tieInterval.split("-")[1];

    if (totalTie > Number(maxGap)) {
      return t("game:betLimit2");
    }
  }

  // 判断有没有超过对子上限
  if (/\d+\-\d+/.test(doubleInterval)) {
    const maxGap = doubleInterval.split("-")[1];

    if (totalBankerDouble > Number(maxGap) || totalPlayerDouble > Number(maxGap)) {
      return t("game:betLimit3");
    }
  }

  return false;
}

/**
 * 判断是否超过桌台限红
 */
export function isBeyondLimitNew(chipNum: number[], category: BetType, deskLimits: any[], betDataList: any[]) {
  const bpInterval = deskLimits[0].tableRed;
  const tieInterval = deskLimits[1].tableRed;
  const doubleInterval = deskLimits[2].tableRed;

  const chipsUnit = getChipsUnit();

  switch (category) {
    case BetType.BANKER:
    case BetType.PLAYER: {
      const totalBanker =
        category === BetType.BANKER
          ? betDataList[0].chip + chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0)
          : betDataList[0].chip;

      const totalPlayer =
        category === BetType.PLAYER
          ? betDataList[1].chip + chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0)
          : betDataList[1].chip;

      // 判断有没有超过庄闲
      if (/\d+\-\d+/.test(bpInterval)) {
        const maxGap = bpInterval.split("-")[1];

        if (Math.abs(totalBanker - totalPlayer) > Number(maxGap)) {
          return t("game:betLimit1");
        }
      }
      return false;
    }
    case BetType.TIE: {
      const totalTie = betDataList[4].chip + chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

      // 判断有没有超过和
      if (/\d+\-\d+/.test(tieInterval)) {
        const maxGap = tieInterval.split("-")[1];

        if (totalTie > Number(maxGap)) {
          return t("game:betLimit2");
        }
      }
      return false; // HKD
    }
    case BetType.BDOUBLE:
    case BetType.PDOUBLE: {
      const totalBankerDouble =
        category === BetType.BDOUBLE
          ? betDataList[2].chip + chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0)
          : betDataList[2].chip;

      const totalPlayerDouble =
        category === BetType.PDOUBLE
          ? betDataList[3].chip + chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0)
          : betDataList[3].chip;

      // 判断有没有超过对子上限
      if (/\d+\-\d+/.test(doubleInterval)) {
        const maxGap = doubleInterval.split("-")[1];

        if (totalBankerDouble > Number(maxGap) || totalPlayerDouble > Number(maxGap)) {
          return t("game:betLimit3");
        }
      }
      return false;
    }
    default:
      return false;
  }
}

/**
 * 获取玩家在本局投注金额
 * @param playerAccount 玩家账号
 * @param betDataList 当前桌所有玩家投注合计
 * @param gameType 玩法 庄闲和
 * @returns
 */
export function getPlayerBets(playerAccount: string, betDataList: any[], gameType?: Shoes) {
  const banker = betDataList[0].list
    ?.filter((bet) => bet.player_account === playerAccount)
    .reduce((prev, next) => prev + Number(next.bet_amount), 0);
  const player = betDataList[1].list
    ?.filter((bet) => bet.player_account === playerAccount)
    .reduce((prev, next) => prev + Number(next.bet_amount), 0);

  const bankerDouble = betDataList[2].list
    ?.filter((bet) => bet.player_account === playerAccount)
    .reduce((prev, next) => prev + Number(next.bet_amount), 0);

  const playerDouble = betDataList[3].list
    ?.filter((bet) => bet.player_account === playerAccount)
    .reduce((prev, next) => prev + Number(next.bet_amount), 0);

  const tie = betDataList[4].list
    ?.filter((bet) => bet.player_account === playerAccount)
    .reduce((prev, next) => prev + Number(next.bet_amount), 0);

  const betSummary = {
    banker,
    player,
    tie,
    bankerDouble,
    playerDouble,
  };

  if (gameType) {
    return betSummary[gameType];
  } else {
    return betSummary;
  }
}

/**
 * 是否可以添加筹码
 * @param deskLimits 限红
 * @param category 玩法
 * @param chipNum 投注
 * @param betAmount 当前玩法下注总和
 * @returns
 */
export function canAddChipsNew(deskLimits: any[], category: BetType, chipNum: number[], betAmount: number) {
  const deskLimit = deskLimits.find((deskLimit) => deskLimit.betType === categoryMap[category]);
  const chipsUnit = getChipsUnit();

  if (deskLimit) {
    const { interval } = deskLimit;

    if (interval === "-") return false;

    const maxAmount = interval.split("-")[1];

    const bet_amount = betAmount + chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);
    if (bet_amount <= Number(maxAmount)) return true;

    return false;
  }

  return false;
}

// 是否满足最低下注要求
export function isOrderInValid(deskLimits: any[], category: BetType, chipNum: number[], betAmount: number) {
  const deskLimit = deskLimits.find((deskLimit) => deskLimit.betType === categoryMap[category]);
  const chipsUnit = getChipsUnit();

  if (deskLimit) {
    const { interval } = deskLimit;

    if (interval === "-") return false;

    const minAmount = interval.split("-")[0];

    const bet_amount = betAmount + chipNum.reduce((prev: number, next: number) => prev + chipsUnit[next], 0);
    if (bet_amount < Number(minAmount)) return Number(minAmount) - bet_amount;

    return false;
  }

  return false;
}

export function hasOrders(chipsStack) {
  let count = 0;
  for (const [key, value] of Object.entries(chipsStack)) {
    count += (value as any).length;
  }

  return !!count;
}

/**
 * 提交游戏投注
 * @param betDetails
 */
export function submitOrders(
  chipsStack: Record<BetType, number[]>,
  desk: { desk_no: string; game_no: string; game_mod: number }
): Promise<any> {
  const betNumberMaps: Record<BetType, number> = {
    [BetType.BANKER]: 1,
    [BetType.PLAYER]: 2,
    [BetType.TIE]: 3,
    [BetType.BDOUBLE]: 4,
    [BetType.PDOUBLE]: 5,
  };
  const chipsUnit = getChipsUnit();

  console.log(chipsStack, "chipsStackchipsStackchipsStackchipsStack");

  const results = [];

  for (const [key, value] of Object.entries(chipsStack)) {
    const bet_amount = (value as number[]).reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

    if (bet_amount) {
      results.push({
        bet_amount,
        game_type: betNumberMaps[key],
      });
    }
  }

  // console.log(sumNumberPart(getNumberPart(755, chipsUnit)));

  // return gameAPI.submitBet({ betReq: results, _isNeedLoading: true });
  return (desk.game_mod === 3 ? gameAPI.smallSubmitBet : gameAPI.submitBet)({
    ...desk,
    bet_details: results,
    _isNeedLoading: true,
  });
}

// 是否是对子
export const isBPair = (arrs: string[]) => {
  return arrs.length >= 2 && arrs[0].substring(2) === arrs[1].substring(2);
};

// 是否是闲对子
export const isPPair = (arrs: string[]) => {
  return arrs.length >= 2 && arrs[arrs.length - 1].substring(2) === arrs[arrs.length - 2].substring(2);
};

export function getBeishu(isKRW: boolean, currencyId?: number) {
  if (isKRW) {
    if (currencyId === 3) {
      return 1;
    } else {
      return 200;
    }
  } else {
    if (currencyId === 3) {
      return 0.005;
    } else {
      return 1;
    }
  }
}

/**
 *
 * @param count 重复次数
 * @param fns 函数数组
 * @param interval 间隔
 */
export function repeatFn(count: number, fns: Array<(data?: any) => void>, interval = 1000, callback?) {
  if (!count) {
    callback && callback();
    return;
  }

  const localFns = [...fns];

  function execute() {
    const fn = localFns.shift();
    if (!fn) {
      count--;
      repeatFn(count, fns, interval);
      return;
    }

    fn();
    const timer = setTimeout(() => {
      execute();
      clearTimeout(timer);
    }, interval);
  }

  execute();
}

export function fmtAmount(amount: number | string) {
  const { locale } = store.getState().config;

  if (locale === "cn") {
    return `${retain(CALC.divide(Number(amount), 10000), 4)}万`;
  } else {
    return formatAmount(Number(amount));
  }
}

export function transformBet(payload: Record<number, number> = {}) {
  const betTotal = {
    banker: 0,
    player: 0,
    tie: 0,
    bankerDouble: 0,
    playerDouble: 0,
  };

  for (const [key, val] of Object.entries(payload)) {
    betTotal[resultMapsReverse.get(Number(key))] = val;
  }

  return betTotal;
}

/**
 * 不四舍五入保留小数
 * @param num
 * @param decimal
 * @returns
 */
export function retain(num, decimal) {
  num = num.toString();
  let index = num.indexOf(".");
  if (index !== -1) {
    const [a, b] = num.split(".");
    return `${a}.${b.substr(0, Math.min(b.length, decimal))}`;
    // num = num.substring(0, decimal + index + 1);
  } else {
    return num;
  }
  // return parseFloat(num).toFixed(decimal);
}

// 截取二维数组
// 别删了 ？？
export function sliceMatrix(arrs: Array<any[]>, num: number) {
  const arrLength = arrs.length;

  // 反向截取
  if (num < 0) {
    return Array.from({ length: arrLength }, (_, index: number) => {
      const arr = [];

      for (let i = arrs[0].length - 1; i >= arrs[0].length + num; i--) {
        const tempIndex = i;
        arr.unshift(arrs[index][tempIndex]);
      }
      return arr;
    });
    // 正向截取
  } else {
    return Array.from({ length: arrLength }, (_, index: number) => {
      const arr = [];

      for (let i = 0; i <= num - 1; i++) {
        const tempIndex = i;
        arr.push(arrs[index][tempIndex]);
      }
      return arr;
    });
  }
}

export function isLogin() {
  const state = store.getState();
  const lToken = state["auth"].access_token ?? localStorage.getItem("token");
  return (!!lToken && state["auth"].currentTradeType) || state["auth"].isVisitor;
}

export enum CurrencyType {
  PHP = 1,
  HKD = 2,
  KRW = 3,
  USDT = 4,
  CNY = 5,
  USD = 6,
}

export function smallKrwLimitChange(arr: any) {
  return arr.map((item) => ({
    ...item,
    max_amount: CALC.divide(item.max_amount || 0, 200),
    min_amount: CALC.divide(item.min_amount || 0, 200),
  }));
}

export function currencySymbol(currency_type: CurrencyType) {
  switch (currency_type) {
    case CurrencyType.PHP:
      return "₱"; // PHP
    case CurrencyType.HKD:
      return "元"; // HKD
    case CurrencyType.KRW:
      return "₩"; // KRW
    case CurrencyType.USDT:
      return "₮"; // USDT
    case CurrencyType.CNY:
      return "¥"; // CYN
    case CurrencyType.USD:
      return "$"; // CYN
    default:
      return "-";
  }
}

export const ErrMsgTranslateMaps = new Map([
  /** desk---- */
  ["d-10001", "errMsgs:DeskNotExist"],
  ["d-10002", "errMsgs:DeskNotOpen"],
  ["d-10003", "errMsgs:WalletNotExist"],
  ["d-10004", "errMsgs:MemberNameNotExist"],
  ["d-10005", "errMsgs:TokenErr"],
  ["d-10006", "errMsgs:ParamErr"],
  ["d-10007", "errMsgs:UnusedBetter"],
  ["d-10008", "errMsgs:AccountErr"],
  ["d-10009", "errMsgs:PwdErr"],
  ["d-10010", "errMsgs:MissBetter"],
  ["d-10011", "errMsgs:AmountBetter"],
  ["d-10028", "errMsgs:IntoGamingDeskDeskInfoById"],
  ["d-10029", "errMsgs:SelectTraditionApplysql"],

  /** player---- */
  ["p-10001", "errMsgs:AccountEmptyMsg"],
  ["p-10002", "errMsgs:AccountLenErr"],
  ["p-10003", "errMsgs:PasswordEmpty"],
  ["p-10004", "errMsgs:WithoutToken"],
  ["p-10005", "errMsgs:GetTokenErr"],
  ["p-10006", "errMsgs:VirificationCodeErr"],
  ["p-10007", "errMsgs:VirificationCodeWrong"],
  ["p-10008", "errMsgs:PasswordWrong"],
  ["p-10009", "errMsgs:LoginErr"],
  ["p-10010", "errMsgs:RefreshTokenErr"],
  ["p-10011", "errMsgs:PasswordLenWrong"],
  ["p-10012", "errMsgs:LogonAccountStatusWrong"],
  ["p-10013", "errMsgs:AccountNotExisted"],
  ["p-10014", "errMsgs:NotAllowedLogin"],
  ["p-10015", "errMsgs:NotAllowedBet"],
  ["p-10021", "errMsgs:BetsList"],
  ["p-10022", "errMsgs:getPlayerBetError"],
  ["p-10023", "errMsgs:getDeskLimitError"],
  ["p-10024", "errMsgs:betLimitError"],
  ["p-10025", "errMsgs:betLimitError"],
  ["p-10026", "errMsgs:betLimitError"],
  ["p-10027", "errMsgs:betLimitError"],
  ["p-10028", "errMsgs:betLimitError"],
  ["p-10029", "errMsgs:betLimitError"],
  ["p-10030", "errMsgs:betLimitError"],
  ["p-10031", "errMsgs:betLimitError"],
  ["p-10032", "errMsgs:betLimitError"],
  ["p-10033", "errMsgs:betLimitError"],
  ["p-10034", "errMsgs:getGameTimeError"],
  ["p-10035", "errMsgs:notAllowBetTime"],
  ["p-10036", "errMsgs:getProxyPlanError"],
  ["p-10037", "errMsgs:betAmountError"],
  ["p-10038", "errMsgs:getPlayerSetNoError"],

  /** game---- */
  ["g-10002", "errMsgs:GameLobbyErr"],
  ["g-10003", "errMsgs:InfoGameDeskErr"],
  ["g-10004", "errMsgs:BetErr"],
  ["g-10005", "errMsgs:FreeGameErr"],
  ["g-10006", "errMsgs:GiveTipsErr"],
  ["g-10007", "errMsgs:SearchGameDeskErr"],
  ["g-10008", "errMsgs:WsErr"],
  ["g-10009", "errMsgs:LastBetInfoGetErr"],
  ["g-10010", "errMsgs:ApplyBacicDeskErr"],
  ["g-10011", "errMsgs:IntoVipDeskErr"],
  ["g-10013", "errMsgs:BetGameTypeErr"],
  ["g-10014", "errMsgs:vipDeskIncorrectPassword"],
  ["g-10015", "errMsgs:BasicPlayerCantPlayOtherMod"],
  ["g-10016", "errMsgs:PlayerGameModNotMatchDeskGame"],
  ["g-10017", "errMsgs:BetAmountWrongWithSelf"],
  ["g-10018", "errMsgs:BetAmountWrongWithDesk"],
  ["g-10022", "errMsgs:RevokeBettingsErr"],
  ["g-10024", "errMsgs:InOtherTableErr"],
  ["g-10025", "errMsgs:CantFreeGame"],
  ["g-10026", "errMsgs:ChipsChangeNotDeal"],
  ["g-10027", "errMsgs:ExitBets"],
  ["g-10033", "errMsgs:cantLogout"],
  /** 接口异常 */
  ["syserr", "errMsgs:Syserr"],
]);

export const gameResultMap = {
  b: ["banker"], // banker
  p: ["player"], // player
  t: ["tie"], // tie
  q: ["banker", "bankerDouble"], // banker banker-pair
  w: ["banker", "bankerDouble", "playerDouble"], // banker banker-pair player-pair
  e: ["banker", "playerDouble"], // banker player-pair
  f: ["player", "bankerDouble"], // player banker-pair
  g: ["player", "bankerDouble", "playerDouble"], // player banker-pair player-pair
  h: ["player", "playerDouble"], // player player-pair
  i: ["tie", "bankerDouble"], // tie banker-pair
  j: ["tie", "bankerDouble", "playerDouble"], // tie banker-pair player-pair
  k: ["tie", "playerDouble"], // tie player-pair
};

// 计算字符串字节长度
export const countByte = (str, byteLong) => {
  if (!str)
    return {
      totalByteNum: 0,
      length: 0,
      subStr: "",
    };
  let totalByteNum = 0;
  let k = 0;
  let subStr = str;
  let isOver = false;
  for (let i = 0; i < str.length; i++) {
    console.log(i, str.charCodeAt(i));
    if (str.charCodeAt(i) > 255) {
      totalByteNum = totalByteNum + 2;
    } else {
      totalByteNum = totalByteNum + 1;
    }
    if (totalByteNum < byteLong) {
      k++;
    }
  }
  if (totalByteNum > str.length && totalByteNum > byteLong) {
    subStr = str?.substring(0, k);
    isOver = true;
  }
  return {
    totalByteNum: totalByteNum, // 总字节长度
    length: str.length, // 字符串长度
    subStr: subStr, // 根据需要的字节长度截取
    isOver: isOver, // 是否益处，true表示超出限制的长度
  };
};

const isKRW = () => {
  const { currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;
  return isKRW;
};

const betRegs: Record<
  string,
  {
    rule: (amount: number) => boolean;
    msg: string;
  }
> = {
  [BetType.BANKER]: {
    rule: (amount: number) => !(amount % (isKRW() ? 1000 * 200 : 1000)),
    msg: "errMsgs:betLimitBanker",
  },
  [BetType.TIE]: {
    rule: (amount: number) => !(amount % (isKRW() ? 100 * 200 : 100)),
    msg: "errMsgs:betLimitTie",
  },
  [BetType.BDOUBLE]: {
    rule: (amount: number) => !(amount % (isKRW() ? 100 * 200 : 100)),
    msg: "errMsgs:betLimitBP",
  },
  [BetType.PDOUBLE]: {
    rule: (amount: number) => !(amount % (isKRW() ? 100 * 200 : 100)),
    msg: "errMsgs:betLimitPP",
  },
};

// 投注前的拦截判断
export function betIntercept(sumMap: Record<BetType, number>, betReg = betRegs) {
  let flag = false;

  for (const [key, val] of Object.entries(sumMap)) {
    if (betRegs[key] && !betRegs[key].rule(val)) {
      flag = true;
      message.error(t(betRegs[key].msg));
      break;
    }
  }
  return flag;
}

// 投注前的拦截判断 多台
export function betInterceptMulti(sumMap: Record<BetType, number>) {
  let flag = false;

  console.log(sumMap, "sumMapsumMapsumMapsumMapsumMap");
  for (const [key, val] of Object.entries(sumMap)) {
    if (betRegs[key] && !betRegs[key].rule(val)) {
      flag = true;
      return betRegs[key].msg;
    }
  }

  return flag;
}
