import Dialog from "@/components/Dialog";
import { logoMap } from "@/config/const";
import { history } from "@/routers";
import { gameAPI } from "@/services";
import { dealChangeDesk, quitTraditionMod } from "@/services/game";
import { useAppSelector } from "@/store";
import {
  changeEnterClassicDesk,
  changeEnterLuxuryDesk,
  enterClassicDesk,
  enterLuxuryDesk,
  leaveDesk
} from "@/utils/functions/enterDesk";
import useCount from "@/utils/hooks/useCount";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDlgHooks } from "./hooks";

interface Props {
  visible?: boolean;
  is_change_club: number;
  onClose?: () => void;
  new_club_id: number;
  old_club_id: number;
  new_desk_no: string;
  old_desk_no: string;
  new_desk_id: number;
  old_desk_id: number;
}

const Text = styled.div`
  font-size: 18px;
  /* max-width: 200px; */
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;

  span {
    color: #e5c180;
  }
`;

const BetterApplyChange: FC<Props> = ({
  new_club_id,
  old_club_id,
  new_desk_no,
  new_desk_id,
  old_desk_id,
  visible,
  onClose,
  is_change_club,
  old_desk_no,
}) => {
  const { balance } = useAppSelector((state) => state.auth);
  const { remain, stop } = useCount(5, 1000);
  const { openLuxury } = useAppSelector(state => state.config)

  const { t } = useTranslation();

  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });

  async function confirm() {
    stop();
    setIsShow(false);
    const betPreference = localStorage.getItem("betPreference");

    const pathname = history.location.pathname;
    const inGameDesk = pathname.includes("/luxuryBet") || pathname.includes("/classic");
    const isLuxury = pathname.includes("luxuryBet");

    await dealChangeDesk({
      old_desk_id,
      new_desk_id,
      state: 1,
    });

    if (inGameDesk) {
      // 换厅需先退出传统
      if (is_change_club === 1) {
        const { msg } = await quitTraditionMod({ desk_no: old_desk_no, _isNeedLoading: true });
        if (msg !== "success") return;
      }
      const leaveDeskSuccess = await leaveDesk(old_desk_id);
      if (!leaveDeskSuccess) return;

      is_change_club === 1 &&
        (await gameAPI.applySpecialService({
          desk_id: new_desk_id,
          desk_no: new_desk_no,
          area_code: "",
          phone: "",
          chips_amount: Number(balance),
        }));
      if (isLuxury) {
        is_change_club === 1 ? await enterLuxuryDesk(new_desk_id) : await changeEnterLuxuryDesk(new_desk_id);
      } else {
        is_change_club === 1 ? await enterClassicDesk(new_desk_id) : await changeEnterClassicDesk(new_desk_id);
      }

      // 在大厅里面
    } else {
      if (is_change_club === 1) {
        const { msg } = await quitTraditionMod({ desk_no: old_desk_no, _isNeedLoading: true });
        if (msg !== "success") return;
      }

      const leaveDeskSuccess = await leaveDesk(old_desk_id);
      if (!leaveDeskSuccess) return;

      is_change_club === 1 &&
        (await gameAPI.applySpecialService({
          desk_id: new_desk_id,
          desk_no: new_desk_no,
          area_code: "",
          phone: "",
          chips_amount: Number(balance),
        }));
      if (openLuxury && !!Number(betPreference)) {
        is_change_club === 1 ? await enterLuxuryDesk(new_desk_id) : await changeEnterLuxuryDesk(new_desk_id);
      } else {
        is_change_club === 1 ? await enterClassicDesk(new_desk_id) : await changeEnterClassicDesk(new_desk_id);
      }
    }
  }

  const decline = async () => {
    stop();
    await dealChangeDesk({
      old_desk_id,
      new_desk_id,
      state: 2,
      _isNeedLoading: true,
    });
    setIsShow(false);
  };

  useEffect(() => {
    !remain && confirm();
  }, [remain]);

  return (
    <Dialog
      title={t("game:switchTraditionTable")}
      visible={isShow}
      buttons={[
        {
          text: t("modalChipChoose:buttonCancel"),
          action: decline,
        },
        {
          text: `${t("modalChipChoose:buttonConfirm")} ${remain}s`,
          action: confirm,
        },
      ]}
    >
      <Text>
        <div>{t("SwitchDesk:betterNowChangeDeskForYou")}</div>
        <div>
          {t("SwitchDesk:Casino")}
          <span>{logoMap.get(new_club_id)}</span>
        </div>
        <div>
          {t("SwitchDesk:Table")}：<span>{new_desk_no}</span>
        </div>
        <div>{t("SwitchDesk:confirmSwitch")}</div>
      </Text>
    </Dialog>
  );
};

export default BetterApplyChange;
