import React from "react";
import { DeskStatus, GameMod, PackedDeskStatus, PackedDeskStatusMap, deskStatusMap, gameModMap } from "@/components/deskPick/types.d";
import { useTranslation } from "react-i18next";
import scss from "./index.module.scss";
import classnames from "classnames/bind";

const cn = classnames.bind(scss);

interface Props {
  isSmall?: boolean;
}

const StatusCard = ({ desk_status, game_mod, isSmall,is_vip }) => {
  const { t } = useTranslation();

  // 维护
  if ([DeskStatus.MaintainDesk].includes(desk_status)) {
    return (
      <div className={scss.mask}>
        <div className={cn(scss.mask__wrap, { small: isSmall })}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.9298 12.98C19.9698 12.66 19.9998 12.34 19.9998 12C19.9998 11.66 19.9698 11.34 19.9298 11.02L22.0398 9.37C22.2298 9.22 22.2798 8.95 22.1598 8.73L20.1598 5.27C20.0398 5.05 19.7698 4.97 19.5498 5.05L17.0598 6.05C16.5398 5.65 15.9798 5.32 15.3698 5.07L14.9898 2.42C14.9598 2.18 14.7498 2 14.4998 2H10.4998C10.2498 2 10.0398 2.18 10.0098 2.42L9.62984 5.07C9.01984 5.32 8.45984 5.66 7.93984 6.05L5.44984 5.05C5.21984 4.96 4.95984 5.05 4.83984 5.27L2.83984 8.73C2.70984 8.95 2.76984 9.22 2.95984 9.37L5.06984 11.02C5.02984 11.34 4.99984 11.67 4.99984 12C4.99984 12.33 5.02984 12.66 5.06984 12.98L2.95984 14.63C2.76984 14.78 2.71984 15.05 2.83984 15.27L4.83984 18.73C4.95984 18.95 5.22984 19.03 5.44984 18.95L7.93984 17.95C8.45984 18.35 9.01984 18.68 9.62984 18.93L10.0098 21.58C10.0398 21.82 10.2498 22 10.4998 22H14.4998C14.7498 22 14.9598 21.82 14.9898 21.58L15.3698 18.93C15.9798 18.68 16.5398 18.34 17.0598 17.95L19.5498 18.95C19.7798 19.04 20.0398 18.95 20.1598 18.73L22.1598 15.27C22.2798 15.05 22.2298 14.78 22.0398 14.63L19.9298 12.98ZM12.4998 15.5C10.5698 15.5 8.99984 13.93 8.99984 12C8.99984 10.07 10.5698 8.5 12.4998 8.5C14.4298 8.5 15.9998 10.07 15.9998 12C15.9998 13.93 14.4298 15.5 12.4998 15.5Z"
              fill="#D4BA8A"
            />
          </svg>
          <span style={{ marginLeft: 10 }}>{t(deskStatusMap.get(3))}</span>
        </div>
      </div>
    );
  }
  // 包桌
  // if (game_mod === GameMod.VipGame) {
    if (is_vip === PackedDeskStatus.Packed) {
      return (
        <div className={scss.mask}>
          <div className={cn(scss.mask__wrap, { small: isSmall })}>{t(PackedDeskStatusMap.get(is_vip))}</div>
        </div>
      );
    }
  // 换靴
  if ([DeskStatus.ChangingCardDesk, DeskStatus.WeiShu].includes(desk_status)) {
    return (
      <div className={scss.mask}>
        <div className={cn(scss.mask__wrap, { small: isSmall })}>{t(deskStatusMap.get(desk_status))}</div>
      </div>
    );
  }

  return null;
};

export default StatusCard;
