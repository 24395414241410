/**
 * 多台选择界面
 */
import { FC, useMemo } from "react";
import { Row } from "@/components/flex";
import { HeaderButton } from "@/components/Header/styledComponents";
import Icon from "@/components/Icon";
import styled from "styled-components";
import * as Modals from "./Modals";
import { useAppDispatch, useAppSelector } from "@/store";
import { getDeskAmountSelector, setDeskAmount } from "@/store/slices/config.slice";
import { useTranslation } from "react-i18next";
import { setDeskPickOptions } from "@/store/slices/bet.slice";
import col9 from "@/assets/images/common/cloumn_9.png";
import col12 from "@/assets/images/common/cloumn_12.png";
import col9Active from "@/assets/images/common/cloumn_9_active.svg";
import col12Active from "@/assets/images/common/clomun_12_active.svg";
import scss from "@/components/Dialog/index.module.scss";
import classNames from "classnames/bind";
import theme from "@/config/theme";

const cn = classNames.bind(scss);
const TableIcon = styled(Icon)`
  color: #ffd78e;
`;

interface SelectedTableStyledProps {
  isSelected?: Boolean;
}

const SelectedTableButton = styled.button<SelectedTableStyledProps>`
  width: 46px;
  height: 34px;
  cursor: pointer;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  vertical-align: top;

  &.col9 {
    background-image: url(${col9});
  }

  &.col12 {
    background-image: url(${col12});
  }

  &.active {
    &.col9 {
      background-image: url(${col9Active});
    }

    &.col12 {
      background-image: url(${col12Active});
    }
  }
`;

const HeaderRightMultipleTable: FC = () => {
  const deskAmount = useAppSelector(getDeskAmountSelector);
  const { t } = useTranslation();
  const {
    lobbyDesksInfo: { desk_vo },
    deskPickOptions,
  } = useAppSelector((state) => state.bet);
  const dispatch = useAppDispatch();
  const selectTable = (amount: 9 | 12) => {
    dispatch(setDeskAmount(amount));
  };

  const handleCasinoChoose = (club_id) => dispatch(setDeskPickOptions({ ...deskPickOptions, club_id }));
  const handleBetTypeChoose = (game_mod) => dispatch(setDeskPickOptions({ ...deskPickOptions, game_mod }));
  const handleBetLimitChoose = (sort_rule) => dispatch(setDeskPickOptions({ ...deskPickOptions, sort_rule }));
  const { game_mod, sort_rule, club_id } = useAppSelector((state) => state.bet.deskPickOptions);

  const betTypes = {
    "0": { label: t("betType:typeAllServicesLabel"), value: 0 },
    "1": { label: t("betType:typeFastLabel"), value: 1 },
    "2": { label: t("betType:typeTraditionalLabel"), value: 2 },
    // '3': { label: t('betType:typeSmallLabel'), value: 3 },
  };
  const sort = {
    " ": { label: t("betLimit:betLimitNotSortedLabel"), value: "" },
    asc: { label: t("betLimit:betLimitLowToHighLabel"), value: "asc" },
    desc: { label: t("betLimit:betLimitHighToLowLabel"), value: "desc" },
  };
  const casinos = {
    0: { label: t("common:buttonAllCasino"), value: 0 },
    5: { label: t("common:buttonSolaireCasino"), value: 5 },
  };
  const deskNum = useMemo(() => {
    return desk_vo?.filter((item) => item.club_id === 5)?.length ?? 0;
  }, [desk_vo]);
  return (
    <Row style={{ paddingRight: "30px" }}>
      <TableIcon type="icon-a-desktop-num" color={theme.theme_color} />
      <span
        style={{
          fontSize: "15px",
          color: "#fff",
          marginLeft: "12px",
          whiteSpace: "nowrap",
        }}
      >
        {t("totalDesktopNum")}:
      </span>
      <span
        style={{
          fontSize: "18px",
          fontWeight: "700",
          color: "#fff",
          marginLeft: "10px",
        }}
      >
        {deskNum}
      </span>
      <div onClick={() => selectTable(9)}>
        <SelectedTableButton
          isSelected={deskAmount === 9}
          style={{ marginLeft: "40px" }}
          className={["col9", deskAmount === 9 ? "active" : ""].join(" ")}
        />
      </div>
      <div onClick={() => selectTable(12)}>
        <SelectedTableButton
          isSelected={deskAmount === 12}
          style={{ marginLeft: "14px" }}
          className={["col12", deskAmount === 12 ? "active" : ""].join(" ")}
        />
      </div>
      {/* <button
        style={{ minWidth: 104, marginLeft: 20 }}
        className={cn(scss.header_btn, club_id && scss.primary)}
        onClick={() => Modals.showCasino(handleCasinoChoose)}
      >
        {casinos[club_id].label}
      </button> */}
      {!process.env.REACT_APP_POGO && (
        <button
          style={{ minWidth: 104, marginLeft: 20 }}
          className={cn(scss.header_btn, game_mod && scss.primary)}
          onClick={() => Modals.showBetType(handleBetTypeChoose)}
        >
          {betTypes[game_mod]?.label}
        </button>
      )}
      <button
        style={{ minWidth: 104, marginLeft: 20 }}
        className={cn(scss.header_btn, sort_rule && scss.primary)}
        onClick={() => Modals.showBetLimit(handleBetLimitChoose)}
      >
        {sort[sort_rule]?.label || t("betLimit:betLimitNotSortedLabel")}
      </button>
    </Row>
  );
};

export default HeaderRightMultipleTable;
