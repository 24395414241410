import React from "react";
import styled from "styled-components";
import Dialog from "@/components/Dialog";
import { Row, Column } from "@/components/flex";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import { useAppSelector } from "@/store";
import { useTranslation } from "react-i18next";

const Container = styled(Column)`
  padding: 20px;
`;

const Header = styled(Row)`
  color: #e5c180;
  font-size: 18px;
`;

interface ImgStyledProps {
  src: string;
  value: number;
  isSelected: boolean;
}
const Img = styled(Row)<ImgStyledProps>`
  position: relative;
  width: 671px;
  height: 144px;
  background-size: 100%;
  background: ${(props) => `url(${props.src}) no-repeat center`};
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    &:before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transition: background-color 500ms ease;
      background-color: ${(props) => {
        switch (props.value) {
          case 0:
            return "rgba(25, 39, 32, 0.9)";
          case 1:
            return "rgba(39, 67, 140, 0.9)";
          case 2:
          default:
            return "rgba(0, 0, 0, 0.9)";
        }
      }};
    }

    .front-text {
      transform: translate(-208px, 0) scale(0.5);
    }
  }

  &.active {
    &:before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transition: background-color 500ms ease;
      background-color: ${(props) => {
        switch (props.value) {
          case 0:
            return "rgba(25, 39, 32, 0.9)";
          case 1:
            return "rgba(39, 67, 140, 0.9)";
          case 2:
          default:
            return "rgba(0, 0, 0, 0.9)";
        }
      }};
    }

    .front-text {
      transform: translate(-208px, 0) scale(0.5);
    }
  }

  .front-text {
    color: #fff;
    font-size: 46px;
    transition: all 500ms ease;
    transform: translate(0, 0) scale(0.5);

    /* transform: ${(props) => (props.isSelected ? "translate(0,0)" : "translate(-208px, 0)")}
      scale(0.5); */
  }

  span {
    margin-left: -70px;
  }

  & + & {
    margin-top: 20px;
  }
`;

interface Props {
  visible?: boolean;
  onClose?: () => void;
  onChoose?: (value) => void;
}
const casinos = [
  {
    value: 0,
    bgImgSrc: require("@/assets/images/bet/casino1.png"),
    textImgSrc: require("@/assets/images/bet/casinoName_1.png"),
  },
  {
    value: 5,
    bgImgSrc: require("@/assets/images/bet/casino5.png"),
    textImgSrc: require("@/assets/images/bet/casinoName_5.png"),
  },
];

const CasinoPick: React.FC<Props> = ({ visible, onClose, onChoose }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const { club_id } = useAppSelector((state) => state.bet.deskPickOptions);
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("chooseCasino:title")}
      visible={isShow}
      zIndex={8000}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <Container>
        {casinos?.map((item, index) => {
          return (
            <Img
              key={index}
              src={item.bgImgSrc}
              value={item.value}
              justify="center"
              isSelected={item.value === club_id}
              className={item.value === club_id ? "active" : ""}
              onClick={() => {
                setIsShow(false);
                onChoose(item.value);
              }}
            >
              {!index ? (
                <span className="front-text">{t("common:buttonAllCasino")}</span>
              ) : (
                <img src={item.textImgSrc} className="front-text"></img>
              )}
            </Img>
          );
        })}
      </Container>
    </Dialog>
  );
};

export default CasinoPick;
