/**
 * 筹码选择
 */
import { FC, useState } from "react";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Dialog from "@/components/Dialog";
import MyButton from "@/components/MyButton";
import { Row } from "@/components/flex";
import styled from "styled-components";
import { Chip } from "@/components/Icon";
import chipBg from "@/assets/images/common/chipsBg.png"
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/store";
import { getChipTypeSelector, setChipType } from "@/store/slices/config.slice";

interface ChipsGroupStyledProps {
  isSelected: boolean
}

const ChipsGroup = styled(Row)<ChipsGroupStyledProps>`
  width: 434px;
  height: 124px;
  padding: 25px 28px;
  ${props => props.isSelected ? `background: url(${chipBg})` : `background-color: #1d1d1d`};
  border-radius: 10px;
  cursor: pointer;

  & + & {
    margin-top: 14px;
  }
`
type ChipType = 0 | 1 | 2

const chipList = [
  {
    id: 0,
    chipAmount_1000: '1,000',
    chipAmount_10000: '10,000',
    chipAmount_50000: '50,000',
    chipAmount_500000: '500,000'
  },
  {
    id: 1,
    chipAmount_1000: '1K',
    chipAmount_10000: '10K',
    chipAmount_50000: '50K',
    chipAmount_500000: '500K'
  },
  {
    id: 2,
    chipAmount_1000: '1千',
    chipAmount_10000: '1万',
    chipAmount_50000: '5万',
    chipAmount_500000: '50万'
  }
]

interface Props {
  visible?: boolean,
  onClose?: () => void
}

const ChipChose: FC<Props> = ({ visible, onClose }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose })
  const { t } = useTranslation()
  const chipType = useAppSelector(getChipTypeSelector)
  const [chipState, setChipState] = useState<ChipType>(chipType)
  const dispatch = useAppDispatch()
  const confirm = () => {
    // todo
    dispatch(setChipType(chipState))
    setIsShow(false)
  }

  return (
    <Dialog
      title={t('modalChipChoose:title')}
      visible={isShow}
      buttons={[
        {
          text: t('modalChipChoose:buttonCancel'),
          action: () => setIsShow(false)
        },
        {
          text: t('modalChipChoose:buttonConfirm'),
          action: confirm
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}>
      <div style={{ padding: '30px 66px 0' }}>
        {
          chipList?.map((item, index) => (
            <ChipsGroup isSelected={chipState === item.id} key={index} justify='space-between' ailgn='center'
              onClick={() => setChipState(item.id as ChipType)}>
              {chipState === item.id ? <Chip text={item.chipAmount_1000} color='purple'/> :
                <Chip text={item.chipAmount_1000}/>}
              {chipState === item.id ? <Chip text={item.chipAmount_10000} color='green'/> :
                <Chip text={item.chipAmount_10000}/>}
              {chipState === item.id ? <Chip text={item.chipAmount_50000} color='red'/> :
                <Chip text={item.chipAmount_50000}/>}
              {chipState === item.id ? <Chip text={item.chipAmount_500000} color='black'/> :
                <Chip text={item.chipAmount_500000}/>}
            </ChipsGroup>
          ))
        }
      </div>
      {/* <Row style={{ width: '325px', margin: '10px auto 18px', padding: '15px 10px' }} justify="center" ailgn="center"> */}
      {/*   <MyButton cancel name={t('modalChipChoose:buttonCancel')} width={140} onClick={() => setIsShow(false)}/> */}
      {/*   <MyButton styles={{ marginLeft: '25px' }} width={140} name={t('modalChipChoose:buttonConfirm')} */}
      {/*     onClick={confirm}/> */}
      {/* </Row> */}
    </Dialog>
  )
}

export default ChipChose
