import { Communicator } from '@/utils/tool';
import { useEffect } from "react";
import { MyWS } from "@/utils/websocket";
import store, { useAppDispatch } from "@/store";

const useEventSubscribe = (
  eventName: string,
  callback: (data: any) => void
) => {
  useEffect(() => {

    if (!MyWS.instance) {
      Communicator.createListener('WS_init', data => {
        MyWS.instance.createEventListener(eventName, callback);
      })
    } else {

      MyWS.instance.createEventListener(eventName, callback);
    }

    return () => {
      MyWS.instance?.removeEventListener(eventName);
    }
  }, [MyWS]);
};

export default useEventSubscribe;
