import React, {FC, useEffect, useState} from "react";
import Dialog from "@/components/Dialog";
import {useDlgHooks} from "@/components/Header/Modals/hooks";
import styled from "styled-components";
import Icon from "@/components/Icon";
import {Row} from "@/components/flex";
import MyButton from "@/components/MyButton";
import {useAppDispatch, useAppSelector} from "@/store";
import {getLocaleListSelector, getLocaleSelector, Locale, setLocale} from "@/store/slices/config.slice";
import { useTranslation } from "react-i18next";
import scss from '@/components/ListSelect/index.module.scss'
import classNames from 'classnames/bind'

const cn = classNames.bind(scss)

const List = styled.ul`

`

const ListItem = styled(Row)`
  padding-left: 40px;
  padding-right: 18px;
  height: 60px;
  cursor: pointer;
  &:hover {
    background-color: #1F1D1A;
    &.label {
      color: #E5C180;
    }
  }
`
const Label = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`

interface Props {
  visible?: boolean,
  onClose?: () => void
}

const Locales:FC<Props> = ({visible,onClose}) => {
  // IStorage
  const stateLocale = useAppSelector(getLocaleSelector)
  const locals = useAppSelector(getLocaleListSelector)

  const dispatch = useAppDispatch();
  const {isShow, setIsShow, close} = useDlgHooks({visible, onClose})
  const [selectedLocale, setSelectedLocale] = useState<Locale>('cn')
  const {t} = useTranslation()
  const confirm = () => {
    // todo
    dispatch(setLocale(selectedLocale))
    setIsShow(false)
  }

  useEffect(() => {
    setSelectedLocale(stateLocale)
  }, [])

  return (
    <Dialog
      title={t('locales:title')}
      visible={isShow}
      buttons={[
        {
          text: t('locales:buttonCancel'),
          action: () => setIsShow(false)
        },
        {
          text: t('locales:buttonConfirm'),
          action: confirm
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}>
      {/* <List> */}
      {/*   { */}
      {/*     locals?.map((item, index) => ( */}
      {/*       <ListItem key={index} ailgn="center" justify="space-between" onClick={() => setSelectedLocale(item.value as Locale)}> */}
      {/*         <Label className="label"> */}
      {/*           <img width={45} height={45} style={{marginRight: '20px'}} src={item.icon}/> */}
      {/*           {item.label} */}
      {/*         </Label> */}
      {/*         { selectedLocale === item.value as Locale &&  <Icon type='icon-circle-check' color="#6C935D" /> } */}
      {/*       </ListItem> */}
      {/*     )) */}
      {/*   } */}
      {/* </List> */}
      <ul className={scss.list}>
        {
          locals?.map((item, index) => (
            <li
              key={index}
              className={cn(scss.list__item, { active: selectedLocale === item.value as Locale })}
              onClick={() => setSelectedLocale(item.value as any)}>
              <img width={45} height={45} style={{marginRight: '20px'}} src={item.icon} alt=""/>
              <span className={scss.list__text}>{item.label}</span>
              {
                selectedLocale === item.value as Locale &&
                <span className={scss.list__icon}/>
              }
            </li>
          ))
        }
      </ul>
      {/* <Row style={{width: '325px', margin: '10px auto 18px', padding: '15px 10px'}} justify="center" ailgn="center"> */}
      {/*   <MyButton cancel name={t('locales:buttonCancel')} width={140} onClick={() => setIsShow(false)}/> */}
      {/*   <MyButton styles={{marginLeft: '25px'}} width={140} name={t('locales:buttonConfirm')} onClick={confirm}/> */}
      {/* </Row> */}
    </Dialog>
    )
}

export default Locales
