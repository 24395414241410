import { RootState } from "@/store";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { DeskInfo, LotteryResult } from "@/store/types/bet";

export enum DeskStatus {
  OPEN = "OPEN",
  COUNT = "COUNT",
  DISABLE = "DISABLE",
}

export enum BetType {
  BANKER = "banker",
  PLAYER = "player",
  TIE = "tie",
  PDOUBLE = "playerDouble",
  BDOUBLE = "bankerDouble",
}

export enum DeskResult {
  BANKER = "BANKER",
  PLAYER = "PLAYER",
  TIE = "TIE",
  NONE = "",
}

interface Result {
  banker: string[];
  player: string[];
  bankerTotal: number;
  playerTotal: number;
  result: "banker" | "player" | "tie";
}

interface State {
  currentDesk: Array<number | string>;
  /** 桌台状态 开放 倒计时 停止下注 */
  deskStatus: DeskStatus;
  chipsStack: any;
  /** 所有桌台的信息 */
  deskInfos: DeskInfo[];
  /** 是否处于开奖 */
  isNowLottery: number[];
  lotteryResults: LotteryResult[];
  /** 当前多台添加的桌 序号 */
  currentChoose: number;
}

const name = "multi";

const initialState: State = {
  currentDesk: [0, 0, 0, 0],
  deskStatus: DeskStatus.OPEN,
  chipsStack: [
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
    },
  ],
  isNowLottery: [0, 0, 0, 0],
  lotteryResults: [{}, {}, {}, {}] as LotteryResult[],
  deskInfos: [{}, {}, {}, {}] as DeskInfo[],
  currentChoose: 0,
};

const betSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentDesk(state, action: PayloadAction<Array<number | string>>) {
      state.currentDesk = action.payload;
    },
    setDeskStatus(state, action: PayloadAction<DeskStatus>) {
      state.deskStatus = action.payload;
    },
    setDeskInfos(state, action: PayloadAction<DeskInfo[]>) {
      state.deskInfos = action.payload;
    },
    setCurrentChoose(state, action: PayloadAction<number>) {
      state.currentChoose = action.payload;
    },
    setChipsStack(state, action: PayloadAction<any>) {
      console.log("PayloadActionPayloadAction", action.payload);
      const { desk, chipStack } = action.payload;
      state.chipsStack[desk] = chipStack;
    },
    resetChipsStack(state, action: PayloadAction<number>) {
      state.chipsStack[action.payload] = {
        playerDouble: [],
        tie: [],
        bankerDouble: [],
        player: [],
        banker: [],
      };
    },
    setIsLottery(state, action: PayloadAction<{ index: number; isLottery: number }>) {
      const { index, isLottery } = action.payload;
      state.isNowLottery[index] = isLottery;
    },
    setLotteryResults(state, action: PayloadAction<{ index: number; result: LotteryResult }>) {
      const { index, result } = action.payload;
      state.lotteryResults[index] = result;
    },
  },
});

// export const selectLotteryResults = (state: { [x: string]: any }) => state.multi.lotteryResults;

export const {
  setChipsStack,
  resetChipsStack,
  setIsLottery,
  setDeskInfos,
  setLotteryResults,
  setDeskStatus,
  setCurrentDesk,
  setCurrentChoose,
} = betSlice.actions;

export default betSlice;
