import { HotRoadType } from "@/store/types/bet.d";

export const imgList = [
  {
    imgSrc: require("@/assets/images/road/road_changzhuang.png"),
    hotType: HotRoadType.LB,
  },
  {
    imgSrc: require("@/assets/images/road/road_changxian.png"),
    hotType: HotRoadType.Lp,
  },
  {
    imgSrc: require("@/assets/images/road/road_dantiao.png"),
    hotType: HotRoadType.Dt,
  },
  {
    imgSrc: require("@/assets/images/road/road_shuangtiao.png"),
    hotType: HotRoadType.St,
  },
  {
    imgSrc: require("@/assets/images/road/road_yitingliangfang.png"),
    hotType: HotRoadType.Tf,
  },
  {
    imgSrc: require("@/assets/images/road/road_fengzhuangtiao.png"),
    hotType: HotRoadType.Bt,
  },
  {
    imgSrc: require("@/assets/images/road/road_fengxiantiao.png"),
    hotType: HotRoadType.Pt,
  },
  {
    imgSrc: require("@/assets/images/road/road_fengzhuangchi.png"),
    hotType: HotRoadType.Bl,
  },
  {
    imgSrc: require("@/assets/images/road/road_fengxianchi.png"),
    hotType: HotRoadType.Pl,
  },
  {
    imgSrc: require("@/assets/images/road/road_paipaichi.png"),
    hotType: HotRoadType.Ll,
  },
  {
    imgSrc: require("@/assets/images/road/road_gechihzhuang.png"),
    hotType: HotRoadType.Gb,
  },
  {
    imgSrc: require("@/assets/images/road/road_gechixian.png"),
    hotType: HotRoadType.Gp,
  },
];

// 所有好路推荐的列表
export const hotRoadRecommend = [
  {
    imgSrc: require("@/assets/images/road/road_changzhuang.png"),
    title: "goodRoadTips:roadMapChangzhuangTitle",
    hotType: HotRoadType.LB,
    checked: true,
    tips: "goodRoadTips:roadMapChangzhuangTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_changxian.png"),
    title: "goodRoadTips:roadMapLongLeisureTitle",
    hotType: HotRoadType.Lp,
    checked: true,
    tips: "goodRoadTips:roadMapLongLeisureTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_dantiao.png"),
    title: "goodRoadTips:roadMapSingleHopTitle",
    hotType: HotRoadType.Dt,
    checked: true,
    tips: "goodRoadTips:roadMapSingleHopTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_shuangtiao.png"),
    title: "goodRoadTips:roadMapDoubleJumpTitle",
    hotType: HotRoadType.St,
    checked: true,
    tips: "goodRoadTips:roadMapDoubleJumpTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_yitingliangfang.png"),
    title: "goodRoadTips:roadMap1hall2BedroomsTitle",
    hotType: HotRoadType.Tf,
    checked: true,
    tips: "goodRoadTips:roadMap1hall2BedroomsTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_fengzhuangtiao.png"),
    title: "goodRoadTips:roadMapFengzhuangDanceTitle",
    hotType: HotRoadType.Bt,
    checked: true,
    tips: "goodRoadTips:roadMapFengzhuangDanceTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_fengxiantiao.png"),
    title: "goodRoadTips:roadMapEveryFreeDanceTitle",
    hotType: HotRoadType.Pt,
    checked: true,
    tips: "goodRoadTips:roadMapEveryFreeDanceTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_fengzhuangchi.png"),
    title: "goodRoadTips:roadMapFengzhuangGuiTitle",
    hotType: HotRoadType.Bl,
    checked: true,
    tips: "goodRoadTips:roadMapFengzhuangGuiTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_fengxianchi.png"),
    title: "goodRoadTips:roadMapEveryFreeTimeTitle",
    hotType: HotRoadType.Pl,
    checked: true,
    tips: "goodRoadTips:roadMapEveryFreeTimeTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_paipaichi.png"),
    title: "goodRoadTips:roadMapPatChiTitle",
    hotType: HotRoadType.Ll,
    checked: true,
    tips: "goodRoadTips:roadMapPatChiTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_gechizhuang.png"),
    title: "goodRoadTips:roadMapGeqiZhuangTitle",
    hotType: HotRoadType.Gb,
    checked: true,
    tips: "goodRoadTips:roadMapGeqiZhuangTips",
  },
  {
    imgSrc: require("@/assets/images/road/road_gechixian.png"),
    title: "goodRoadTips:roadMapFreeTimeTitle",
    hotType: HotRoadType.Gp,
    checked: true,
    tips: "goodRoadTips:roadMapFreeTimeTips",
  },
];

export const logoMap = new Map([
  [1, "Okada"],
  [2, "City of Dreams"],
  [3, "Hann"],
  [4, "Newport World Resorts"],
]);
