import store, { RootState } from "@/store";
import { setLobbyDesksInfo,setChangeDesksInfo, setShowTablePick } from "@/store/slices/bet.slice";
import { DeskInfo } from "@/store/types/bet";
import { Log } from "@/utils";
import useEventSubscribe from "@/utils/hooks/useEventSubscribe";
import { WS_Event } from "@/utils/wsEvent";
import { useSetState } from "ahooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SlideModal from "../slideModal";
import TablePick from "./tablePick";
import { history } from "@/routers";
import {useTranslation} from "react-i18next";
import {DeskStatusChange} from '@/utils/ws/handler'
import { message } from "@/components/Message";

const Index = () => {
  const [{ showMask }, setState] = useSetState<any>({
    showMask: "",
  });
  const dispatch = useDispatch();
  const { showTablePick } = useSelector((state: RootState) => state.bet);

  useEventSubscribe(WS_Event.LobbyGameEvent, receiveDeskInfo);
  useEventSubscribe(WS_Event.DeskStatusChangeEvent, DeskStatusChange);

  // 接受后端推送的新桌台信息
  function receiveDeskInfo (data: DeskInfo) {
    Log({
      message: '收到大厅桌台事件',
      data
    })
    console.log('tablePick====ws事件receiveDeskInfo', data)
    const { desk_vo, club_num, desk_num } = store.getState().bet.lobbyDesksInfo
    // 判断新推送的是否是新桌台
    const index = (desk_vo || []).findIndex((item) => item.desk_no === data.desk_no);
    // 存在
    if (index !== -1) {
      const tempDeskVo = [...desk_vo];
      tempDeskVo[index] = { ...tempDeskVo[index], ...data };
      dispatch(
        setLobbyDesksInfo({
          club_num,
          desk_num,
          desk_vo: tempDeskVo,
        })
      );
      dispatch(
        setChangeDesksInfo({
          club_num,
          desk_num,
          desk_vo: tempDeskVo,
        })
      );
      // 新桌台
    } else {
      // return
      // const tempDeskNum = desk_num + 1;
      // const tempDeskVo = [...desk_vo, data];
      // let tempClubNum = club_num;

      // const exsitClub = desk_vo.find(
      //   (item) => item.club_name === data.club_name
      // );
      // if (!exsitClub) {
      //   tempClubNum++;
      // }

      // dispatch(
      //   setLobbyDesksInfo({
      //     club_num: tempClubNum,
      //     desk_num: tempDeskNum,
      //     desk_vo: tempDeskVo,
      //   })
      // );
    }
  }

  return (
    <SlideModal
      visible={showTablePick}
      maskBackground={"rgba(255, 255, 255, 0)"}
      onClose={() => dispatch(setShowTablePick(false))}
    >
      <TablePick slide={true}
        showSelectMask={(category: string) =>
          setState((state) => ({ ...state, showMask: category }))
        }
      />
    </SlideModal>
  );
};

export default Index;
