/**
 *
 * @description
 * 顶部header头
 */
import { FC, MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import logoPng from "@/assets/images/common/header_logo.png";
import userInfo from "@/assets/images/common/user_icon.svg";
import bell from "@/assets/images/common/header_bell.svg";
import bell_hover from "@/assets/images/common/header_bell_hover.png";
import list from "@/assets/images/common/header_list.svg";
import list_hover from "@/assets/images/common/header_list_hover.png";
import menu from "@/assets/images/common/header_menu.svg";
import menu_hover from "@/assets/images/common/header_menu_hover.png";
import logout from "@/assets/images/common/header_logout.svg";
import logout_hover from "@/assets/images/common/header_logout_hover.png";
import moneyIcon from "@/assets/images/common/header_money.svg";
import { Row } from "@/components/flex";
import * as Modals from "./Modals";
import HeaderRightMultipleTable from "@/components/Header/HeaderRightMultipleTable";
import HeaderRightGamingMultiple from "@/components/Header/HeaderGamingMultiple";
import HeaderRightGamingSingle from "@/components/Header/HeaderGamingSingle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/store";
import { authSelector, getBalance, setIsOpenedNickName } from "@/store/slices/auth.slice";
import { MenuDataItem } from "@/components/Header/Modals/Menu";
import { betSelector, getGameAnnouncement, setAnnounces } from "@/store/slices/bet.slice";
import { Collapse, ListItemButton, ListItemText } from "@mui/material";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CALC, IStorage } from "@/utils";
import { formatAmount, retain } from "@/utils/tool";
import { getLocaleSelector, setDeskStyle } from "@/store/slices/config.slice";
import useMidiPlay from "@/utils/hooks/useMidiPlay";
import Wifi from "@/components/Header/Wifi";
import { history } from "@/routers";
import Dialog, { dialog } from "@/components/Dialog";
import scss from "./index.module.scss";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames/bind";
import { gameAPI } from "@/services";
import User from "../icons/User";
import theme from "@/config/theme";

const cn = classNames.bind(scss);

// header外框
const HeaderLayout = styled(Row)`
  width: 100%;
  padding-left: 36px;
  align-items: center;
  box-sizing: content-box;
  /* height: 84px; */
  background-color: #202020;
  border-bottom: 1px solid #d3af6e;
  padding: 17px 0;
  position: sticky;
  top: 0;
  z-index: 2;

  .userIcon {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(${userInfo}) no-repeat;
    background-size: cover;
  }

  .userText {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    margin-left: 15px;
    max-width: 100px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

// logo
interface LogoStyledProps {
  logoPng: string;
}

const Logo = styled.div<LogoStyledProps>`
  width: 176px;
  height: 41px;
  flex-shrink: 1;
  background: url(${(props) => props.logoPng}) no-repeat center;
  background-size: contain;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  width: 100%;
  max-width: 562px;
  flex-shrink: 1;
  height: 49px;
  margin-left: 38px;
  border-radius: 40px;
  background: #30333b;
`;

const Box = styled(Row)`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  transform: scale(1.2);

  &.active {
    svg {
      animation-name: rotate-animation;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Refresh = () => {
  const dispatch = useAppDispatch();

  const boxRef: MutableRefObject<any> = useRef(null);

  const refreshBalance = () => {
    (boxRef.current as HTMLElement).classList.add("active");

    dispatch(getBalance())
      .catch(() => {
        console.log("访客没有余额");
      })
      .finally(() => (boxRef.current as HTMLElement).classList.remove("active"));
  };

  return (
    <Box justify="center" iref={boxRef} onClick={refreshBalance}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.51161 1.49068C5.66552 0.969658 6.95389 0.824089 8.19496 1.07451C9.43603 1.32492 10.5672 1.95869 11.4287 2.88639C12.2903 3.81409 12.8389 4.98889 12.9971 6.24506C13.0461 6.63472 12.77 6.99037 12.3804 7.03943C11.9907 7.08849 11.635 6.81238 11.586 6.42272C11.465 5.46209 11.0455 4.56368 10.3866 3.85424C9.72775 3.14479 8.86275 2.66013 7.91366 2.46863C6.96457 2.27713 5.97932 2.38845 5.09689 2.78689C4.35982 3.1197 3.72557 3.63878 3.25458 4.28944H4.99952C5.39226 4.28944 5.71063 4.60782 5.71063 5.00055C5.71063 5.39329 5.39226 5.71167 4.99952 5.71167H1.66619C1.27345 5.71167 0.955078 5.39329 0.955078 5.00055V1.66722C0.955078 1.27448 1.27345 0.95611 1.66619 0.95611C2.05893 0.95611 2.3773 1.27448 2.3773 1.66722V3.10397C2.95644 2.41697 3.68452 1.86413 4.51161 1.49068ZM1.61869 6.96168C2.00835 6.91262 2.364 7.18873 2.41306 7.57839C2.53401 8.53902 2.95352 9.43743 3.61241 10.1469C4.27129 10.8563 5.1363 11.341 6.08538 11.5325C7.03447 11.724 8.01973 11.6127 8.90216 11.2142C9.63923 10.8814 10.2735 10.3623 10.7445 9.71167H8.99952C8.60679 9.71167 8.28841 9.39329 8.28841 9.00056C8.28841 8.60782 8.60679 8.28944 8.99952 8.28944H12.3329C12.7256 8.28944 13.044 8.60782 13.044 9.00056V12.3339C13.044 12.7266 12.7256 13.045 12.3329 13.045C11.9401 13.045 11.6217 12.7266 11.6217 12.3339V10.8971C11.0426 11.5841 10.3145 12.137 9.48744 12.5104C8.33353 13.0315 7.04515 13.177 5.80408 12.9266C4.56301 12.6762 3.43189 12.0424 2.5703 11.1147C1.70871 10.187 1.16014 9.01222 1.00198 7.75605C0.952922 7.36639 1.22903 7.01074 1.61869 6.96168Z"
          fill={theme.theme_color}
        />
      </svg>
    </Box>
  );
};

// 圆形按钮
interface CycleBtnStyledProps {
  icon?: string;
  iconHover?: string;
  red?: boolean;
}

const Money = styled(Row)`
  margin-left: 40px;
  flex-shrink: 0;

  .money_icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(${moneyIcon}) no-repeat center -2px;
    background-size: 30px;
  }

  .text {
    margin-left: 10px;
    font-weight: 400;
    font-size: 20px;
    color: ${theme.theme_color};
  }
`;

const CycleBtn = styled.button<CycleBtnStyledProps>`
  position: relative;
  width: 49px;
  height: 49px;
  background: transparent;
  // background: linear-gradient(to bottom, rgba(23, 23, 23, 0) 0, rgba(23, 23, 23, 0) 49px, rgba(15, 14, 12, 0), rgba(15, 14, 12, 1)) no-repeat;
  // background-size: 49px 98px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  .unread_num {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    background: #cb5460;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(${(props) => props.icon}) no-repeat;
    background-size: contain;
  }

  &:after {
    position: absolute;
    top: 12px;
    right: 12px;
    display: ${(props) => (props.red ? "block" : "none")};
    content: "";
    width: 4px;
    height: 4px;
    background-color: #cb5460;
    border-radius: 2px;
  }

  &:hover {
    background: linear-gradient(180deg, rgba(15, 14, 12, 0) 0%, #0f0e0c 100%);
    //background: linear-gradient(180deg, rgba(15, 14, 12, 0) 0%, #0F0E0C 100%);
    // background-position: 0 100%;

    &:before {
      background: url(${(props) => props.iconHover}) no-repeat;
      background-size: contain;
    }
  }
`;

const RightWrap = styled.div`
  margin-left: 20px;
`;

// 公告
const HeaderMap = {
  "/tablePick": HeaderRightMultipleTable,
  "/auth": HeaderRightGamingMultiple,
};

const ListItem: FC = (props: { title: string; content: string }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemText primary={<span style={{ fontSize: "16px" }}>{props.title ?? t("common:noData")}</span>} />
        {open ? <UpOutlined /> : <DownOutlined />}
      </ListItemButton>
      <Collapse style={{ textAlign: "left", padding: "0 16px", fontSize: "14px" }} in={open}>
        {props.content ?? t("common:noData")}
      </Collapse>
    </>
  );
};

interface Props {
  right?: ReactElement;
}

const Header: FC<Props> = () => {
  const { pathname } = useLocation();
  const RightMenu = useMemo(() => HeaderMap[pathname] || HeaderRightGamingSingle, [pathname]);
  const dispatch = useAppDispatch();
  const state = useAppSelector(authSelector);
  const betState = useAppSelector(betSelector);
  const locale = useAppSelector(getLocaleSelector);
  const routerNav = useNavigate();
  const [open, setOpen] = useState(false);
  const [openItem, setOpenItem] = useState<Record<string, boolean>>({});
  // 是否播放背景音乐
  const { music, deskStyle } = useAppSelector((state) => state.config);
  const { is_set_pwd } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (is_set_pwd === 0) {
      const hide = dialog({
        title: t("common:prompt"),
        message: t("common:updatePwd"),
        buttons: [
          {
            text: t("common:buttonConfirm") as string,
            action: () => {
              hide();
              Modals.showModifyPassword();
            },
          },
        ],
      });
    }
  }, [is_set_pwd]);

  const { t } = useTranslation();

  const balance = useMemo(() => {
    if (locale === "cn") {
      return `${retain(CALC.divide(Number(state.balance), 10000), 4)}万`;
    } else {
      return formatAmount(Number(state.balance));
    }
  }, [locale, state.balance]);

  const confirm = (data) => {
    const betPreference = localStorage.getItem("betPreference");
    let arr = history?.location?.pathname?.match(/\/(\d+)/);
    let deskId = "";
    IStorage.setItem("betPreference", data);
    if (arr === null) {
      return;
    } else {
      deskId = arr[1];
    }
    if (Number(betPreference) === data) {
      return;
    }
    if (pathname === "/multiBet") return;
    if (deskId) {
      if (Number(data)) {
        routerNav(`/luxuryBet/${deskId}`);
      } else {
        routerNav(`/classic/${deskId}`);
      }
    }
  };
  const menuData = useMemo<MenuDataItem[]>(() => {
    const isPogo = !!process.env.REACT_APP_POGO;
    const list = [
      {
        onlyCustomer: true,
        icon: "icon-record-circle-line",
        name: t("modalMenu:gameRecord"),
        onChose() {
          Modals.showRecord();
        },
        hide: isPogo,
      },
      // {
      //   onlyCustomer: true,
      //   icon: "icon-poker_style",
      //   name: t("modalMenu:chipStyle"),
      //   onChose() {
      //     Modals.showChipChose();
      //   },
      //   hide: isPogo
      // },
      {
        onlyCustomer: true,
        icon: "icon-tips",
        name: t("modalMenu:goodRoadTips"),
        onChose() {
          Modals.showGoodRoad();
        },
        hide: isPogo,
      },
      {
        icon: "icon-sound",
        name: t("modalMenu:setSound"),
        onChose() {
          Modals.showVoice();
        },
      },
      {
        icon: "icon-language",
        name: t("modalMenu:chooseLanguage"),
        onChose() {
          Modals.showLocales();
        },
      },
      {
        onlyCustomer: true,
        icon: "icon-key",
        name: t("modalMenu:changePassword"),
        onChose() {
          Modals.showModifyPassword();
        },
      },
      {
        onlyCustomer: true,
        icon: "icon-edit-name",
        name: t("modalMenu:changeNickname"),
        onChose() {
          Modals.showModifyNick();
        },
      },
      {
        icon: "icon-support-agent",
        name: t("modalMenu:contactCustomerService"),
        onChose() {
          Modals.showSupport();
        },
      },
      {
        icon: "icon-logout",
        name: t("modalMenu:exitGame"),
        onChose() {
          Modals.showQuit();
        },
      },
      // {
      //   icon: "icon-logout",
      //   name: t("modalMenu:tableStyle"),
      //   onChose() {
      //     Modals.showSelectBetting({
      //       confirm: confirm,
      //     });
      //   },
      // },
      /* {
       icon: 'icon-logout',
       name: '申请专属服务',
       onChose() {
       // todo
       Modals.showExService()
       }
       },
       {
       icon: 'icon-logout',
       name: '包台密码',
       onChose() {
       // todo
       Modals.showBaotai()
       }
       },
       {
       icon: 'icon-logout',
       name: '选择您喜好的投注界面',
       onChose() {
       Modals.showSelectBetting({
       confirm: data => IStorage.setItem('betPreference', data)
       })
       }
       }, */
    ];
    // 增加判断，当是游客是去掉一些菜单选项
    return list
      ?.filter((item) => !item.onlyCustomer || (item.onlyCustomer && !state.isVisitor))
      .filter((item) => !item.hide);
  }, [locale]);

  useMidiPlay({
    url: "/bg.mp3.mid",
    loop: true,
    auto: music,
  });

  useEffect(() => {
    dispatch(getBalance()).catch(() => {
      console.log("访客没有余额");
    });
    // dispatch(getGameAnnouncement({ type: 2 }));
    if (!state.nickname && !state.isVisitor && !state.isOpenedNickName && !isEmpty(state.access_token)) {
      Modals.showModifyNick();
      dispatch(setIsOpenedNickName(true));
    }
  }, []);

  const onQuit = () => {
    Modals.showQuit();
  };

  const announces = useMemo(() => {
    const _locale = locale === "ko" ? "kr" : locale;
    console.log("locale:", _locale);
    return betState.announce
      ?.filter((item) => {
        return !!item.title[_locale];
      })
      .map((item) => {
        return {
          title: item.title[_locale],
          content: item.content[_locale],
          id: item.id,
          isRead: item.is_red,
        };
      });
  }, [betState.announce, locale]);

  const notReadNum = useMemo(() => {
    return betState.announce.filter((item) => !item.is_red).length;
  }, [betState.announce]);

  // 打开公告
  const openAnnounce = () => {
    setOpen(true);

    // Modals.showConfirm({
    //   title: t('modalAnnouncement:title'),
    //   content:
    //     <div style={{ width: 510 }}>
    //       {
    //         announces?.map((item, index) => {
    //           return (
    //             <ListItem {...item} key={index}></ListItem>
    //           )
    //         })
    //       }
    //     </div>
    // })
  };

  const nickname = useMemo(() => {
    const account = `***${state.account?.substring(state.account.length - 4) ?? ""}`;
    return state.nickname ? state.nickname : account;
  }, [state.nickname, state.account]);

  const changeDeskStyle = () => {
    dispatch(setDeskStyle(deskStyle === "dark" ? "bright" : "dark"));
  };

  function handleClickNotice(index: number) {
    setOpenItem({ [index]: !openItem[index] });
    if (!announces[index].isRead) {
      gameAPI.readAnnouncement({ id: announces[index].id });
      const tempArr = [...betState.announce];
      tempArr[index] = {
        ...tempArr[index],
        is_red: 1,
      };
      dispatch(setAnnounces(tempArr));
    }
  }

  return (
    <HeaderLayout justify="space-between">
      <Row style={{ width: "924px" }} justify="flex-start" ailgn="center">
        <Logo logoPng={logoPng} />
        <UserInfo>
          <Row ailgn="center">
            <User />
            <span className="userText">{state.isVisitor ? t("visitor") : nickname}</span>
          </Row>
          <div>
            <Wifi />
            <CycleBtn icon={bell} iconHover={bell_hover} onClick={() => openAnnounce()}>
              {!!notReadNum && <span className="unread_num">{notReadNum > 99 ? "99+" : notReadNum}</span>}
            </CycleBtn>
            {!state.isVisitor && !process.env.REACT_APP_POGO && (
              <CycleBtn icon={list} iconHover={list_hover} onClick={() => Modals.showRecord()}></CycleBtn>
            )}
            <CycleBtn icon={menu} iconHover={menu_hover} onClick={() => Modals.showMenu(menuData)}></CycleBtn>
            <CycleBtn icon={logout} iconHover={logout_hover} onClick={onQuit}></CycleBtn>
          </div>
        </UserInfo>
        <Money ailgn="center">
          <i className="money_icon" />
          <span className="text">{balance}</span>
          {!state.isVisitor && <Refresh />}
        </Money>
      </Row>
      <RightWrap>
        <RightMenu />
      </RightWrap>
      {/* 公告 */}
      <Dialog
        title={t("modalAnnouncement:title")}
        visible={open}
        buttons={[
          {
            type: "primary",
            text: t("buttonConfirm"),
            action: () => setOpen(false),
          },
        ]}
        onClose={() => setOpen(false)}
        onAnimationend={close}
      >
        <ul className={scss.notice}>
          {announces?.map((item, index) => (
            <li
              key={index}
              className={cn(scss.notice__item, { is_read: !!item.isRead }) + ` ${openItem[index] ? scss.open : ""}`}
            >
              <p onClick={() => handleClickNotice(index)} className={scss.notice__title}>
                <span>{item.title || t("common:noData")}</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 17L14 12L9 7" stroke="#E5C180" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </p>
              <p className={scss.notice__content}>{item.content || t("common:noData")}</p>
            </li>
          ))}
        </ul>
      </Dialog>
    </HeaderLayout>
  );
};

export default Header;
