import { drawBigEyeWay, drawBigWay, drawCockroachWay, drawDishWay, drawSmallWay } from "@/utils/drawRoad";
import RoadMaps from "@/utils/roadmaps";
import { initCanvas } from "@/utils/tool";
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef } from "react";
// import { useTranslation } from "react-i18next";
import { Theme } from "@/store/slices/config.slice";
import { t } from "i18next";
import theme from "@/config/theme";

type Axis = {
  cols: number;
  rows: number;
};

interface Config {
  breadplate: Axis;
  bigroad: Axis;
  bigeyeboy: Axis;
  smallroad: Axis;
  cockroachPig: Axis;
  cellWidth: number;
  //   cellHeight: number;
  gridLineWidth: number;
  theme?: Theme;
}

interface Props {
  results: string[];
  config: Config;
}

// const textMap = {
//   banker: "庄",
//   player: "闲",
//   tie: "和",
// };

const colorMap = {
  banker: theme.bank_color,
  player: theme.player_color,
  tie: theme.tie_color,
};

function initData(results: string[], config: Config, canvas: HTMLCanvasElement) {
  const {
    cellWidth,
    gridLineWidth,
    breadplate,
    bigeyeboy,
    bigroad,
    smallroad,
    cockroachPig,
    theme = "bright",
  } = config;
  const canvasWidth = cellWidth * breadplate.cols + (cellWidth / 2) * bigroad.cols + gridLineWidth;
  const canvasHeight = cellWidth * breadplate.rows + gridLineWidth;
  const textMap = {
    banker: t("game:lotteryTypes.banker"),
    player: t("game:lotteryTypes.player"),
    tie: t("game:lotteryTypes.tie"),
  };
  const bgColor = theme === "bright" ? "#FAF8F4" : "#1D1D1D";
  const ctx = initCanvas(canvas, false, canvasWidth, canvasHeight, bgColor);

  const dishWayWidth = cellWidth * breadplate.cols;
  const smallWayWidth = (cellWidth / 4) * smallroad.cols;
  const bigWayHeight = (cellWidth / 2) * bigroad.rows;
  const bigEyeHeight = (cellWidth / 4) * bigeyeboy.rows;

  const roadMap: any = new RoadMaps({
    results,
    config: {
      breadplate,
      bigroad,
      bigeyeboy,
      smallroad,
      cockroachPig,
    },
  });

  const common = {
    textMap,
    colorMap,
    cellWidth: cellWidth,
    cellHeight: cellWidth,
    lineColor: theme === "bright" ? "rgba(211, 175, 110, 0.3)" : "rgba(217, 217, 217, 0.25)",
    lineWidth: 1,
  };

  drawDishWay(ctx, roadMap.breadplate.matrix, { ...common, rows: breadplate.rows, columns: breadplate.cols }, [0, 0]);

  drawBigWay(
    ctx,
    roadMap.bigroad.matrix,
    {
      ...common,
      rows: bigroad.rows,
      columns: bigroad.cols,
      cellWidth: cellWidth / 2,
      cellHeight: cellWidth / 2,
    },
    [dishWayWidth, 0]
  );
  drawBigEyeWay(
    ctx,
    roadMap.bigeyeboy.matrix,
    {
      ...common,
      rows: bigeyeboy.rows,
      columns: bigeyeboy.cols,
      cellWidth: cellWidth / 4,
      cellHeight: cellWidth / 4,
    },
    [dishWayWidth, bigWayHeight]
  );

  drawSmallWay(
    ctx,
    roadMap.smallroad.matrix,
    {
      ...common,
      rows: smallroad.rows,
      columns: smallroad.cols,
      cellWidth: cellWidth / 4,
      cellHeight: cellWidth / 4,
    },
    [dishWayWidth, bigWayHeight + bigEyeHeight]
  );

  drawCockroachWay(
    ctx,
    roadMap.cockroachPig.matrix,
    {
      ...common,
      rows: cockroachPig.rows,
      columns: cockroachPig.cols,
      cellWidth: cellWidth / 4,
      cellHeight: cellWidth / 4,
    },
    [dishWayWidth + smallWayWidth, bigWayHeight + bigEyeHeight]
  );

  return ctx;
}

let timer;

const Index = forwardRef(({ results, config }: Props, ref: Ref<any>) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useImperativeHandle(ref, () => ({
    inquiry,
  }));

  function inquiry(winner: "b" | "p", callback?) {
    let i = 0;
    const list: string[] = [...results, winner];
    clearInterval(timer);
    initData(list, config, canvasRef.current);

    timer = setInterval(() => {
      if (i % 2) {
        initData(list, config, canvasRef.current);
      } else {
        initData(results, config, canvasRef.current);
      }
      if (i === 4) {
        clearInterval(timer);
        callback && callback();
      }
      i++;
    }, 500);
  }

  useEffect(() => {
    initData(results, config, canvasRef.current);
  }, [results, config]);

  return <canvas ref={canvasRef}></canvas>;
});

export default Index;
