export const config = {
    breadplate: {
      rows: 6,
      cols: 8,
    },
    bigroad: {
      rows: 6,
      cols: 20,
    },
    bigeyeboy: {
      rows: 6,
      cols: 40,
    },
    smallroad: {
      rows: 6,
      cols: 20,
    },
    cockroachPig: {
      rows: 6,
      cols: 20,
    },
    cellWidth: 524 / 18,
    gridLineWidth: 1,
  };
  
  export const smallConfig = {
    breadplate: {
      rows: 6,
      cols: 8,
    },
    bigroad: {
      rows: 6,
      cols: 20,
    },
    bigeyeboy: {
      rows: 6,
      cols: 40,
    },
    smallroad: {
      rows: 6,
      cols: 20,
    },
    cockroachPig: {
      rows: 6,
      cols: 20,
    },
    cellWidth: 382 / 18,
    gridLineWidth: 1,
  };