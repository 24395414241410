import http from "@/api/http";
import { ApiFunctionReturnResponseData } from "@/api/types";

// 获取所有赌桌信息
export const getAlldesks: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/alldesks";
  return http.post(url, params, config).then((r) => r.data.data);
};

// 获取所有赌桌信息
export const getGameDeskById: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/entry-desk";
  return http.get(url, params, config).then((r) => r.data);
};

// 获取桌台投注统计
export const getGameBetInfo: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/bet-info";
  return http.get(url, params, config).then((r) => r.data);
};

// 获取桌台倒计时
export const getGameBetTime: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/bet-time";
  return http.get(url, params, config).then((r) => r.data);
};

// 获取好路列表
export const getHotRoadList: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/game-hot-load";
  return http.get(url, params, config).then((r) => r.data);
};

// 获取游戏公告
export const getGameAnnouncement: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/announcement";
  return http.get(url, params, config).then((r) => r.data);
};

// 读取公告
export const readAnnouncement: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = '/v1/announcement-read';
  return http.get(url, params, config).then((r) => r.data);
};

// 获取牌靴结果
export const getShoesResult: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/shoe-results";
  return http.get(url, params, config).then((r) => r.data);
};

// 玩家发起换桌
export const changeDesk: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/change-desk";
  return http.post(url, params, config).then((r) => r.data);
};

// 玩家发起换桌的时候进桌
export const changeEnterDesk: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/change-enter-desk";
  return http.get(url, params, config).then((r) => r.data);
};

// 处理枪手发起的换桌申请
export const dealChangeDesk: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/deal-change-desk";
  return http.post(url, params, config).then((r) => r.data);
};

// 提交注单
export const submitBet: ApiFunctionReturnResponseData<
  Array<{
    bet_amount: number;
    desk_no: string;
    game_no: string;
    game_type: string;
  }>
> = (params, config) => {
  const url = "/v1/game/bet";
  return http.post(url, params, config).then((r) => r.data);
};

// 提交小额注单
export const smallSubmitBet: ApiFunctionReturnResponseData<
  Array<{
    bet_amount: number;
    desk_no: string;
    game_no: string;
    game_type: string;
  }>
> = (params, config) => {
  const url = "/v1/game/small-bet";
  return http.post(url, params, config).then((r) => r.data);
};

// 获取玩家上次投注
export const getLastBet: ApiFunctionReturnResponseData<any> = (params, config) => {
  const url = "/v1/game/rebet-lastbet";
  return http.post(url, params, config).then((r) => r.data);
};

// 飞牌
export const freeGame: ApiFunctionReturnResponseData<{
  desk_id: number;
  desk_no: string;
  game_no: string;
}> = (params, config) => {
  const url = "/v1/game/free-game";
  return http.post(url, params, config).then((r) => r.data);
};

// 玩家离开桌台
export const leaveDesk: ApiFunctionReturnResponseData<{
  desk_id: number;
  desk_no: string;
}> = (params, config) => {
  const url = "/v1/game/leave-desk";
  return http.post(url, params, config).then((r) => r.data);
};

// 玩家进入包台房间
export const applyVipDesk: ApiFunctionReturnResponseData<{
  desk_id: number;
  desk_no: string;
}> = (params, config) => {
  const url = "/v1/game/into-vipdesk";
  return http.post(url, params, config).then((r) => r.data);
};

// 玩家申请专属服务
export const applySpecialService: ApiFunctionReturnResponseData<{
  area_code: string;
  desk_no: string;
  desk_id: string;
  phone: string;
  chips_amount: number;
}> = (params, config) => {
  const url = "/v1/game/basicdesk-apply";
  return http.post(url, params, config).then((r) => r.data);
};

// 玩家取消申请专属服务
export const cancelApplySpecialService: ApiFunctionReturnResponseData<{
  desk_id: string;
}> = (params, config) => {
  const url = "/v1/game/cancel-apply-basic";
  return http.post(url, params, config).then((r) => r.data);
};

// 玩家退出传统桌台
export const quitTraditionMod: ApiFunctionReturnResponseData<{
  desk_id: string;
}> = (params, config) => {
  const url = "/v1/game/logout-tradition-mod";
  return http.post(url, params, config).then((r) => r.data);
};

// 玩家撤注
export const revokeBet: ApiFunctionReturnResponseData<{
  desk_id: string;
}> = (params, config) => {
  const url = "/v1/game/revoke-bettings";
  return http.post(url, params, config).then((r) => r.data);
};
