import React, {useRef, useState, useEffect, FC} from 'react'
import {createRoot, Root} from 'react-dom/client'
import classNames from 'classnames/bind'
import scss from './index.module.scss'
import Icon from "@/components/Icon"
import {motion} from 'framer-motion'
import loadingGif from '@/assets/images/common/loading.gif'
const cn = classNames.bind(scss)

interface Props {
  visible: boolean,
  onAnimationend?: () => void
}

// loading效果
const motionlist = [0,1,2,3,4]
const LoadingEffect2: FC = () => {
  return (
    <div className={scss.effectWrap}>
      {
        motionlist?.map((_, index) => {
          return <motion.div key={index}
            className={scss.loadIcon}
            animate={{
              opacity: [0, 1, 1, 0],
              scale: [0.3, 1, 1, 0.3],
              rotate: [-30, 0, 0, 30]
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.1, 0.9, 1],
              repeat: Infinity,
              delay: index * 1.8,
              repeatDelay: 1.8 * (motionlist.length - 1)
            }}
          >
            <Icon type={`icon-loading_${index}`} style={{fontSize: '100px'}}></Icon>
          </motion.div>
        })
      }
    </div>
  )
}

const LoadingEffect = () => {
  return (
    <img src={loadingGif} alt=""/>
  )
}

function Loading(props: Props) {
  const {visible: visibleProps, onAnimationend} = props
  const wrapperEl = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState<boolean>(visibleProps)

  useEffect(toggleVisible, [visibleProps])

  function toggleVisible() {
    const wrapper = wrapperEl.current
    if (visibleProps) {
      setVisible(visibleProps)
    } else {
      wrapper && (wrapper.onanimationend = animationend)
    }
  }

  function animationend() {
    setVisible(false)
    onAnimationend && onAnimationend()
  }

  return (
    visible
      ? <div ref={wrapperEl}
             className={cn(scss.mask, 'animate__animated', visibleProps ? 'animate__fadeIn' : 'animate__fadeOut')}>
        {/* <div className={scss.loading}></div> */}
        <LoadingEffect/>
      </div>
      : null
  )
}

class LoadingClass {
  private static getRoot() {
    const container = document.createElement('div')
    document.body.appendChild(container)
    return createRoot(container)
  }

  private readonly root: Root

  constructor() {
    this.root = LoadingClass.getRoot()
  }

  show() {
    const params = {visible: true}
    this.root.render(<Loading {...params}/>)
  }

  hide(onAnimationend?: () => void) {
    const params = {
      visible: false,
      onAnimationend
    }
    this.root.render(<Loading {...params}/>)
  }
}

export {LoadingClass}
export default Loading
