import React, { FC } from "react"
import classNames from 'classnames/bind'
import scss from './index.module.scss'

const cn = classNames.bind(scss)

interface Props {
  list: { label: string; value: number | string }[]
  active?: number
  onChoose: (params: { label: string; value: number }) => void
}

const Index: FC<Props> = (props) => {
  const { list, onChoose, active } = props
  return <ul className={scss.list}>
    {
      list.map((item, index) => (
        <li
          key={index}
          className={cn(scss.list__item, { active: active === index })}
          onClick={() => onChoose(item as any)}>
          <span className={scss.list__text}>{item.label}</span>
          {
            active === index &&
            <img className={scss.list__icon} src={require('./icon_checked.png')} alt=""/>
          }
        </li>
      ))
    }
  </ul>
}

export default Index
