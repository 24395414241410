import { Column, Row } from "@/components/flex";
import { useAppSelector } from "@/store";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrap = styled(Column)<any>`
  /* position: absolute;
  bottom: 0;
  left: 0; */
  width: 100%;
  /* height: 174px; */
  height: ${({ isSmall }: any) => `${isSmall ? "128px" : "172px"}`};

  background: rgb(18, 19, 13);
  color: #fff;
  z-index: 500;
`;

const LineRow = styled(Row)<any>`
  width: 100%;
  height: ${({ isSmall }: any) => `${isSmall ? "32px" : "43px"}`};
  /* font-size: ${({ isSmall }: any) => `${isSmall ? "12px" : "15px"}`}; */

  &.header {
    span {
      color: #d3af6e;
    }
  }

  span {
    flex: 1;
    text-align: center;
  }
`;

const SelectPicker: FC<any> = ({
  onItemPick,
  isSmall,
  deskLimits,
  style
}) => {
  const {t} = useTranslation()

  return (
    <Wrap isSmall={isSmall} style={style}>
      <LineRow className="header">
        <span>{t('betLimitTable:column1')}</span>
        <span>{t('betLimitTable:column2')}</span>
        <span>{t('betLimitTable:column3')}</span>
        <span>{t('betLimitTable:column4')}</span>
      </LineRow>
      {deskLimits?.map((item, index) => (
        <LineRow
          key={item.area}
          isSmall={isSmall}
        >
          <span>{item.area}</span>
          <span>{item.limitRedFmt}</span>
          <span>{item.tableRedFmt}</span>
          <span>{item.intervalFmt}</span>
        </LineRow>
      ))}
    </Wrap>
  );
};

export default SelectPicker;
