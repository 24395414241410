import request from './http';
import {ApiFunctionReturnResponseData, RequestParams} from "@/api/types";
import store from "@/store";
import http from "./http";
const prefix = '/game'
const ver = '/v1'
export const testRequest = (params = {}) => {
  return request.get('/posts', params);
};

// 登录
export interface LoginParams extends RequestParams{
  account: string,
  password: string
}
export const reqLogin = (params: LoginParams) => {
  const url = `/auth/login`
  return request.post(url, params)
}

// refresh token
interface RefreshParams {
  access_token: string,
  refresh_token: string
}
export const reqRefresh = (params: RefreshParams) => {
  const url = `/auth/refresh-token`
  return request.post(url, params, {
    headers: {
      lToken: undefined
    }
  })
}

// 验证码
export const reqVerificationCode = () => {
  const url = `/auth/verification-code-id`
  return request.get(url)
}

// 访客登录
export interface VisitLoginParams extends RequestParams{
  verification_code: string,
  visitor_id: string
}
export const reqVisitLogin = (params: VisitLoginParams) => {
  const url = `/auth/visit/login`
  return request.post(url, params)
}

// logout
export const reqLogout = (params?: RequestParams) => {
  const url = `${ver}/player/logout`
  return request.post(url, params)
}

// pay tips
interface SendTipsParams extends RequestParams{
  desk_id: string | number,
  desk_no?: string ,
  game_no?: string,
  game_mod?: string | number,
  tips_amount: string
}
export const reqSendTips = (params: SendTipsParams) => {
  const url = `${ver}/game/send-tips`
  return request.post(url, params)
}

// get Balance
interface BalanceParams {
  currency_code: string
  player_id: string
}
export const reqBalance = (params: BalanceParams) => {
  const url = `${ver}/player/balance`
  return request.post(url, params)
}

// 获取客服电话
export const reqCustomerService = () => {
  const url = `${ver}/player/customer-service`
  return request.post(url)
}
// 获取投注记录
export interface PlayerRecordParams extends RequestParams{
  page_size: number,
  page_num: number,
}
export const reqPlayerRecord = (params: PlayerRecordParams) => {
  const url = `${ver}/player/records`
  return request.get(url, params)
}

// 获取投注统计
export const reqPlayerStatistics = () => {
  const url = `${ver}/player/statistics`
  return request.get(url)
}

// 修改密码
export interface UppasswordParams extends RequestParams{
  confirm_password: string,
  new_password: string,
  old_password: string
}
export const reqUppassword = (params: UppasswordParams) => {
  const url = `${ver}/player/upassword`
  return request.post(url, params)
}

// 修改昵称
interface UnicknameParams {
  new_nickname: string
}
export const reqUnickname = (params: UnicknameParams) => {
  const url = `${ver}/player/unickname`
  return request.post(url, params)
}

// 获取玩家限红
export const reqPlayerLimit: ApiFunctionReturnResponseData<any> = (params: any, config) => {
  const url = `${ver}/player/player-limit`
  return http.get(url, params, config).then((r) => r.data)
}

// 接口








