import { reqSendTips } from "@/api";
import Dialog from "@/components/Dialog";
import { Row } from "@/components/flex";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Icon from "@/components/Icon";
import { message } from "@/components/Message";
import store, { useAppDispatch, useAppSelector } from "@/store";
import { setBalance } from "@/store/slices/auth.slice";
import { CALC } from "@/utils";
import useTradeType from "@/utils/hooks/useTradeType";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SelectTips = styled(Row)`
  padding: 15px 15px 0;
  width: 365px;
`;

interface TipsStyledProps {
  icon: string;
}

const Tip = styled.button<TipsStyledProps>`
  width: 75px;
  height: 75px;
  margin: 0 6px 15px 0;
  background: url(${(props) => props.icon}) no-repeat;
  background-size: contain;
  border: 0 none;
  cursor: pointer;
`;

interface TipsItem {
  value: string | number;
  icon: string;
}

interface Props {
  visible?: boolean;
  onClose?: () => void;
}

const Reg = /^\+?[1-9][0-9]*$/;

const TipBox = styled(Row)`
  position: relative;
  width: 100%;
  height: 64px;
  padding: 20px 16px;
  z-index: 10;
  border-radius: 8px;
  transition: border-color 0.2s;
  background: rgba(10, 8, 8, 0.5);
  overflow: hidden;
`;

const ResetIcon = styled(Icon)`
  color: #717171;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

const Tips: FC<Props> = (props) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible: props.visible, onClose: props.onClose });
  const [tipAmount, setTipAmount] = useState("");
  const dispatch = useAppDispatch();
  const { deskInfo } = useAppSelector((state) => state.bet);

  const isKRW = useTradeType();

  const tipsRender = useMemo(() => {
    const tips: TipsItem[] = [
      {
        value: 25,
        icon: isKRW ? require("@/assets/images/tips/tips_25_krw.png") : require("@/assets/images/tips/tips_25.png"),
      },
      {
        value: 50,
        icon: isKRW ? require("@/assets/images/tips/tips_50_krw.png") : require("@/assets/images/tips/tips_50.png"),
      },
      {
        value: 100,
        icon: isKRW ? require("@/assets/images/tips/tips_100_krw.png") : require("@/assets/images/tips/tips_100.png"),
      },
      {
        value: 500,
        icon: isKRW ? require("@/assets/images/tips/tips_500_krw.png") : require("@/assets/images/tips/tips_500.png"),
      },
      {
        value: 1000,
        icon: isKRW ? require("@/assets/images/tips/tips_1000_krw.png") : require("@/assets/images/tips/tips_1000.png"),
      },
      {
        value: 5000,
        icon: isKRW ? require("@/assets/images/tips/tips_5000_krw.png") : require("@/assets/images/tips/tips_5000.png"),
      },
      {
        value: 10000,
        icon: isKRW
          ? require("@/assets/images/tips/tips_10000_krw.png")
          : require("@/assets/images/tips/tips_10000.png"),
      },
      {
        value: 50000,
        icon: isKRW
          ? require("@/assets/images/tips/tips_50000_krw.png")
          : require("@/assets/images/tips/tips_50000.png"),
      },
      {
        value: 100000,
        icon: isKRW
          ? require("@/assets/images/tips/tips_100000_krw.png")
          : require("@/assets/images/tips/tips_100000.png"),
      },
      {
        value: 500000,
        icon: isKRW
          ? require("@/assets/images/tips/tips_500000_krw.png")
          : require("@/assets/images/tips/tips_500000.png"),
      },
      {
        value: 1000000,
        icon: isKRW
          ? require("@/assets/images/tips/tips_1000000_krw.png")
          : require("@/assets/images/tips/tips_1000000.png"),
      },
    ];

    return isKRW
      ? tips.map((tip) => ({
          ...tip,
          value: Number(tip.value) * 200,
        }))
      : tips;
  }, [isKRW]);

  useEffect(() => {
    if (!Reg.test(tipAmount)) {
      setTipAmount("");
    }
  }, [tipAmount]);

  const { t } = useTranslation();
  const [validate, setValidate] = useState(false);
  const choseTip = (amount: string | number) => {
    const _tipAmount = tipAmount ? parseInt(tipAmount) : 0;
    const _amount = parseInt(`${amount}`);
    setTipAmount(`${_tipAmount + _amount}`);
    setValidate(true);
  };

  const rules = [
    {
      required: true,
      message: t("tip:tipValidationMessage"),
      trigger: "blur",
    },
  ];
  //
  const onFinish = () => {
    if (validate) {
      const { balance } = store.getState().auth;
      reqSendTips({
        _isNeedLoading: true,
        desk_id: deskInfo.desk_id,
        desk_no: deskInfo.desk_no,
        game_mod: deskInfo.game_mod,
        game_no: deskInfo.game_no,
        tips_amount: tipAmount,
      })
        .then((r) => {
          message.success(`${t("tip:tipPaidMessage")} ${tipAmount}`);
          dispatch(setBalance(CALC.subtract(Number(balance), Number(tipAmount))));
        })
        .finally(() => {
          setIsShow(false);
        });
    }
  };

  return (
    <Dialog
      title={t("tip:title")}
      visible={isShow}
      buttons={[
        {
          text: t("tip:buttonCancel"),
          action: () => setIsShow(false),
        },
        {
          disabled: !tipAmount,
          text: t("tip:buttonConfirm"),
          action: onFinish,
        },
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <SelectTips justify="center" wrap="wrap">
        {tipsRender?.map((item, index) => {
          return <Tip icon={item.icon} key={index} onClick={() => choseTip(item.value)}></Tip>;
        })}
      </SelectTips>
      <Row style={{ padding: "0 24px" }}>
        {/* <OInput
          label={t("tip:tipLabel")}
          reset
          value={tipAmount}
          onValidate={setValidate}
          onChange={setTipAmount}
          rules={rules}
          inputChangeFlag={false}
        ></OInput> */}
        <TipBox>
          <Row style={{ width: "90%", color: "#fff", fontSize: 16, fontWeight: 400 }}>{tipAmount}</Row>
          <ResetIcon type="icon-input_reset" onClick={() => setTipAmount("")} />
        </TipBox>
      </Row>
      {/* <Row style={{width: '325px', margin: '10px auto 18px', padding: '15px 10px'}} justify="center" ailgn="center"> */}
      {/*   <MyButton cancel name={t('tip:buttonCancel')} width={140} onClick={() => setIsShow(false)}/> */}
      {/*   <MyButton styles={{marginLeft: '25px'}} width={140} name={t('tip:buttonConfirm')} onClick={onFinish}/> */}
      {/* </Row> */}
    </Dialog>
  );
};

export default Tips;
