import {
  LoginParams,
  PlayerRecordParams,
  reqBalance,
  reqCustomerService,
  reqLogin,
  reqLogout,
  reqPlayerRecord,
  reqPlayerStatistics,
  reqRefresh,
  reqUnickname,
  reqUppassword,
  reqVerificationCode,
  reqVisitLogin,
  UppasswordParams,
  VisitLoginParams,
} from "@/api";
import { playerAPI, userAPI } from "@/services";
import { RootState } from "@/store";
import { AnyAction, createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import { AUTH } from "../actionType";

interface TradeType {
  // 当前选择的交收方案
  id: number;
  trade_type_id: string;
  currency_type: number;
  currency_code: string;
  [key: string]: any;
}
interface State {
  account?: string; // 账户名
  isVisitor: boolean; // 是否是访客
  access_token?: string;
  refresh_token?: string;
  player_id?: string;
  balance?: string | number;
  currency_code?: string;
  nickname?: string;
  position_level?: string;
  record?: any[];
  statistics?: {
    total_bet_amount: string;
    total_prize: string;
  };
  customerService?: {
    phone: string;
  }[];
  limit_array: any[]; // 我的限红
  currentTradeType?: TradeType;
  trade_type: TradeType[];
  isOpenedNickName: boolean; // 昵称弹窗是否已打开过
  is_set_pwd?: number; // 新用户修改密码 0 需要修改 1 不需要
}

function isRejectedAction(action: AnyAction) {
  return action.type.endsWith("rejected");
}

// 登录
export const login = createAsyncThunk(`${AUTH}/login`, (params: LoginParams) => {
  return reqLogin(params).then((res) => res.data);
});

// 访客登录
export const visitorLogin = createAsyncThunk(`${AUTH}/visitor`, (params: VisitLoginParams) => {
  return reqVisitLogin(params).then((res) => res.data);
});

// 获取玩家限红
export const getPlayerLimit = createAsyncThunk(`${AUTH}/getPlayerLimit`, (params) => {
  return playerAPI
    .getPlayerLimit({})
    .then((res) => res.data)
    .catch((err) => err);
});

// 发送验证码
export const verificationCode = createAsyncThunk(`${AUTH}/code`, () => {
  return reqVerificationCode().then((res) => res.data);
});

// 修改密码
export const updatePassword = createAsyncThunk(`${AUTH}/updatePassword`, (params: UppasswordParams) => {
  return reqUppassword(params);
});

// 获取游戏记录
export const getPlayerRecord = createAsyncThunk(`${AUTH}/record`, (params: PlayerRecordParams) => {
  return Promise.all([reqPlayerRecord(params), reqPlayerStatistics()]).then(([record, statistics]) => {
    return {
      record: record.data,
      statistics: statistics.data,
    };
  });
});

// token刷新-当用户一进来时刷新token
export const refreshToken = createAsyncThunk(`${AUTH}/refresh`, (arg, thunkAPI) => {
  const state = thunkAPI.getState()[AUTH] as State;
  if (!state.isVisitor) {
    const params = {
      position_level: state.position_level,
      access_token: state.access_token,
      refresh_token: state.refresh_token,
    };
    return reqRefresh(params).then((res) => res.data);
  }
});

// logout
export const logout = createAsyncThunk(`${AUTH}/logout`, async () => {
  const res = await reqLogout({
    _isNeedLoading: true,
  });
  return res.data;
});

// 获得余额
export const getBalance = createAsyncThunk(`${AUTH}/balance`, (arg, thunkAPI) => {
  const state = thunkAPI.getState()[AUTH] as State;
  const currency_code = state.currentTradeType.currency_code;
  const player_id = state.player_id;

  if (!state.isVisitor && currency_code) {
    return reqBalance({
      currency_code,
      player_id,
    }).then((res) => res.data);
  } else {
    return Promise.reject();
  }
});

// 获取客服
export const getCSPhone = createAsyncThunk(`${AUTH}/support`, () => {
  return reqCustomerService().then((res) => res.data);
});

// 修改昵称
export const setNick = createAsyncThunk(`${AUTH}/nick`, (params: { new_nickname: string }, thunkAPI) => {
  return reqUnickname({
    new_nickname: params.new_nickname,
  }).then((res) => {
    return res.data;
  });
});
// 一级代理选择交收方案
export const chooseTradeType = createAsyncThunk(
  `${AUTH}/tradeType`,
  (params: { trade_type_id: string; currency_code: string; wallet_id: string }, thunkAPI) => {
    return userAPI
      .chooseTradeType({
        trade_type_id: params.trade_type_id,
        currency_code: params.currency_code,
        wallet_id: params.wallet_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => err);
  }
);
const initialState: State = {
  isVisitor: false,
  access_token: "",
  player_id: "",
  account: "",
  limit_array: [
    {
      currency: 0,
      limit_array: [
        {
          bet_type: 1,
          max_amount: 500,
          min_amount: 30,
        },
        {
          bet_type: 2,
          max_amount: 500,
          min_amount: 30,
        },
        {
          bet_type: 3,
          max_amount: 500,
          min_amount: 30,
        },
      ]
    }
  ],
  trade_type: [],
  isOpenedNickName: false, // 昵称弹窗是否已打开过
  is_set_pwd: 1
};
const slice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    logout(state) {
      state.access_token = undefined;
      state.refresh_token = undefined;
      state.currentTradeType = undefined;
      state.isOpenedNickName = false;
    },
    setBalance(state, action: PayloadAction<number>) {
      state.balance = String(action.payload);
    },
    setAccount(state, action: PayloadAction<string>) {
      state.account = action.payload;
    },
    setPayType(state, action: PayloadAction<string>) {
      state.currentTradeType = state.trade_type.find((item) => item.trade_type_id === action.payload);
    },
    setIsOpenedNickName(state, { payload }) {
      state.isOpenedNickName = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        const data = payload.data;
        /*  state.isVisitor = false
        state.account = data.account
        state.access_token = data.access_token || "token";
        state.refresh_token = data.refresh_token || "token";
        state.balance = data.balance
        state.position_level = data.position_level */
        return {
          ...state,
          ...data,
          isVisitor: false,
        };
      })
      .addCase(logout.fulfilled, (state, action) => {
        console.log("触发action退出");
        state.access_token = undefined;
        state.refresh_token = undefined;
        state.currentTradeType = undefined;
        state.isOpenedNickName = false;
        state.is_set_pwd = 1;
      })
      .addCase(logout.rejected, (state, action) => {
        // 临时当退出报错时，同样把token置空
        state.access_token = undefined;
        state.refresh_token = undefined;
        state.currentTradeType = undefined;
        state.isOpenedNickName = false;
        state.is_set_pwd = 1;
      })
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        state.access_token = payload.data.access_token;
        state.refresh_token = payload.data.refresh_token;
      })
      .addCase(visitorLogin.fulfilled, (state, { payload }) => {
        console.log("visitorLogin:", payload);
        state.isVisitor = true;
        state.access_token = payload.data.ltoken;
        state.refresh_token = undefined;
        state.balance = 0;
        state.is_set_pwd = 1;
      })
      .addCase(getCSPhone.fulfilled, (state, { payload }) => {
        state.customerService = payload.data;
      })
      .addCase(setNick.fulfilled, (state, { payload, meta }) => {
        state.nickname = meta.arg.new_nickname;
      })
      .addCase(getPlayerLimit.fulfilled, (state, { payload, meta }) => {
        console.log(payload, "payloadpayloadpayloadpayload");
        if (payload === "p-10016") {
          state.limit_array = [
            {
              currency: 0,
              limit_array: [
                {
                  bet_type: 1,
                  max_amount: 100000000,
                  min_amount: 10,
                },
                {
                  bet_type: 2,
                  max_amount: 100000000,
                  min_amount: 10,
                },
                {
                  bet_type: 3,
                  max_amount: 100000000,
                  min_amount: 10,
                },
              ]
            }
          ];
        } else {
          state.limit_array = payload || [];
        }
      })
      .addCase(getBalance.fulfilled, (state, { payload }) => {
        console.log(payload.data, "payloadpayloadpayload");
        state.balance = Number(payload.data.balance) / 100;
      })
      .addCase(REHYDRATE, (state, action) => {
        const ac = action as any;
        const st = ac.payload as State;
        console.log("localstorage读取到state完成时:", st, st && st[AUTH]);
        if (st && st[AUTH] && !st[AUTH].currentTradeType) {
          // state.access_token = undefined;
          state.refresh_token = undefined;
          state.currentTradeType = undefined;
        }
      })
      .addMatcher(isRejectedAction, (state, action) => {
        console.log(action);
        throw action.error.message;
      });
  },
});
export const authSelector = createSelector(
  (rootState: RootState) => rootState[AUTH],
  (state) => state
);

const { setPayType, setBalance, logout: localLogout, setIsOpenedNickName, setAccount } = slice.actions;

export { setPayType, setBalance, localLogout, setIsOpenedNickName, setAccount };

export default slice;
