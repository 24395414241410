import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Modals from "@/components/Header/Modals";
import { useAppDispatch, useAppSelector } from "@/store";
import { getGameDeskById, setShowTablePick } from "@/store/slices/bet.slice";
import { enterClassicDesk, enterLuxuryDesk, leaveDesk } from "../functions/enterDesk";

const useEnterGame = (deskNo: string) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { openLuxury } = useAppSelector(state => state.config);

  const confirm = (data) => {
    localStorage.setItem("betPreference", data);
    if (data) {
      setTimeout(() => navigate(`/luxuryBet/${deskNo}`));
    } else {
      setTimeout(() => navigate(`/classic/${deskNo}`));
    }
  };

  const startGame = async () => {
    const betPreference = localStorage.getItem("betPreference");
    const inGameDesk = pathname.includes("/luxuryBet") || pathname.includes("/classic");

    const deskId = inGameDesk ? pathname.match(/\/(\d+)/)[1] : "";

    // if (betPreference === null) {
    //   Modals.showSelectBetting({ confirm });
    // } else {

    let leaveDeskSuccess = true;
    // 如果在桌台内 先离桌
    if (inGameDesk) {
      leaveDeskSuccess = await leaveDesk(deskId);
    }

    if (!leaveDeskSuccess) return;

    if (openLuxury && !!Number(betPreference)) {
      await enterLuxuryDesk(deskNo);
    } else {
      await enterClassicDesk(deskNo);
    }
    // dispatch(getGameDeskById({ desk_id: deskNo, _isNeedLoading: true })).then((data) => {
    //   console.log(data, "datatatatta");

    //   dispatch(setShowTablePick(false));

    //   if (data.payload.msg !== "success") return;
    //   if (!!Number(betPreference)) {
    //     enterClassicDesk(deskNo)
    //     navigate(`/luxuryBet/${deskNo}`);
    //   } else {
    //     enterLuxuryDesk(deskNo)
    //     navigate(`/classic/${deskNo}`);
    //   }
    // });
    // }
  };

  return startGame;
};

export const useEnterGame1 = () => {
  const [deskId, setDeskId] = useState(0);
  const navigate = useNavigate();
  const { openLuxury } = useAppSelector(state => state.config);

  const confirm = (data) => {
    localStorage.setItem("betPreference", data);
    if (data) {
      setTimeout(() => navigate(`/luxuryBet/${deskId}`));
    } else {
      setTimeout(() => navigate(`/classic/${deskId}`));
    }
  };

  const startGame = (deskId) => {
    const betPreference = localStorage.getItem("betPreference");
    setDeskId(deskId);
    if (betPreference === null) {
      Modals.showSelectBetting({ confirm });
    } else {
      if (openLuxury && !!Number(betPreference)) {
        navigate(`/luxuryBet/${deskId}`);
      } else {
        setTimeout(() => navigate(`/classic/${deskId}`));
      }
    }
  };

  return startGame;
};

export default useEnterGame;
