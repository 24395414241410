import { createGlobalStyle } from "styled-components";
import { DESIGN_WIDTH, getWndowWidth } from "@/utils/tool";
const GlobalStyle = createGlobalStyle`
  html,body,#root {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width:100%;
    height:100%;
  }

  #root {
    overflow-y: auto;
    background: #0D0D0D;
    ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  }
  *{
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing:border-box;
  }
  html{
    font-size: ${getWndowWidth() / DESIGN_WIDTH}px;
  }
  body{
    font-size:12px;
    user-select:none;
  }
  // input 自动填充的样式覆盖
  input:-webkit-autofill {
    -webkit-text-fill-color: #FFF !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
    background-color:transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
  }

  /* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #E0B975 !important;
    color: #fff;
  } */
`;
const GlobalView: React.FC = () => {
  return <GlobalStyle />;
};
export default GlobalView;
