/**
 *
 * @constructor
 * 菜单弹窗
 */
import { FC } from "react";
import Dialog from "@/components/Dialog";
import styled from "styled-components";
import Icon from '@/components/Icon'
import { Row } from "@/components/flex";
import MyButton from "@/components/MyButton";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import { useTranslation } from "react-i18next";

// 菜单列表
const MenuList = styled.ul`
  width: 355px;
`
const MenuItem = styled(Row)`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  color: #F6E0B0;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  .iconMenuItem {
    margin-right: 10px;
    color: #F6E0B0;
    font-size: 24px;
    transition: color 300ms ease-in-out;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
    content: "\\20";
    height: 1px;
    border-bottom: 1px solid #53504a;
  }

  &:hover {
    background-color: rgba(204, 169, 106, .2);
    color: #fff;

    .iconMenuItem {
      color: #FFF;
    }
  }
`

export interface MenuDataItem {
  onlyCustomer?: boolean, // 仅在正式账号下展示
  icon: string,
  name: string,
  onChose?: () => void
}

interface Props {
  visible?: boolean,
  onClose?: () => void,
  menuData?: MenuDataItem[]
}

const MenuDlg: FC<Props> = ({ visible, onClose, menuData = [] }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose })
  const { t } = useTranslation()
  const openItem = (item) => {
    item.onChose()
    setIsShow(false)
  }

  return (
    <Dialog
      title={t('modalMenu:menuTitle')}
      visible={isShow}
      buttons={[
        {
          type: 'primary',
          text: t('modalMenu:buttonClose'),
          action: () => setIsShow(false)
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}>
      <MenuList>
        {
          menuData?.map((item, index) => {
            return <MenuItem key={index} ailgn="center" onClick={() => openItem(item)}>
              <Icon className="iconMenuItem" type={item.icon ?? ''}/>
              <span>{item.name}</span>
            </MenuItem>
          })
        }
      </MenuList>
    </Dialog>
  )
}

export default MenuDlg
