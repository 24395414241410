import { Column, Row } from "@/components/flex";
import MyButton from "@/components/MyButton";
import Dialog from "@/components/Dialog";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import { FC, Fragment, useEffect } from "react";
import Icon from "@/components/Icon";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "@/store";
import { authSelector, getCSPhone } from "@/store/slices/auth.slice";
import { useTranslation } from "react-i18next";

const Wrap = styled(Column)`
  width: 343px;
  margin: 35px 40px 0;
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border-radius: 8px;
  color: #fff;
  background-color: #1D1D1D;
`

const NoticeText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;
  color: #D4BA8A;
`

interface Props {
  visible?: boolean,
  onClose?: () => void
}

const Support: FC<Props> = ({ visible, onClose }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose })
  const dispatch = useAppDispatch()
  const state = useAppSelector(authSelector)
  const { t } = useTranslation()
  useEffect(() => {
    dispatch(getCSPhone())
  }, [])

  return (
    <Dialog
      title={t('contactNumber:title')}
      visible={isShow}
      buttons={[
        {
          type: 'primary',
          text: t('changePassword:buttonConfirm'),
          action: () => setIsShow(false)
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}>
      <Wrap justify='center'>
        {
          state.customerService?.map((item, index) => {
            return (
              <Row style={{ marginTop: 16 }} key={index}>
                <Icon style={{ fontSize: 32 }} type='icon-support-agent' color='#D3AF6E'/>
                <span style={{
                  margin: '0 20px 0 12px',
                  color: '#D4BA8A',
                  fontSize: 14
                }}>{t('contactNumber:hotline' + (index + 1))}</span>
                <p style={{ marginLeft: 12 }}>+{item.phone} </p>
              </Row>
            )
          })
        }

      </Wrap>
      <NoticeText>{t('contactNumber:contactNumberMessage')}</NoticeText>
      {/* <Row style={{ width: '325px', margin: '10px auto 18px', padding: '15px 10px' }} justify="center" ailgn="center"> */}
      {/*   <MyButton styles={{ marginLeft: '25px' }} width={140} name={t('contactNumber:buttonConfirm')} */}
      {/*     onClick={() => setIsShow(false)}/> */}
      {/* </Row> */}
    </Dialog>
  )
}

export default Support
