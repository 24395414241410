import { FC, useState } from "react";
import Dialog from "@/components/Dialog";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import { Row } from "@/components/flex";
import styled from "styled-components";
import MyButton from "@/components/MyButton";
import { useTranslation } from "react-i18next";

interface BettingItemStyledProps {
  isSelected: boolean;
}

const BettingItem = styled.div<BettingItemStyledProps>`
  h3 {
    margin-top: 22px;
    margin-bottom: 19px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    text-align: center;
  }

  img {
    width: 354px;
    height: auto;
    cursor: pointer;
  }

  div {
    width: 350px;
    height: 184px;
    background: #232322 no-repeat center;
    background-size: 100%;
    border-radius: 8px;
    opacity: ${props => props.isSelected ? '1' : '0.5'};
  }

  & + & {
    margin-left: 30px;
  }
`;

const SelectBox = styled(Row)`
  padding: 0 42px;
`;

interface Props {
  visible?: boolean;
  onClose?: () => void;
  confirm?: (selected: number) => void;
}

const SelectBetting: FC<Props> = ({ visible, onClose, confirm }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const { t } = useTranslation()
  const [selected, setSelected] = useState(
    localStorage.getItem("betPreference")
      ? Number(localStorage.getItem("betPreference"))
      : 0
  );

  const betts = [
    {
      value: 1,
      title: t('tableStyleModal:tableStyleLuxuryLabel'),
      imgSrc: require("@/assets/images/common/choseBetting_bestnew.png"),
    },
    {
      value: 0,
      title: t('tableStyleModal:tableStyleClassicLabel'),
      imgSrc: require("@/assets/images/common/choseBetting_classicnew.png"),
    },
  ];

  const confirmFn = () => {

    confirm && confirm(selected);
    setIsShow(false);
  };

  return (
    <Dialog
      title={t('tableStyleModal:title')}
      visible={isShow}
      buttons={[
        {
          text: t('tableStyleModal:buttonCancel'),
          action: () => setIsShow(false)
        },
        {
          text: t('tableStyleModal:buttonConfirm'),
          action: confirmFn
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <SelectBox
        justify="space-between"
      >
        {betts?.map((item, index) => (
          <BettingItem
            key={index}
            isSelected={item.value === selected}
            onClick={() => setSelected(item.value)}
          >
            <h3>{item.title}</h3>
            <div style={{ backgroundImage: `url(${item.imgSrc})` }}>
              {/* <img src={item.imgSrc} alt=""/> */}
            </div>
          </BettingItem>
        ))}
      </SelectBox>
      {/* <Row */}
      {/*   style={{ */}
      {/*     width: "330px", */}
      {/*     margin: "10px auto 18px", */}
      {/*     padding: "15px 10px", */}
      {/*   }} */}
      {/*   justify="center" */}
      {/*   ailgn="center" */}
      {/* > */}
      {/*   <MyButton */}
      {/*     cancel */}
      {/*     name={t('tableStyleModal:buttonCancel')} */}
      {/*     width={140} */}
      {/*     onClick={() => setIsShow(false)} */}
      {/*   /> */}
      {/*   <MyButton */}
      {/*     styles={{ marginLeft: "25px" }} */}
      {/*     width={140} */}
      {/*     name={t('tableStyleModal:buttonConfirm')} */}
      {/*     onClick={confirmFn} */}
      {/*   /> */}
      {/* </Row> */}
    </Dialog>
  );
};

export default SelectBetting;
