import { useAppSelector } from "@/store";
import { getLocaleSelector } from "@/store/slices/config.slice";
import React, { useMemo } from "react";
import { CALC } from "..";
import { formatAmount, retain } from "../tool";

const useFormat = (amount: number | string) => {
  const locale = useAppSelector(getLocaleSelector);

  const amountFmt = useMemo(() => {
    if (locale === "cn") {
      return `${retain(CALC.divide(Number(amount), 10000), 4)}万`;
    } else {
      return formatAmount(Number(amount));
    }
  }, [amount, locale]);

  return amountFmt;
};

export default useFormat;
