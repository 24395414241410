import { FC } from "react";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import Dialog from "@/components/Dialog";
import MyButton from "@/components/MyButton";
import { Row } from "@/components/flex";
import styled from "styled-components";
import store, { useAppDispatch, useAppSelector } from "@/store";
import { getBalance, logout } from "@/store/slices/auth.slice";
import { getLastBet, resetChipsStack } from "@/store/slices/bet.slice";
import { history } from "@/routers";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { revokeBet } from "@/services/game";
import { setDeskBetInfo, resetChipsStack as resetChipsStackLuxury, setQuickBet } from "@/store/slices/luxury.slice";
import { setDeskInfos, resetChipsStack as resetChipsStackMulti } from "@/store/slices/multi.slice";
import { message } from "@/components/Message";
import { seatNos } from "@/utils/tool";
import { gameAPI } from "@/services";

const Text = styled.p`
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;
`;

interface Props {
  visible?: boolean;
  small?: boolean;
  isQuick?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  alertMsg?: any;
  params?: Record<string, any>;
}
const RevokeDlg: FC<Props> = ({ visible, onClose, onConfirm, onCancel, small = false, params, alertMsg, isQuick }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const {
    deskInfo: { game_no },
  } = useAppSelector((state) => state.bet);
  const { account } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { deskId } = useParams();

  const { t } = useTranslation();

  // 快捷撤注
  const revokeQuickBet = async () => {
    const tempQuickBet = { ...store.getState().luxury.quickBet };
    const { deskId, game_no, player_bet_info } = tempQuickBet;

    try {
      const { msg } = await revokeBet({
        desk_id: deskId,
        game_no,
        action_at: Date.now(),
        revoke_flag: 1,
        _isNeedLoading: true,
      });

      if (msg !== "success") return;
      message.success(t("game:withdrawalSucceed"));

      dispatch(
        setQuickBet({
          ...tempQuickBet,
          chipsStack: {
            playerDouble: [],
            tie: [],
            bankerDouble: [],
            player: [],
            banker: [],
          },
          player_bet_info: player_bet_info.filter((betInfo) => betInfo.player_account !== account),
        })
      );
      // 刷新余额
      dispatch(getBalance()).catch(() => {
        console.log("访客没有余额");
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsShow(false);
      onConfirm && onConfirm();
    }
  };

  const confirm = async () => {
    if (isQuick) {
      return revokeQuickBet();
    }

    const {
      deskInfo: { desk_id, seat_no },
    } = store.getState().bet;
    const isLuxury = history.location.pathname.includes("luxuryBet");
    const restParams = params ? params : { desk_id: Number(desk_id), game_no };

    try {
      const { code, msg } = await revokeBet({
        ...restParams,
        action_at: Date.now(),
        revoke_flag: 1,
        _isNeedLoading: true,
      });

      if (msg !== "success") return;

      if (!small) {
        const { deskBetInfo } = store.getState().luxury;
        dispatch(setDeskBetInfo(deskBetInfo.filter((betInfo) => betInfo.player_account !== account)));

        if (isLuxury) {
          dispatch(resetChipsStackLuxury(seatNos.indexOf(seat_no)));
        } else {
          dispatch(resetChipsStack());
        }
        !store.getState().auth.isVisitor && dispatch(getLastBet({ desk_id: Number(desk_id) }));

        // message.success("撤单成功");
        message.success(t("game:withdrawalSucceed"));
      } else {
        const { deskInfos } = store.getState().multi;
        const index = deskInfos.findIndex((deskInfo) => deskInfo.desk_id === params.desk_id);
        if (index === -1) return;
        const { data: lastBet } = await gameAPI.getLastBet({ desk_id: Number(desk_id) });

        const tempDeskInfos = [...deskInfos];
        const tempDeskInfo = { ...tempDeskInfos[index] };
        const lastBetInfo = tempDeskInfo.player_bet_info.filter((betInfo) => betInfo.player_account !== account);

        tempDeskInfos[index] = {
          ...tempDeskInfo,
          lastBet,
          player_bet_info: lastBetInfo,
        };

        dispatch(setDeskInfos(tempDeskInfos));
        dispatch(resetChipsStackMulti(index));
        alertMsg(t("game:withdrawalSucceed"));
      }
      // 刷新余额
      dispatch(getBalance()).catch(() => {
        console.log("访客没有余额");
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsShow(false);
      onConfirm && onConfirm();
    }
  };

  return (
    <Dialog
      title={t("withdrawal")}
      small={small}
      visible={isShow}
      buttons={[
        {
          text: t("exitGame:buttonCancel"),
          action: () => {
            setIsShow(false);
            onCancel && onCancel();
          },
        },
        {
          text: t("exitGame:buttonConfirm"),
          action: confirm,
        },
      ]}
      onClose={() => {
        setIsShow(false);
        onConfirm && onConfirm();
      }}
      onAnimationend={close}
    >
      <Text>{t("game:cancelBetOrNot")}</Text>
    </Dialog>
  );
};

export default RevokeDlg;
