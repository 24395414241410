import Dialog from "@/components/Dialog";
import { useAppDispatch } from "@/store";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Text = styled.p`
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
`;

interface Props {
  visible?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}
const WaitConfirmDlg: FC<Props> = ({ visible, onClose, onConfirm, onCancel }) => {
//   const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const [time, setTime] = useState(5)

  useEffect(() => {
    if (visible) {
      setTime(5)
      setTimeout(countdown, 1000)
    }
  }, [visible])

  function countdown() {
    setTime(state => {
      if (state > 0) {
        setTimeout(() => {
          countdown()
        }, 1000)
        return state - 1
      }
      onClose()
      return state
    })
  }

  return (
    <Dialog
      title={"提示"}
      visible={visible}
      showCloseIcon={false}
      onClose={onClose}
      onAnimationend={close}
    >
      <Text>{t('SwitchDesk:requestTableChange', { time })}</Text>
    </Dialog>
  );
};

export default WaitConfirmDlg;
