import React, {FC, useRef, useState} from "react";
import Dialog from "@/components/Dialog";
import {useDlgHooks} from "@/components/Header/Modals/hooks";
import styled from "styled-components";
import {Column, Row} from "@/components/flex";
import MyButton from "@/components/MyButton";
import OInput from "@/components/OInput";
import * as Modals from "@/components/Header/Modals/index";
import {log} from "util";
import {useAppDispatch, useAppSelector} from "@/store";
import {authSelector, logout, refreshToken, updatePassword} from "@/store/slices/auth.slice";
import {delay, toMD5} from "@/utils";
import {useTranslation} from "react-i18next";

interface Props {
  visible?: boolean,
  onClose?: () => void
}

const Input = styled(OInput)`
  margin-top: 24px;
`


const ModifyPassword = React.forwardRef(({visible, onClose}: Props, ref) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {isShow, setIsShow, close} = useDlgHooks({visible, onClose})
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validateAll, setValidateAll] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  })
  const state = useAppSelector(authSelector)
  const iptRef = useRef<any>(null)

  const rules = [
    {
      required: true,
      message: t('changePassword:passwordMessage'),
      trigger: 'blur',
    },
    {
      pattern: /^[a-zA-Z0-9]{6,20}$/,
      message: t('changePassword:passwordValidationMessage'),
      trigger: 'blur',
    }
  ]

  const newPasswordRules = [
    {
      required: true,
      message: t('changePassword:passwordMessage'),
      trigger: 'blur',
    },
    {
      pattern: /^[a-zA-Z0-9]{6,20}$/,
      message: t('changePassword:passwordValidationMessage'),
      trigger: 'blur',
    },
    {
      message: t('changePassword:newAsOld'),
      validator(value) {
        console.log('input:', value)
        console.log('old', oldPassword)
        return oldPassword !== value
      }
    }
  ]

  // 确认密码
  const customRules = {
    message: t('changePassword:passwordMismatchMessage'),
    validator(value) {
      console.log(value)
      return value === newPassword
    }
  }

  // 确认修改
  const onConfirm = async () => {
    iptRef.current.handleValidate()
    await delay(200)
    if (iptRef.current.isErr) return;
    // todo
    if (!iptRef.current.isErr && validateAll.oldPassword && validateAll.confirmPassword) {
      dispatch(updatePassword({
        _isNeedLoading: true,
        old_password: toMD5(`ebet_account${oldPassword}EbetGameL`),
        new_password: toMD5(`ebet_account${newPassword}EbetGameL`),
        confirm_password: toMD5(`ebet_account${confirmPassword}EbetGameL`)
      })).then(() => {
        Modals.showConfirm({
          title: t('changePassword:title'),
          content: t('changePassword:passwordModalMessage'),
          onConfirm() {
            window.location.href = '/auth'
          }
        })
      }).catch()
    }
  }

  function handleClose () {
    close()
    if (state.is_set_pwd === 0) {
      dispatch(logout()).then(() => {})
      .finally(() => {
        window.location.href = "/auth";
      })
    }
  }

  return (
    <Dialog
      title={t('changePassword:title')}
      visible={isShow}
      buttons={[
        {
          text: t('changePassword:buttonCancel'),
          action: () => setIsShow(false)
        },
        {
          text: t('changePassword:buttonConfirm'),
          action: onConfirm
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={handleClose}>
      <Column style={{width: '427px', padding: '0 42px'}}>
        <Input label={t('changePassword:oldPasswordLabel')} type='password' value={oldPassword}
               onChange={setOldPassword} rules={rules}
               onValidate={b => setValidateAll({...validateAll, oldPassword: b})}/>
        <Input ref={iptRef} label={t('changePassword:newPasswordLabel')} type='password' value={newPassword}
               onChange={setNewPassword} rules={newPasswordRules}
               onValidate={b => setValidateAll({...validateAll, newPassword: b})}/>
        <Input label={t('changePassword:confirmNewPasswordLabel')} type='password' value={confirmPassword}
               onChange={setConfirmPassword} rules={customRules}
               onValidate={b => setValidateAll({...validateAll, confirmPassword: b})}/>
      </Column>
      {/* <Row style={{width: '325px', margin: '10px auto 18px', padding: '15px 10px'}} justify="center" ailgn="center"> */}
      {/*   <MyButton cancel name={t('changePassword:buttonCancel')} width={140} onClick={() => setIsShow(false)}/> */}
      {/*   <MyButton styles={{marginLeft: '25px'}} width={140} name={t('changePassword:buttonConfirm')} */}
      {/*             onClick={onConfirm}/> */}
      {/* </Row> */}
    </Dialog>
  )
})

export default ModifyPassword
