import BaotaiPassword from "@/components/Header/Modals/BaotaiPassword";
import BetLimit from "@/components/Header/Modals/BetLimit";
import BetType from "@/components/Header/Modals/BetType";
import CasinoPick from "@/components/Header/Modals/CasinoPick";
import ChangeTradition from "@/components/Header/Modals/ChangeTradition";
import ChipChose from "@/components/Header/Modals/ChipChose";
import Confirm from "@/components/Header/Modals/Confirm";
import Locales from "@/components/Header/Modals/Locales";
import ModifyNick from "@/components/Header/Modals/ModifyNick";
import ModifyPassword from "@/components/Header/Modals/ModifyPassword";
import QuitDlg from "@/components/Header/Modals/Quit";
import RecordDlg from "@/components/Header/Modals/Record";
import RevokeDlg from "@/components/Header/Modals/Revoke";
import RoadTips from "@/components/Header/Modals/RoadTips";
import SelectBetting from "@/components/Header/Modals/SelectBetting";
import SetVoice from "@/components/Header/Modals/SetVoice";
import Support from "@/components/Header/Modals/Support";
import SwitchDesk from "@/components/Header/Modals/SwitchDesk";
import Tips from "@/components/Header/Modals/Tips";
import { IRouter } from "@/routers";
import store from "@/store";
import { ReactElement } from "react";
import type { Root } from "react-dom/client";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import BetterApplyChange from "./BetterApplyChange";
import MenuDlg, { MenuDataItem } from "./Menu";

// 管理弹窗
interface ModalInstance {
  root: Root;
  container: HTMLDivElement;
}

// 弹窗管理类
const modalMap = new Map<Symbol | number | string, ModalInstance>();

const destroyModal = (mark: Symbol) => {
  if (modalMap.has(mark)) {
    let { root, container } = modalMap.get(mark);
    // console.log('摧毁弹窗~！')
    root.unmount();
    document.body.removeChild(container);
    root = undefined;
    modalMap.delete(mark);
    // console.log(modalMap)
  }
};
// 渲染弹窗
const renderBody = (mark: Symbol, node: ReactElement) => {
  const container = document.createElement("div"); // 不能渲染
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <IRouter>{node}</IRouter>
    </Provider>
  );
  document.body.appendChild(container);
  modalMap.set(mark, { root, container });
  return mark;
};

const showRevokeBet = () => (options?: QuitOptions) => {
  const mark = Symbol("Menu");
  const element = <QuitDlg onConfirm={options?.onConfirm} onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showMenu = (menuData?: MenuDataItem[]) => {
  const mark = Symbol("Menu"); // 产生一个唯一识别弹窗标记
  const element = <MenuDlg menuData={menuData} onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showRecord = () => {
  const mark = Symbol("Menu");
  const element = <RecordDlg onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};
interface QuitOptions {
  onConfirm?: () => void;
  isQuick?: boolean;
}
const showQuit = (options?: QuitOptions) => {
  const mark = Symbol("Menu");
  const element = <QuitDlg onConfirm={options?.onConfirm} onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showRevoke = (options?: QuitOptions) => {
  const mark = Symbol("Menu");
  const element = (
    <RevokeDlg onConfirm={options?.onConfirm} onClose={() => destroyModal(mark)} isQuick={!!options.isQuick} />
  );
  renderBody(mark, element);
};

const showChipChose = () => {
  const mark = Symbol("Menu");
  const element = <ChipChose onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showVoice = () => {
  const mark = Symbol("Menu");
  const element = <SetVoice onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showLocales = () => {
  const mark = Symbol("Menu");
  const element = <Locales onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showModifyPassword = () => {
  const mark = Symbol("Menu");
  const element = <ModifyPassword onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showModifyNick = () => {
  const mark = Symbol("Menu");
  const element = <ModifyNick onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showSupport = () => {
  const mark = Symbol("Menu");
  const element = <Support onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

const showGoodRoad = () => {
  const mark = Symbol("Menu");
  const element = <RoadTips onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

interface ConfirmOptions {
  title?: string;
  content?: any;
  cancel?: boolean;
  showCloseIcon?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}
const showConfirm = (options?: ConfirmOptions) => {
  const mark = Symbol("Menu");
  const element = (
    <Confirm
      title={options?.title}
      content={options?.content}
      cancel={options?.cancel}
      onCancel={options?.onCancel}
      onConfirm={options?.onConfirm}
      onClose={() => destroyModal(mark)}
      showCloseIcon={options?.showCloseIcon}
    />
  );
  renderBody(mark, element);
};

// 保胎密码弹窗
const showBaotai = (enterGame, deskId, changeTradition?) => {
  const mark = Symbol("Menu");
  const element = changeTradition ? (
    <BaotaiPassword
      onClose={() => destroyModal(mark)}
      enterGame={enterGame}
      deskId={deskId}
      changeTradition={changeTradition}
    />
  ) : (
    <BaotaiPassword onClose={() => destroyModal(mark)} enterGame={enterGame} deskId={deskId} />
  );
  renderBody(mark, element);
};

// 切换传统桌台弹框
const showChangeTradition = (enterGame, deskIn, deskOut) => {
  const mark = Symbol("Menu");
  const element = (
    <ChangeTradition onClose={() => destroyModal(mark)} enterGame={enterGame} deskIn={deskIn} deskOut={deskOut} />
  );
  renderBody(mark, element);
};

// 切换传统桌台弹框
const showSwitchDesk = (clubId, deskNo, deskId, oldDeskId, isChangeClub = false, oldClubId = 0, oldDeskNo = "") => {
  const mark = Symbol("showSwitchDesk");
  const element = (
    <SwitchDesk
      onClose={() => {
        destroyModal(mark);
      }}
      deskNo={deskNo}
      deskId={deskId}
      clubId={clubId}
      oldDeskId={oldDeskId}
      isChangeClub={isChangeClub}
      oldClubId={oldClubId}
      oldDeskNo={oldDeskNo}
    />
  );
  renderBody(mark, element);
};

// 切换传统桌台弹框
const showBetterApplyChange = ({
  old_club_id,
  new_club_id,
  new_desk_no,
  new_desk_id,
  old_desk_id,
  is_change_club,
  old_desk_no,
}) => {
  const mark = Symbol("showBetterApplyChange");
  const element = (
    <BetterApplyChange
      onClose={() => destroyModal(mark)}
      new_desk_no={new_desk_no}
      old_desk_no={old_desk_no}
      new_desk_id={new_desk_id}
      new_club_id={new_club_id}
      old_club_id={old_club_id}
      old_desk_id={old_desk_id}
      is_change_club={is_change_club}
    />
  );
  renderBody(mark, element);
};

// 选择投注界面
const showSelectBetting = ({ confirm }) => {
  const mark = Symbol("Menu");
  const element = <SelectBetting onClose={() => destroyModal(mark)} confirm={confirm} />;
  renderBody(mark, element);
};

// 小费弹窗
const showTips = () => {
  const mark = Symbol("Menu");
  const element = <Tips onClose={() => destroyModal(mark)} />;
  renderBody(mark, element);
};

// 选择赌场
const showCasino = (onChoose?) => {
  const mark = Symbol("Menu");
  const element = <CasinoPick onClose={() => destroyModal(mark)} onChoose={onChoose} />;
  renderBody(mark, element);
};

// 限红弹窗
const showBetLimit = (onChoose?) => {
  const mark = Symbol("Menu");
  const element = <BetLimit onClose={() => destroyModal(mark)} onChoose={onChoose} />;
  renderBody(mark, element);
};

// 投注类型
const showBetType = (onChoose?) => {
  const mark = Symbol("Menu");
  const element = <BetType onClose={() => destroyModal(mark)} onChoose={onChoose} />;
  renderBody(mark, element);
};

// 关闭所有弹窗
const closeModals = (mark) => {};

// 关闭所有弹窗
const closeAll = () => {
  modalMap?.forEach((value, key) => {
    destroyModal(key as Symbol);
  });
};

export {
  showMenu,
  showRecord,
  showQuit,
  showRevoke,
  showChipChose,
  showVoice,
  showLocales,
  showModifyPassword,
  showModifyNick,
  showSupport,
  showGoodRoad,
  showConfirm,
  showBaotai,
  showSelectBetting,
  showTips,
  showCasino,
  showBetLimit,
  showBetType,
  closeModals,
  showChangeTradition,
  showSwitchDesk,
  showBetterApplyChange,
  closeAll,
};
