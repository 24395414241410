import React, { useEffect, useRef, useState, memo, CSSProperties } from "react";
import classnames from "classnames/bind";
import { RequireOnlyOne } from "@/type";
import { createRoot, Root } from "react-dom/client";
import scss from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { history } from "@/routers";

const cn = classnames.bind(scss);

interface Button {
  type?: "default" | "primary";
  text?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  action: () => void;
}

interface Props {
  title: string;
  visible: boolean;
  showCloseIcon?: boolean;
  message?: string;
  buttons?: Button[];
  children?: React.ReactNode;
  zIndex?: number;
  onClose?: () => void;
  small?: boolean;
  onAnimationend?: () => void;
}

interface Params extends Omit<Props, "message" | "children" | "visible"> {
  message: string;
}

type RequireOne = RequireOnlyOne<Props, "message" | "children">;

const Index: React.FC<RequireOne> = (props) => {
  let { onClose, buttons = [] } = props;
  const { title, message, children, visible: visibleProps, onAnimationend, small, showCloseIcon = true } = props;
  const wrapperEl = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(visibleProps);
  const { t } = useTranslation();

  const defaultButton: Button[] = [
    {
      type: "default",
      text: t("common:buttonCancel"),
      action: () => null,
    },
    {
      type: "primary",
      text: t("common:buttonConfirm"),
      action: () => null,
    },
  ];

  buttons = buttons?.filter(Boolean).map((item, index) => {
    return {
      ...defaultButton[index],
      ...item,
    };
  });

  onClose = onClose || buttons[0]?.action;
  useEffect(toggleVisible, [visibleProps]);

  function toggleVisible() {
    const wrapper = wrapperEl.current;
    if (visibleProps) {
      setVisible(visibleProps);
    } else {
      wrapper && (wrapper.onanimationend = animationend);
    }
  }

  function animationend() {
    setVisible(false);
    onAnimationend && onAnimationend();
  }

  return visible ? (
    <div
      ref={wrapperEl}
      style={{ zIndex: props.zIndex || 1000 }}
      className={cn(
        scss.container,
        "animate__animated",
        visibleProps ? "animate__fadeIn" : "animate__fadeOut",
        small ? scss.small : "",
        history.location.pathname.includes("luxuryBet") ? "luxury" : ""
      )}
    >
      <div className={cn(scss.dialog, "animate__animated", visibleProps ? "animate__zoomIn" : "animate__zoomOut")}>
        <div className={scss.header}>
          <span>{title}</span>
          {showCloseIcon && <a className={scss.header__close} onClick={onClose}></a>}
        </div>
        <div className={scss.content}>{children || <p className={scss.message}>{message}</p>}</div>
        {!!buttons?.length && (
          <div className={scss.footer}>
            {buttons?.map((item, index) => (
              <button
                key={index}
                style={item.style}
                disabled={item.disabled}
                className={cn(scss.button, scss[item.type], { disabled: item.disabled }, item.className)}
                onClick={item.action}
              >
                {item.text}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

function open(instanceId: string, params: Params): Root {
  const container = document.createElement("div");
  const root = createRoot(container);
  document.body.appendChild(container);
  container.setAttribute("id", instanceId);
  root.render(<Index visible {...params} />);
  return root;
}

function hide(instanceId: string, params: Params, root: Root) {
  params.onAnimationend = () => {
    const container = document.getElementById(instanceId);
    container?.parentNode?.removeChild(container);
    root.unmount();
  };
  root.render(<Index visible={false} {...params} />);
}

function dialog(params: Params): () => void {
  const instanceId = `dialog_${Date.now()}`;
  const root = open(instanceId, params);
  return hide.bind(null, instanceId, params, root);
}

export type { Button };
export { dialog };
export default memo(Index);
