/* eslint-disable @typescript-eslint/member-ordering */
import * as Modals from "@/components/Header/Modals";
import store from "@/store";
import { setReConnecting } from "@/store/slices/bet.slice";
import { t } from "i18next";
import { connect, JSONCodec, NatsConnection, StringCodec } from "nats.ws";
import { reconnectSuccessed } from "./functions";
import { Communicator } from "./tool";

const jc = JSONCodec();

export class MyWS {
  static socket = null;
  private sc = null;
  private listenerEventList = [];
  private subscriptions = [];
  private options = {
    // 链接地址
    reconnect: true,
    // user: '',
    // pass: '',
    // pingInterval: 5000,
    // reconnectTimeWait: 3000,
    // maxReconnectAttempts: 3,
    // timeout: 8000,
    // servers: [process.env.REACT_APP_WS_URL],
  };

  static read = (instance) => {
    return instance;
  };

  static instance;

  constructor(options = {}) {
    if (MyWS.instance) throw new Error("ws已经建立,请勿重复创建!!");
    this.options = {
      ...this.options,
      ...options,
    };
    this.WebSocketConnect();
  }

  get socket() {
    return MyWS.socket as NatsConnection;
  }
  set socket(value) {
    MyWS.socket = value;
  }
  private setData = (data = {}) => {
    const actionData = {
      action: "test",
    };
    // 可以在这里格式化数据
    Object.assign(actionData, data);
    return actionData;
  };

  WebSocketConnect = async () => {
    try {
      this.socket = await connect({
        ...this.options,
      });
      this.checkSocketStatus();
      MyWS.instance = this;
      Communicator.action("WS_init", { ws: this.socket });
      this.sc = StringCodec();
      MyWS.read(this);
    } catch (err) {
      console.log(err.code, "----000-----");
    }
  };

  // 检测当前socket的连接状态
  checkSocketStatus = () => {
    (async () => {
      for await (const s of this.socket.status()) {
        console.log(s.type);
        // if (s.type === "reconnecting") {
        //   store.dispatch(setReConnecting(true));
        // }

        if (s.type === "reconnect") {
          store.dispatch(setReConnecting(false));
          reconnectSuccessed();
          // message.success({
          //   message: "重新连接成功",
          //   duration: 3000,
          // });
        }
      }
    })();

    this.socket.closed().then((err) => {
      console.log((err as any).message, "NATS connection closed");
      store.dispatch(setReConnecting(false));
      Modals.showConfirm({
        showCloseIcon: false,
        content: t("errMsgs:NetworkConnectionFailed"),
        onConfirm() {
          window.location.reload();
        },
      });
    });
  };

  /**
   * 广播事件
   * @param topic 主题
   * @param payload 负载
   */
  public publish = (topic: string, payload: Record<string, any>) => {
    if (this.socket) {
      this.socket.publish(topic, jc.encode(payload));
    } else {
      console.log("no connection exsit");
    }
  };

  removeEventListener = (evnetName) => {
    try {
      if (!this.listenerEventList.includes(evnetName)) {
        console.log("找不到事件名");
        return;
      }
      console.log("removeEventListener", evnetName);
      this.listenerEventList = this.listenerEventList?.filter((item) => item !== evnetName);

      this.subscriptions.filter((sub) => sub.getSubject() === evnetName)?.forEach((sub) => sub.unsubscribe());
      this.subscriptions = this.subscriptions?.filter((sub) => sub.getSubject() !== evnetName);
    } catch (error) {
      console.log("error", error);
    }
  };

  createEventListener = async (evnetName, callBack) => {
    try {
      if (this.listenerEventList.includes(evnetName)) return;
      this.listenerEventList.push(evnetName);
      const sub = this.socket.subscribe(evnetName);
      this.subscriptions = [...this.subscriptions, sub];

      console.log(`开始监听socket事件 : ${evnetName}`);
      for await (const m of sub) {
        const data = jc.decode(m.data);
        if (callBack) {
          callBack(data);
          continue;
        }
        const actionData = this.setData(data);
        console.log(`事件:${evnetName};\n数据:\n`, data);
        if (Communicator.action(actionData.action, actionData)) continue;
        // 这里传入事件id
        // const errEvent = this.getEvent(data.id);
        // console.error(
        //   `\n触发了\n{\n  "事件key":"${errEvent.key}",\n  "描述":"${
        //     errEvent.describe
        //   }"\n}\n事件,但没有处理!\n${JSON.stringify(data)}`
        // );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}
