import { CustomParams, RequestParams, ResponseData } from "@/api/types";
import { LoadingClass } from "@/components/Loading";
import { message } from "@/components/Message";
import { history } from "@/routers";
import store from "@/store";
import { localLogout } from "@/store/slices/auth.slice";
import { ErrMsgTranslateMaps, getUuid } from "@/utils/tool";
import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { t } from "i18next";
import qs from "qs";

interface AppAxiosRequestConfig extends AxiosRequestConfig {
  customParams?: CustomParams;
}

interface AppAxiosResponse extends AxiosResponse {
  config: AppAxiosRequestConfig;
}

let requestIdList: string[] = [];
const loading = new LoadingClass();

console.log(process.env, "process.envprocess.env");
// const baseURL = process.env.REACT_APP_I_ENV === "dev" ? "/game/api" : "/api";
const baseURL = process.env.REACT_APP_I_ENV === "dev" ? "/api" : "/api";

// 创建 axios 实例
const _http = axios.create({
  timeout: 10000,
  baseURL,
  headers: {
    AppId: "1",
  },
});

// 添加请求拦截器
_http.interceptors.request.use(request, (error) => {
  console.log("request error:\n", error);
  return Promise.reject(error);
});

// 添加响应拦截器
_http.interceptors.response.use(response, responseOnRejected);

function request(config: AppAxiosRequestConfig) {
  const qid = getUuid();
  const state = store.getState();
  const lToken = state["auth"].access_token ?? localStorage.getItem("token");

  const headers = config.headers as AxiosRequestHeaders;
  const contentType = headers["Content-Type"] as string;
  const isFormData = /multipart\/form-data/.test(contentType);
  const paramsFiled = /get|delete/.test(String(config.method)) ? "params" : "data";
  const needStringify = /application\/x-www-form-urlencoded/.test(contentType);

  const data = {
    ...config[paramsFiled],
  };

  const customParams = {
    _isNeedLoading: data._isNeedLoading,
    _isShowErrorTips: data._isShowErrorTips !== false,
    _notHandleResponse: data._notHandleResponse,
    _notShowErrorTipsCode: data._notShowErrorTipsCode,
  };

  delete data._isNeedToken;
  delete data._isNeedLoading;
  delete data._isShowErrorTips;
  delete data._notHandleResponse;
  delete data._notShowErrorTipsCode;

  if (!isFormData) {
    config[paramsFiled] = needStringify ? qs.stringify(data) : data;
  }

  if (customParams._isNeedLoading) {
    requestIdList.push(qid);
    loading.show();
  }

  config.headers = {
    qid,
    lToken,
    ...config.headers,
  };
  config.customParams = customParams;

  return config;
}

function response(response: AppAxiosResponse) {
  const qid = response.config.headers?.qid;
  const { _isNeedLoading, _isShowErrorTips, _notHandleResponse, _notShowErrorTipsCode } = response.config
    .customParams as CustomParams;

  _isNeedLoading && closeLoading(qid);

  if (_notHandleResponse) return response;

  const data = response.data as ResponseData;
  const code = data.code;
  const msg = data.msg;
  const notTipsCode = ["N_000000", "N_000026", "N_000027"];
  const needLoginCode = ["N_000003", "N_000004", "N_000015"];

  const isTips = _isShowErrorTips && code !== _notShowErrorTipsCode && !notTipsCode.indexOf(code);
  if (isTips) {
    console.log(msg);
  }
  if (needLoginCode.includes(code)) {
    // console.log('to login')
  }
  if (data.msg !== "success") {
    if (data.msg !== "p-10016" && data.msg !== "g-10021" && data.msg !== "g-10024") {
      // p-10016未设置个人限红 不用弹出错误提示，来自chris  已申请专属存在提示g-10021
      message.error(`${t(ErrMsgTranslateMaps.get(data.msg) || 'errMsgs:Syserr')} 【${data.msg}】`);
    }

    return Promise.reject(data);
  }
  return response;
}

function responseOnRejected(error: any) {
  const config = error.config;
  if (config?.customParams._isNeedLoading) {
    closeLoading(config.headers.qid);
  }

  if (error.message.indexOf(401) !== -1) {
    message.error(t("errMsgs:LoginExpired"));
    store.dispatch(localLogout());

    if (history.location.pathname !== "/auth") {
      window.location.href = "/auth";
    }
  } else {
    message.error(t("errMsgs:NetworkTimeout"));
  }
  console.log("response error:\n", error);
  return Promise.reject(error.message);
}

function closeLoading(qid?: any) {
  requestIdList = requestIdList?.filter((item) => item !== qid);
  if (!requestIdList.length) {
    loading.hide();
  }
}

function get<T = ResponseData, P = RequestParams>(url: string, params?: P, config?: AxiosRequestConfig<P>) {
  const cfg = {
    params,
    ...config,
  };
  return _http.get<T>(url, cfg);
}

function put<T = ResponseData, P = RequestParams>(url: string, params?: P, config?: AxiosRequestConfig<P>) {
  return _http.put<T>(url, params, config);
}

function post<T = ResponseData, P = RequestParams>(url: string, params?: P, config?: AxiosRequestConfig<P>) {
  return _http.post<T>(url, params, config);
}

function remove<T = ResponseData, P = RequestParams>(url: string, params?: P, config?: AxiosRequestConfig<P>) {
  const cfg = {
    params,
    ...config,
  };
  return _http.delete<T>(url, cfg);
}

// 封装常用请求方法
const http = {
  baseURL,
  get,
  put,
  post,
  delete: remove,
};

export default http;
