import { showBetterApplyChange } from "@/components/Header/Modals";
import { message } from "@/components/Message";
import { hotRoadRecommend } from "@/config/const";
import { history } from "@/routers";
import { quitTraditionMod } from "@/services/game";
import store from "@/store";
import { getBalance, logout, setBalance } from "@/store/slices/auth.slice";
import {
  addToRecommendRoad,
  getHotRoadList,
  getLastBet,
  removeRecommendRoadByDeskId,
  resetChipsStack as resetChipsStackCurrent,
  setChangeDesksInfo,
  setDeskInfo,
  setGameResult,
  setIsLottery,
  setLastBetContent,
  setLobbyDesksInfo,
  setShowWaitConfirm,
  setSpecialServiceFlag,
} from "@/store/slices/bet.slice";
import { setMultiDeskConfig, setQuickBetDeskNos } from "@/store/slices/config.slice";
import {
  getDeskBetInfo,
  resetChipsStack,
  setChipsStack,
  setDeskBetInfo,
  setDeskPlayers,
  setQuickBet,
} from "@/store/slices/luxury.slice";
import { setCurrentDesk, setDeskInfos } from "@/store/slices/multi.slice";
import { ApplyServiceStatus, BetType } from "@/store/types/bet.d";
import { CALC, DeskStatus, GameMod, IStorage, Log, delay } from "@/utils";
import {
  Communicator,
  getBeishu,
  getChipsUnit,
  getNumberPart,
  getUuid,
  isLogin,
  seatNos,
  sumNumberPart,
} from "@/utils/tool";
import {
  ApplyChangeDeskParams,
  ApplyServiceParams,
  BetterEndWorkParams,
  DealChangeDeskParams,
  DeskBettingsParams,
  DeskPlayerInfoParams,
  HotLoadEventParams,
  RefusedFreeGameParams,
  RejectedOrderDetail,
  RejectedOrderParams,
  RevokeBettingsParams,
} from "@/utils/wsEvent";
import { t } from "i18next";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import { quitEnterTradition, restoreChipsAfterRejectOrders } from "../functions";
import { enterClassicDesk, enterLuxuryDesk, leaveDesk } from "../functions/enterDesk";

const betNumberMaps: Record<number, BetType> = {
  1: BetType.BANKER,
  2: BetType.PLAYER,
  3: BetType.TIE,
  4: BetType.BDOUBLE,
  5: BetType.PDOUBLE,
};

export function startBetEvent(result) {
  Log({
    message: "收到开始倒计时事件",
    data: result,
  });
  const deskId = history.location.pathname.match(/\/(\d+)/)[1];
  const { deskInfo } = store.getState().bet;

  const { desk_id, stop_bet_at, game_no, timing_bet } = result;

  if (Number(deskId) === desk_id) {
    // 赌桌game_no更新
    store.dispatch(setDeskInfo({ ...deskInfo, game_no, stop_bet_at, TimingBet: timing_bet || 45 }));
  }
}

export function endBetEvent(result) {
  Log({
    message: "停止投注ws事件",
    data: result,
  });
  const { desk_id } = result;
  const { deskInfo, hotRoadList } = store.getState().bet;
  const deskId = (history.location.pathname.match(/\/(\d+)/) || [])[1];
  store.dispatch(removeRecommendRoadByDeskId(desk_id));

  if (Number(deskId) === desk_id) {
    store.dispatch(setDeskInfo({ ...deskInfo, stop_bet_at: 0, TimingBet: 0 }));
  }

  Communicator.action(`quickBetStop_${desk_id}`, {});
  if (hotRoadList.map((hotRoad) => hotRoad.desk_id).includes(desk_id)) {
    store.dispatch(getHotRoadList({}));
  }
}

// 收取牌局结果的ws事件
export async function onGameResult(result) {
  Log({
    message: "收取牌局结果的ws事件",
    data: result,
  });
  const { desk_no, game_result, b_point, p_point, b_cards, p_cards, desk_id } = result;
  const { desk_no: deskNo } = store.getState().bet.deskInfo;
  const { quickBetDeskNos } = store.getState().config;

  const temp_cards = [p_cards[2], ...p_cards.slice(0, 2)];

  if (quickBetDeskNos.includes(desk_no)) {
    // 刷新余额
    store.dispatch(getBalance()).catch(() => {
      console.log("访客没有余额");
    });

    store.dispatch(setQuickBetDeskNos(quickBetDeskNos.filter((deskNo) => deskNo !== desk_no)));
  }

  if (desk_no === deskNo) {
    const gameResult = {
      desk_no,
      desk_id,
      game_result,
      b_point,
      p_point,
      b_cards: b_cards?.filter((item) => !!item),
      p_cards: temp_cards?.filter((item) => !!item),
    };
    store.dispatch(setGameResult(gameResult));
    await delay(10);
    store.dispatch(setIsLottery(true));
  }
}

// 玩家登录被挤下线
export async function outLogin(data) {
  await delay(2000);

  console.log("outLoginoutLogin", data);
  Log({
    message: "收取玩家登录被挤下线的ws事件",
    data: data,
  });
  const { player_id, access_token } = store.getState().auth;

  if (player_id === data.player_id && isLogin() && access_token === data.access_token) {
    message.error({
      message: t("errMsgs:yourAccountAlreadyLoginOtherSide"),
      onAnimationend: () =>
        store
          .dispatch(logout())
          .then(() => {})
          .finally(() => {
            window.location.href = "/auth";
          }),
    });
  }
}

// 桌台结果统计
export function gameTypeStatistics(data) {
  console.log(data, "获取最新的开奖统计------gameTypeStatistics");
  const { pathname } = history.location;
  const { deskInfo } = store.getState().bet;
  const { deskInfos } = store.getState().multi;
  const { desk_id } = data;

  if (pathname.includes("multiBet")) {
    const index = deskInfos.findIndex((desk) => desk.desk_id === desk_id);
    const tempDeskInfos = [...deskInfos];
    if (index !== -1) {
      tempDeskInfos[index] = {
        ...tempDeskInfos[index],
        game_result_statistics: _.omit(data, ["desk_id"]),
      };
      store.dispatch(setDeskInfos(tempDeskInfos));
    }
  } else {
    const deskId = history.location.pathname.match(/\/(\d+)/)[1];
    if (Number(deskId) === desk_id) {
      store.dispatch(
        setDeskInfo({
          ...deskInfo,
          game_result_statistics: _.omit(data, ["desk_id"]),
        })
      );
    }
  }
}

/**
 * 玩家进入台桌
 * @param playerInfo
 */
export function playerIntoDesk(playerInfo: DeskPlayerInfoParams) {
  const { account } = store.getState().auth;
  const { deskPlayers, deskBetInfo } = store.getState().luxury;
  const { openLuxury } = store.getState().config
  const deskId = history.location.pathname.match(/\/(\d+)/)[1];
  const { player_id, player_account, balance, seat_no, desk_id, nickname, currency_id } = playerInfo;

  if (Number(deskId) !== desk_id) return;

  console.log(playerInfo, "有新玩家进入桌台");

  const index = seatNos.indexOf(seat_no);

  // 是自己不用做处理
  if (account === player_account || index === -1) return;

  // 座位已被占
  if ((deskPlayers || []).find((player) => player.seat_no === seat_no)) return;

  // 如果玩家进桌 清除桌上筹码
  // store.dispatch(resetChipsStack(index));

  // 是否已有旁注
  const sideBetExist = (deskPlayers || []).find((player) => player.seat_no >= 7);

  // 已有旁注不处理
  if (sideBetExist && seat_no >= 7) return;

  store.dispatch(
    setDeskPlayers([
      ...deskPlayers,
      {
        player_id,
        player_account,
        seat_no,
        balance,
        nickname,
        currency_id
      },
    ])
  );

  deskBetInfo?.forEach((betInfo) => {
    const { bet_amount, player_account: playerAccount, seat_no: seatNo } = betInfo;

    // 座位号不为自己的
    if (player_account === playerAccount && !!Number(bet_amount)) {
      addLuxuryChips(_.omit({ ...betInfo, seat_no }, ["desk_id", "player_id"]));
    }
  });
}

/**
 * 玩家离开台桌
 * @param playerInfo
 */
export function playerLeaveDesk(playerInfo: Omit<DeskPlayerInfoParams, "balance">) {
  const deskId = history.location.pathname.match(/\/(\d+)/)[1];

  const { deskPlayers } = store.getState().luxury;
  const { player_id, desk_id, player_account } = playerInfo;

  if (Number(deskId) !== desk_id) return;

  const player = deskPlayers.find((player) => player.player_account === player_account);

  const { seat_no = 0 } = player || {};

  if (seat_no) {
    store.dispatch(resetChipsStack(seatNos.indexOf(Math.min(seat_no, 7))));
  }

  const players = deskPlayers?.filter((player) => Number(player.player_id) !== Number(player_id));
  store.dispatch(setDeskPlayers(players));

  // const index = deskPlayers.findIndex((player) => player.player_id === player_id);

  // 如果玩家在这一桌投注 并离开了 这一桌筹码清空
  // if (history.location.pathname.includes("luxuryBet") && index !== -1) {
  //   const { seat_no } = deskPlayers[index];
  //   const leaveIndex = seatNos.indexOf(Math.min(seat_no, 7));
  //   store.dispatch(resetChipsStack(leaveIndex));
  // }
}

/**
 * 玩家未下注踢出
 */
export function playerOutDesk(data) {
  const { player_id } = data;
  const { player_id: playerId } = store.getState().auth;

  if (Number(player_id) !== Number(playerId)) return;
  console.log(data, "ws事件：玩家长时间没下注踢出桌台", new Date());
  history.push("/tablePick");
  message.error(t("game:KickedOutTableDueToLongTimeNoBetting"));
}

/**
 * 多台玩家未下注踢出
 */
export function playerOutMultiDesk(data) {
  console.log(data, "玩家被踢出桌台");
  const { player_id, desk_id } = data;
  const { player_id: playerId, isVisitor, account } = store.getState().auth;
  const { multiDeskConfig } = store.getState().config;

  const { currentDesk, deskInfos } = store.getState().multi;

  if (Number(player_id) !== Number(playerId)) return;

  if (isVisitor) {
    const index = currentDesk.findIndex((desk) => Number(desk) === Number(desk_id));

    if (index !== -1) {
      const tempCurrentDesk = [...currentDesk];
      const tempDeskInfos = [...deskInfos];
      tempCurrentDesk[index] = 0;
      tempDeskInfos[index] = {} as any;
      store.dispatch(setCurrentDesk(tempCurrentDesk));
      store.dispatch(setDeskInfos(tempDeskInfos));
    }
  } else {
    const tempMultiDeskConfig = { ...multiDeskConfig };
    const tempCurrentDesk = tempMultiDeskConfig[account] || [0, 0, 0, 0];
    const filterDesk = tempCurrentDesk.map((deskId) => (deskId === desk_id ? 0 : deskId));
    tempMultiDeskConfig[account] = filterDesk;
    store.dispatch(setMultiDeskConfig(tempMultiDeskConfig));
  }

  message.error(t("game:KickedOutTableDueToLongTimeNoBetting"));
}

/**
 * 投手端代客投注
 * @param data 代客投注内容Array
 * @returns
 */
export function betByOwnWoker(data: { bet_by_own_worker_orders: DeskBettingsParams[] }) {
  const { bet_by_own_worker_orders: betInfos } = data;
  Log({
    message: "投手端待客投注事件",
    data: betInfos,
  });

  const deskId = history.location.pathname.match(/\/(\d+)/)[1];
  const { account } = store.getState().auth;

  if (Number(betInfos[0].desk_id) !== Number(deskId)) return;

  const { deskBetInfo } = store.getState().luxury;
  const {
    deskInfo: { desk_no, game_mod },
  } = store.getState().bet;
  const { currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;

  store.dispatch(
    setDeskBetInfo([
      ...deskBetInfo,
      ...betInfos.filter(item => game_mod !== 3 || item.currency_id === currentTradeType?.currency_type).map((betInfo) => ({
        player_account: betInfo.player_account,
        seat_no: betInfo.seat_no,
        game_type: betInfo.game_type,
        bet_amount: Number(betInfo.bet_amount),
        nickname: betInfo.nickname || "",
        currency_id: betInfo.currency_id,
      })),
    ])
  );

  // 刷新投注统计 防止重复推送
  store.dispatch(getDeskBetInfo({ desk_id: deskId }));

  const ownBetInfos = betInfos.filter((betInfo) => betInfo.player_account === account);

  // 如果是玩家自己
  if (ownBetInfos.length) {
    message.success(`您有${ownBetInfos.length}笔代客投注已成功`);

    store.dispatch(
      setLastBetContent([
        {
          desk_no,
          game_type: betInfos[0].game_type,
          bet_amount: Number(betInfos[0].bet_amount),
          game_mod,
        },
      ])
    );

    const { balance } = store.getState().auth;
    store.dispatch(
      setBalance(
        CALC.subtract(
          Number(balance),
          ownBetInfos.reduce((prev, next) => prev + Number(next.bet_amount), 0)
        )
      )
    );
  }
}

/**
 * 添加到快捷投注里面
 * @param betInfo
 */
function addToQuickBetInfo(betInfo: DeskBettingsParams) {
  const { currentTradeType } = store.getState().auth;
  const {
    quickBet,
    quickBet: { deskNo, player_bet_info, deskId },
  } = store.getState().luxury;
  const isKRW = currentTradeType?.currency_type === 3;
  const { desk_no, currency_id, player_account, seat_no, bet_amount, nickname, game_type } = betInfo;

  if (desk_no === deskNo) {
    store.dispatch(
      setQuickBet({
        ...quickBet,
        player_bet_info: [
          ...player_bet_info,
          {
            desk_id: deskId,
            player_account,
            seat_no,
            game_type,
            bet_amount,
            nickname,
            currency_id,
          },
        ],
      })
    );
  }
}

/**
 * 玩家下注事件
 * @param betInfo
 */
export function playerBetInfo(betInfo: DeskBettingsParams) {
  Log({
    message: "有新玩家在豪华桌进行投注",
    data: betInfo,
  });
  const { deskBetInfo, deskPlayers } = store.getState().luxury;
  const { account, currentTradeType } = store.getState().auth;

  const isKRW = currentTradeType?.currency_type === 3;
  const {
    deskInfo: { desk_no: deskNo, game_mod },
  } = store.getState().bet;
  const { quickBet } = store.getState().luxury
  const pathname = history.location.pathname;
  const { seat_no, player_account, game_type, bet_amount, desk_no, nickname, currency_id } = betInfo;

  if (deskNo !== desk_no && quickBet.deskNo !== desk_no) return;
  if (game_mod !== 3 || store.getState().auth.currentTradeType?.currency_type === currency_id) {
    // 有玩家投注，更新玩家的余额
    let deskPlayersArr = deskPlayers?.map((item) => {
      if (item.player_account === player_account && account === player_account) {
        return Object.assign({}, item, {
          balance: Number(item.balance || 0) - Number(bet_amount || 0) * 100,
        });
      }
      return { ...item };
    });
    store.dispatch(setDeskPlayers(deskPlayersArr));
    addToQuickBetInfo(betInfo);
    store.dispatch(
      setDeskBetInfo([
        ...deskBetInfo,
        {
          player_account,
          seat_no,
          game_type,
          bet_amount,
          currency_id,
          nickname,
        },
      ])
    );
    if (pathname.includes("luxuryBet")) {
      // CALC.multiply(Number(next.bet_amount), getBeishu(isKRW, next.currency_id));
      // 没有分配到座位号的不统计
      if (account !== player_account && deskPlayers.find((player) => player.player_account === player_account)) {
        addLuxuryChips({
          player_account,
          seat_no,
          game_type,
          bet_amount: CALC.multiply(Number(bet_amount), getBeishu(isKRW, currency_id)),
        });
      }
    }
  }
}

export function addLuxuryChips(deskBetInfo: Omit<DeskBettingsParams, "desk_no" | "nickname">) {
  const { game_type, bet_amount, seat_no } = deskBetInfo;
  const { chipsStack } = store.getState().luxury;

  const tempChipsStack = [...chipsStack];
  const index = seatNos.indexOf(Math.min(seat_no, 7));

  // 投注类型 庄闲和
  const betType = betNumberMaps[game_type];
  // 筹码拆分 600 = 500 + 100
  const chipArrs = sumNumberPart(getNumberPart(Number(bet_amount)));

  // 计算最新的筹码堆配置
  const newChipArrs = [...tempChipsStack[index][betType], ...chipArrs];
  // setChipsStack desk chipStack
  store.dispatch(
    setChipsStack({
      desk: index,
      chipStack: {
        ...tempChipsStack[index],
        [betType]: newChipArrs,
      },
    })
  );
}

// 从筹码堆中移除
export function removeFromChipStack(rejectBetInfo: RejectedOrderDetail) {
  const { game_type, bet_amount, seat_no, currency_id } = rejectBetInfo;
  const { chipsStack } = store.getState().luxury;
  const { currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;

  const chipsUnit = getChipsUnit();

  const tempChipsStack = { ...chipsStack };
  const index = seatNos.indexOf(Math.min(seat_no, 7));

  // 投注类型 庄闲和
  const betType = betNumberMaps[game_type];

  // 当前投注类型投注金额
  let total = tempChipsStack[index][betType].reduce((prev: number, next: number) => prev + chipsUnit[next], 0);

  total -= CALC.multiply(Number(bet_amount), getBeishu(isKRW, currency_id || 1));

  // 筹码拆分 600 = 500 + 100
  const chipArrs = sumNumberPart(getNumberPart(total));

  store.dispatch(
    setChipsStack({
      desk: index,
      chipStack: {
        ...tempChipsStack[index],
        [betType]: chipArrs,
      },
    })
  );
}

/**
 * 好路推荐事件
 * @param betInfo
 */
export async function addHotRoad(data: HotLoadEventParams) {
  Log({
    message: "有新好路推荐好路推荐",
    data: data,
  });
  const { hot_load_type, history_result, stop_bet_at, desk_id, desk_no, game_no, game_mod, TimingBet } = data;
  const deskId = history.location.pathname.match(/\/(\d+)/) ? history.location.pathname.match(/\/(\d+)/)[1] : 0;

  // 只接受快速模式的推送
  if (game_mod !== GameMod.FastGame && game_mod !== GameMod.SmallBet) return;
  // 好路推荐不计算当前桌台
  if (Number(deskId) === desk_id) return;

  try {
    const data = await IStorage.getItem("roadTipMap");
    const hotRoads = isEmpty(data) ? hotRoadRecommend : data;
    console.log(hotRoads, "好路推荐好路推荐好路推荐好路推荐好路推荐", isEmpty(data), data);

    const types = hot_load_type
      .slice(0, -1)
      .split(",")
      .filter((type) => hotRoads?.find((hotRoad) => hotRoad.hotType === type)?.checked);
    console.log(types, "好路类型好路类型好路类型好路类型好路类型");

    if (types.length) {
      types?.forEach((type) =>
        store.dispatch(
          addToRecommendRoad({
            id: getUuid(),
            type,
            deskNo: desk_no,
            deskId: desk_id,
            stop_bet_at,
            TimingBet,
            history_result,
            game_mod: game_mod || 2,
            game_no,
          })
        )
      );
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * 好路推荐事件 桌子里面的
 * @param betInfo
 */
export async function addHotRoadNew(data: HotLoadEventParams) {
  Log({
    message: "有新好路推荐好路推荐",
    data: data,
  });
  const { hot_load_type, history_result, stop_bet_at, desk_id, desk_no, game_no, game_mod, TimingBet } = data;
  const deskId = history.location.pathname.match(/\/(\d+)/) ? history.location.pathname.match(/\/(\d+)/)[1] : 0;

  // 只接受快速模式的推送
  if (game_mod !== GameMod.FastGame && game_mod !== GameMod.SmallBet) return;
  // 好路推荐不计算当前桌台
  if (Number(deskId) === desk_id) return;

  store.dispatch(getHotRoadList({}));
}

/**
 * 枪手拒绝处理注单
 * @param data
 */
export function rejectBetOrders(data: RejectedOrderParams) {
  console.log(data, "RejectedOrderEventRejectedOrderEventRejectedOrderEvent");
  const { rejected_orders } = data;
  const { deskBetInfo, deskPlayers } = store.getState().luxury;
  const { account } = store.getState().auth;
  const {
    deskInfo: { desk_id },
  } = store.getState().bet;

  if (desk_id !== rejected_orders[0].desk_id) return;

  const tempDeskBetInfo = [...deskBetInfo];
  const tempDeskPlayers = [...deskPlayers];

  const playerIndex = tempDeskPlayers.findIndex(
    (deskPlayer) => deskPlayer.player_account === rejected_orders[0].player_account
  );

  rejected_orders.forEach((rejectOrder) => {
    const { game_type, player_account, bet_amount, seat_no } = rejectOrder;

    const index = tempDeskBetInfo.findIndex(
      (betInfo) =>
        betInfo.game_type === game_type &&
        betInfo.player_account === player_account &&
        Number(betInfo.bet_amount) === Number(bet_amount) &&
        betInfo.seat_no === seat_no
    );

    if (index !== -1) {
      tempDeskBetInfo.splice(index, 1);

      // 不是当前玩家 从桌台移除
      if (player_account !== account) {
        history.location.pathname.includes("/luxuryBet") && removeFromChipStack(rejectOrder);
      }
      // else {
      //   const { balance } = store.getState().auth;
      //   store.dispatch(setBalance(CALC.add(Number(balance), Number(bet_amount))));
      // }
    }

    if (playerIndex !== -1) {
      tempDeskPlayers[playerIndex] = {
        ...tempDeskPlayers[playerIndex],
        balance: CALC.add(Number(tempDeskPlayers[playerIndex].balance), Number(bet_amount) * 100),
      };
    }
  });

  if (rejected_orders.find((rejectOrder) => rejectOrder.player_account === account)) {
    store.dispatch(getBalance()).catch(() => {
      console.log("访客没有余额");
    });
    !store.getState().auth.isVisitor && store.dispatch(getLastBet({ desk_id: Number(desk_id) }));

    message.error(t("game:betOrderCanceled"));
  }

  restoreChipsAfterRejectOrders(data);

  // 台桌上玩家余额也要更新
  store.dispatch(setDeskPlayers(tempDeskPlayers));
  store.dispatch(setDeskBetInfo(tempDeskBetInfo));
}

/**
 * 多台无拒绝
 * @param data
 * @returns
 */
export function rejectMultiBetOrders(data: RejectedOrderParams) {
  let { rejected_orders } = data;
  let { currentDesk, deskInfos } = store.getState().multi;
  let { account } = store.getState().auth;

  let tempDeskInfos = [...deskInfos];

  const index = currentDesk.findIndex((deskId) => deskId === rejected_orders[0].desk_id);

  if (index === -1) return;

  let betInfos = [...tempDeskInfos[index].player_bet_info];

  rejected_orders.forEach((rejectOrder) => {
    const { player_account, bet_amount, game_type } = rejectOrder;

    betInfos = betInfos.filter((betInfo) => {
      const isGameType = betInfo.game_type === game_type;
      const isPlayerAccount = betInfo.player_account === player_account;

      const flag = isGameType && isPlayerAccount ? false : true;
      return flag;
    });

    // const targetIndex = betInfos.findIndex(
    //   (betInfo) =>
    //     betInfo.game_type === game_type &&
    //     betInfo.player_account === player_account &&
    //     Number(betInfo.bet_amount) === Number(bet_amount)
    // );

    // if (targetIndex !== -1) {
    //   betInfos.splice(targetIndex, 1);
    // }

    if (account === player_account) {
      // const { balance } = store.getState().auth;
      // store.dispatch(setBalance(CALC.add(Number(balance), Number(bet_amount))));
      store.dispatch(getBalance()).catch(() => {
        console.log("访客没有余额");
      });
    }
  });

  console.log(betInfos, "betInfosbetInfos");
  tempDeskInfos[index] = {
    ...tempDeskInfos[index],
    player_bet_info: betInfos,
  };

  store.dispatch(setDeskInfos(tempDeskInfos));
}

export function DealWithBasicApply(data: ApplyServiceParams) {
  const { account } = store.getState().auth;
  const { deskInfo } = store.getState().bet;
  const { openLuxury } = store.getState().config
  const betPreference = localStorage.getItem("betPreference");

  console.log("处理专属服务", data);
  const { member_account, apply_status, desk_id } = data;
  const inGameDesk = history.location.pathname.includes("/luxuryBet") || history.location.pathname.includes("/classic");

  if (account !== member_account || !isLogin()) return;
  if (apply_status === 2) {
    message.success(t("game:ExclusiveServiceApplicationSuccessful"));
    // store.dispatch(
    //   setDeskInfo({
    //     ...deskInfo,
    //     apply_basic_ing: ApplyServiceStatus.Passed,
    //   })
    // );
    store.dispatch(setSpecialServiceFlag(false));

    if (history.location.pathname.includes("/luxuryBet") || (openLuxury && !!Number(betPreference))) {
      // Communicator.action("refreshTraditionDesk", {});'
      enterLuxuryDesk(desk_id);
    } else {
      // 申请通过，玩家进入桌子
      enterClassicDesk(desk_id);
    }
  }

  if (apply_status === 3) {
    message.error(t("game:ExclusiveServiceApplicationFail"));
    if (inGameDesk) {
      store.dispatch(
        setDeskInfo({
          ...deskInfo,
          apply_basic_ing: ApplyServiceStatus.Failed,
        })
      );
    }

    store.dispatch(setSpecialServiceFlag(true));
  }
}

// 多台专属服务申请
export function DealWithBasicMultiApply(data: ApplyServiceParams) {
  const { deskInfos } = store.getState().multi;
  const { account } = store.getState().auth;
  console.log("处理专属服务===多台专属服务申请", data);
  const { member_account, apply_status, desk_id } = data;

  if (member_account !== account) return;

  const index = deskInfos.findIndex((desk) => desk.desk_id === desk_id);

  if (index === -1) return;
  const tempDeskInfos = { ...deskInfos };
  const tempDeskInfo = { ...deskInfos[index] };

  if (apply_status === 2) {
    tempDeskInfos[index] = {
      ...tempDeskInfo,
      apply_basic_ing: ApplyServiceStatus.Passed,
    };
    Communicator.action(`update_apply_status_${desk_id}`, { apply_status });
    store.dispatch(setSpecialServiceFlag(false));
    store.dispatch(setDeskInfos(tempDeskInfos));
  }

  if (apply_status === 3) {
    tempDeskInfos[index] = {
      ...tempDeskInfo,
      apply_basic_ing: ApplyServiceStatus.NotApply,
    };
    Communicator.action(`update_apply_status_${desk_id}`, { apply_status });
    store.dispatch(setSpecialServiceFlag(true));
    store.dispatch(setDeskInfos(tempDeskInfos));
  }
}

/**
 * 多台投注 玩家下注处理
 * @param betInfo
 */
export function playerBetMultiInfo(betInfo: DeskBettingsParams) {
  Log({
    message: "有新玩家在多台进行投注",
    data: betInfo,
  });
  const { deskInfos } = store.getState().multi;
  const { account } = store.getState().auth;

  const { seat_no, player_account, game_type, bet_amount, desk_no, currency_id, nickname } = betInfo;

  const index = deskInfos.findIndex((desk) => desk.desk_no === desk_no);
  if (index === -1) return;

  const { currentTradeType } = store.getState().auth;
  const isKRW = currentTradeType?.currency_type === 3;

  const tempDeskInfos = [...deskInfos];
  tempDeskInfos[index] = {
    ...tempDeskInfos[index],
    player_bet_info: [
      ...tempDeskInfos[index].player_bet_info,
      {
        player_account,
        seat_no,
        game_type,
        nickname,
        currency_id,
        bet_amount,
      },
    ],
  };

  store.dispatch(setDeskInfos(tempDeskInfos));
}

/**
 * 对于桌台状态的变化，判断自己所在的桌台，如果是维护 和包桌，自动退出桌台
 */
export async function DeskStatusChange(data) {
  // 处理在赌桌的状态更新

  console.log(data, "处理在赌桌的状态更新处理在赌桌的状态更新");
  const { desk_id, desk_no, desk_status, vip_game_mod } = data as any;

  const {
    deskInfo,
    deskInfo: { seat_no },
    changeDesksInfo,
    lobbyDesksInfo
  } = store.getState().bet;

  const isLuxury = history.location.pathname.includes("luxuryBet");

  console.log("ws事件，桌台状态发生变化", data);
  // 对于桌台状态的变化，判断自己所在的桌台，如果是维护 和包桌，自动退出桌台
  if (history.location.pathname.includes("/luxuryBet") || history.location.pathname.includes("/classic")) {
    // 单桌
    const deskId = history.location.pathname.match(/\/(\d+)/) ? history.location.pathname.match(/\/(\d+)/)[1] : 0;
    if (desk_id?.toString() !== deskId?.toString()) {
      return false;
    }
    console.log(5555, desk_status, DeskStatus.MaintainDesk, desk_status === DeskStatus.MaintainDesk);
    if (desk_status === DeskStatus.MaintainDesk) {
      message.error(t("game:deskOnMaintainWillBeOut"));
      history.push("/tablePick");
    }

    // 换靴
    if (desk_status === DeskStatus.ChangingCardDesk) {
      message.error({ message: t("game:changingShoe"), duration: 5000 });
      store.dispatch(
        setDeskInfo({
          ...deskInfo,
          game_no: "",
          stop_bet_at: 0,
          TimingBet: 0,
          history_result: "",
          game_result_statistics: {
            bank: 0,
            bpair: 0,
            player: 0,
            ppair: 0,
            game_count: 0,
          },
        })
      );

      store.dispatch(resetChipsStack(seatNos.indexOf(Number(Math.min(seat_no || 7, 7)) || 1)));
      !isLuxury && store.dispatch(resetChipsStackCurrent());
    }

    if (vip_game_mod === 1) {
      message.error(t("game:deskOnVipWillBeOut"));
      history.push("/tablePick");
    }
  } else if (history.location.pathname.includes("/multiBet")) {
    // 当前多台选择的桌台id
    // let currentDeskArr = isVisitor ? [...store.getState().multi.currentDesk] : multiDeskConfig[account] || [0, 0, 0, 0];
    // let flag = false;

    Communicator.action(`desk_status_change_${desk_id}`, data);
  } else {
    const changeIndex = changeDesksInfo.desk_vo?.findIndex(item => item.desk_id === desk_id && item.desk_no === desk_no)
    if (changeIndex !== undefined && changeIndex !== -1) {
      const tempDeskVo = changeDesksInfo.desk_vo.map((item, index) => {
        if (index === changeIndex) {
          return {
            ...item,
            desk_status
          }
        }
        return item
      })
      store.dispatch(setChangeDesksInfo({
        ...changeDesksInfo,
        desk_vo: tempDeskVo
      }))
    }
    const lIndex = lobbyDesksInfo.desk_vo?.findIndex(item => item.desk_id === desk_id && item.desk_no === desk_no)
    if (lIndex !== undefined && lIndex !== -1) {
      const tempDeskVo = lobbyDesksInfo.desk_vo.map((item, index) => {
        if (index === lIndex) {
          return {
            ...item,
            desk_status
          }
        }
        return item
      })
      store.dispatch(setLobbyDesksInfo({
        ...lobbyDesksInfo,
        desk_vo: tempDeskVo
      }))
    }
  }
}

// 接受飞牌撤回玩家本轮投注
export function acceptedFreeGame(data: RefusedFreeGameParams) {
  const { desk_no, player_account } = data;

  const { account } = store.getState().auth;
  const { deskBetInfo, deskPlayers } = store.getState().luxury;

  const {
    deskInfo: { desk_no: deskNo, seat_no, desk_id },
  } = store.getState().bet;
  const isLuxury = history.location.pathname.includes("luxuryBet");

  if (desk_no !== deskNo) return;

  const betAmount = deskBetInfo
    .filter((betInfo) => betInfo.player_account === player_account)
    .map((bet) => Number(bet.bet_amount))
    .reduce((prev, next) => prev + next, 0);

  if (isLuxury) {
    store.dispatch(
      setDeskPlayers(
        deskPlayers.map((player) => {
          if (player.player_account === player_account) {
            return { ...player, balance: Number(player.balance) + betAmount * 100 };
          } else {
            return player;
          }
        })
      )
    );

    if (player_account === account) {
      store.dispatch(getBalance()).catch(() => {
        console.log("访客没有余额");
      });

      !store.getState().auth.isVisitor && store.dispatch(getLastBet({ desk_id: Number(desk_id) }));

      store.dispatch(resetChipsStack(seatNos.indexOf(Number(Math.min(seat_no || 7, 7)))));
    } else {
      const player = deskPlayers.find((player) => player.player_account === player_account);

      if (player) {
        store.dispatch(resetChipsStack(seatNos.indexOf(player.seat_no)));
      }
    }
  } else {
    if (player_account === account) {
      store.dispatch(resetChipsStackCurrent());
      // 刷新余额
      store.dispatch(getBalance()).catch(() => {
        console.log("访客没有余额");
      });
      !store.getState().auth.isVisitor && store.dispatch(getLastBet({ desk_id: Number(desk_id) }));
    }
  }

  store.dispatch(setDeskBetInfo(deskBetInfo.filter((betInfo) => betInfo.player_account !== player_account)));

  if (player_account === account) {
    message.success(t("game:youSkipApplySuccessful"));
  } else {
    message.success(`${player_account} 进行了飞牌`);
  }
}

export function refuseFreeGame(data: RefusedFreeGameParams) {
  const { desk_no, player_id } = data;

  const { player_id: playerId } = store.getState().auth;
  const {
    deskInfo: { desk_no: deskNo },
  } = store.getState().bet;

  if (desk_no === deskNo && playerId === player_id) {
    message.success(t("game:youSkipApplyeRefused"));
  }
}

export function betterQuit(data: BetterEndWorkParams) {
  console.log(data, "枪手端枪手收工枪手端枪手收工");
  const { player_id: playerId, desk_no: deskNo } = data;
  const { player_id } = store.getState().auth;
  const {
    deskInfo: { desk_no },
  } = store.getState().bet;

  if (playerId === player_id && deskNo === desk_no) {
    try {
      message.success({
        message: "枪手收工",
        onAnimationend: async () => {
          const { msg } = await quitTraditionMod({ desk_no, _isNeedLoading: true });

          if (msg === "success") {
            history.push("/tablePick");
          }
        },
      });
    } catch (err) {}
  }
}

/**
 * 玩家撤注事件
 * @param data
 * @returns
 */
export function revokeBettings(data: RevokeBettingsParams) {
  const isLuxury = history.location.pathname.includes("luxuryBet");
  const {
    deskInfo: { desk_no },
  } = store.getState().bet;
  const { account } = store.getState().auth;
  const { player_account, desk_no: deskNo } = data;

  if (desk_no !== deskNo || account === player_account) return;

  const { deskBetInfo, deskPlayers } = store.getState().luxury;
  const revokeAmount = deskBetInfo
    ?.filter((betInfo) => betInfo.player_account === player_account)
    ?.reduce((prev, next) => Number(prev) + Number(next.bet_amount), 0);

  store.dispatch(setDeskBetInfo((deskBetInfo || []).filter((betInfo) => betInfo.player_account !== player_account)));

  // 如果是豪华投注 要把其他视角玩家的余额加回来
  if (isLuxury) {
    const index = deskPlayers.findIndex((player) => player.player_account === player_account);

    if (index !== -1) {
      const { seat_no } = deskPlayers[index];

      store.dispatch(resetChipsStack(seatNos.indexOf(Number(Math.min(seat_no || 7, 7)) || 1)));

      store.dispatch(
        setDeskPlayers(
          deskPlayers.map((player) => {
            if (player.player_account === player_account) {
              return {
                ...player,
                balance: Number(player.balance) + revokeAmount * 100,
              };
            } else {
              return player;
            }
          })
        )
      );
    }
  }
}

// 多台撤注
export function revokeBettingsMulti(data: RevokeBettingsParams) {
  console.log("多台撤注", data);
  const { player_account, desk_no: deskNo } = data;
  const { deskInfos } = store.getState().multi;
  const { account } = store.getState().auth;

  const deskIndex = deskInfos?.findIndex((deskInfo) => deskInfo.desk_no === deskNo);

  if (deskIndex === -1 || player_account === account) return;

  const tempDeskInfos = [...deskInfos];
  const tempDeskInfo = { ...tempDeskInfos[deskIndex] };

  const newBetInfo = tempDeskInfo.player_bet_info?.filter((betInfo) => betInfo.player_account !== player_account);

  tempDeskInfos[deskIndex] = {
    ...tempDeskInfo,
    player_bet_info: newBetInfo,
  };

  store.dispatch(setDeskInfos(tempDeskInfos));
}

// 玩家余额发生变化
export function PlayerBalanceChange(data) {
  console.log("玩家余额发生变化玩家余额发生变化", data);
  const { player_id, balance } = data;
  const { player_id: playerId } = store.getState().auth;
  const { deskPlayers } = store.getState().luxury;

  if (playerId !== player_id) {
    let deskPlayersArr = deskPlayers?.map((item) => {
      if (item.player_id === player_id) {
        return Object.assign({}, item, {
          balance: CALC.multiply(Number(balance), 1),
        });
      }
      return { ...item };
    });
    store.dispatch(setDeskPlayers(deskPlayersArr));
  }
}

/** 枪手处理玩家申请换桌 */
export function betterDealChangeDesk(data: DealChangeDeskParams) {
  console.log(
    data,
    "枪手处理玩家申请换桌枪手处理玩家申请换桌枪手处理玩家申请换桌枪手处理玩家申请换桌枪手处理玩家申请换桌"
  );

  if (!isLogin()) return;
  const { player_id: playerId } = store.getState().auth;
  const { state, new_desk_id, old_desk_id, is_change_club, old_desk_no, new_desk_no, player_id } = data;

  if (playerId !== player_id) return;

  store.dispatch(setShowWaitConfirm(false));

  if (state === 2) {
    message.error(t("SwitchDesk:changeDeskDenied"));
  } else {
    // 在游戏桌子里面
    message.success({
      message: t("SwitchDesk:applyChangeSuccess", { deskNo: new_desk_no }),
      onAnimationend: async () =>
        quitEnterTradition({
          is_change_club,
          old_desk_no,
          old_desk_id,
          new_desk_id,
          new_desk_no,
        }),
    });
  }
}

/**
 * 枪手申请换桌
 * @param data
 */
export function betterApplyChangeDesk(data: ApplyChangeDeskParams) {
  console.log(data, "ApplyChangeDeskParamsApplyChangeDeskParams");
  const { player_id: playerId } = store.getState().auth;
  const { is_change_club, new_club_id, new_desk_no, new_desk_id, old_desk_id, old_desk_no, old_club_id, player_id } =
    data;

  if (playerId === player_id) {
    showBetterApplyChange({
      new_club_id,
      old_club_id,
      new_desk_no,
      new_desk_id,
      old_desk_id,
      is_change_club,
      old_desk_no,
    });
  }
}

export async function setVipEvent(data: { desk_id: number, desk_no: string; is_vip: number }) {
  console.log('包桌状态发生变化')
  const { desk_id, desk_no, is_vip } = data
  const { changeDesksInfo, lobbyDesksInfo } = store.getState().bet
  const { multiDeskConfig } = store.getState().config
  const { account } = store.getState().auth
  const { deskInfos } = store.getState().multi
  console.log(multiDeskConfig)
  const changeIndex = changeDesksInfo.desk_vo?.findIndex(item => item.desk_id === desk_id && item.desk_no === desk_no)
  if (changeIndex !== undefined && changeIndex !== -1) {
    const tempDeskVo = changeDesksInfo.desk_vo.map((item, index) => {
      if (index === changeIndex) {
        return {
          ...item,
          is_vip
        }
      }
      return item
    })
    store.dispatch(setChangeDesksInfo({
      ...changeDesksInfo,
      desk_vo: tempDeskVo
    }))
  }
  const lIndex = lobbyDesksInfo.desk_vo?.findIndex(item => item.desk_id === desk_id && item.desk_no === desk_no)
  if (lIndex !== undefined && lIndex !== -1) {
    const tempDeskVo = lobbyDesksInfo.desk_vo.map((item, index) => {
      if (index === lIndex) {
        return {
          ...item,
          is_vip
        }
      }
      return item
    })
    store.dispatch(setLobbyDesksInfo({
      ...lobbyDesksInfo,
      desk_vo: tempDeskVo
    }))
  }
  if (history.location.pathname.includes("/luxuryBet") || history.location.pathname.includes("/classic")) {
    const deskId = history.location.pathname.match(/\/(\d+)/) ? history.location.pathname.match(/\/(\d+)/)[1] : 0;
    if (desk_id?.toString() !== deskId?.toString()) {
      return false;
    }
    if (is_vip === 1) {
      const leaveDeskSuccess = await leaveDesk(desk_id)
      if (leaveDeskSuccess) {
        history.push("/tablePick");
      }
    }
  } else if (history.location.pathname.includes("/multiBet")) {
    const cIndex = multiDeskConfig[account]?.findIndex(deskId => String(deskId) === String(desk_id))
    if (cIndex !== undefined && cIndex !== -1 && is_vip === 1) {
      const tempCurrentDesk = [...multiDeskConfig[account]]
      tempCurrentDesk[cIndex] = 0
      store.dispatch(setMultiDeskConfig({
        ...multiDeskConfig,
        [account]: tempCurrentDesk
      }))
    }
  }
}
