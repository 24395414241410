import logoCOD from "@/assets/images/bet/casinos/logoCOD.svg";
import logoHann from "@/assets/images/bet/casinos/logoHann.svg";
import logoOkada from "@/assets/images/bet/casinos/logoOkada.svg";
import logoResort from "@/assets/images/bet/casinos/logoResort.svg";
import logoSolaire from "@/assets/images/bet/casinos/logoSolaire.png";
import table from "@/assets/images/bet/table.svg";
import user from "@/assets/images/bet/user.svg";
import { Solid } from "@/components/common";
import SelectPicker from "@/components/deskPick/selectPicker";
import StatusCard from "@/components/deskPick/statusWrap";
import { DeskStatus, GameMod, PackedDeskStatus } from "@/components/deskPick/types.d";
import { message } from "@/components/Message";
import RoadMap from "@/components/roadCanvas";
import { history } from "@/routers";
import { useAppDispatch, useAppSelector } from "@/store";
import { setIsLottery, setShowTablePick } from "@/store/slices/bet.slice";
import { setMultiDeskConfig } from "@/store/slices/config.slice";
import { setCurrentDesk, setIsLottery as setMultiIsLottery } from "@/store/slices/multi.slice";
import { ApplyServiceStatus } from "@/store/types/bet.d";
import { CALC, Throttle } from "@/utils";
import useEnterGame from "@/utils/hooks/useEnterGame";
import { Communicator, CurrencyType, getLimitsArr, smallKrwLimitChange } from "@/utils/tool";
import { useSetState } from "ahooks";
import classnames from "classnames/bind";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Column, Row } from "../flex";
import { showBaotai, showChangeTradition, showSwitchDesk } from "../Header/Modals";
import { config, smallConfig } from "./config";
import scss from "./index.module.scss";
import theme from "@/config/theme";

const cn = classnames.bind(scss);

interface Props {
  isSmall?: boolean;
}

interface ContainerProps extends Props {
  desk_no: string;
  desk_id: number;
  game_mod: number;
  /** 桌台限红 */
  desk_limits: any[];
  onChoose?: (data) => void;

  [key: string]: any;
}

const Container = styled(Column)<Props>`
  position: relative;
  width: ${({ isSmall }) => `${isSmall ? "382px" : "524px"}`};
  height: ${({ isSmall }) => `${isSmall ? "194px" : "265px"}`};
  background: #171717;
  overflow: hidden;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const TableInfo = styled(Row)<Props>`
  // width: ${({ isSmall }: any) => `${isSmall ? "382px" : "524px"}`};
  width: 100%;
  height: ${({ isSmall }) => `${isSmall ? "66px" : "90px"}`};
  padding: ${({ isSmall }) => `${isSmall ? "0 12px" : "0 15px"}`};
`;

const TableIcon = styled.div<any>`
  width: ${({ isSmall }) => `${isSmall ? "56px" : "72px"}`};
  height: ${({ isSmall }) => `${isSmall ? "42px" : "55px"}`};
  margin: ${({ isSmall }) => `${isSmall ? "0 9px" : "0 15px"}`};
  background: ${(props: any) => `url(${props.src}) no-repeat center`};
  background-size: 100%;
`;

const TableColumn = styled(Column)<Props>`
  height: ${({ isSmall }) => `${isSmall ? "50px" : "65px"}`};
  /* height: 65px; */
  width: ${({ isSmall }) => `${isSmall ? "240px" : "320px"}`};
  color: #faf8f4;
`;

const IconRow = styled(Row)<Props>`
  margin-right: ${({ isSmall }) => `${isSmall ? "10px" : "16px"}`};

  &:nth-of-type(2) {
    margin-right: ${({ isSmall }) => `${isSmall ? "10px" : "20px"}`};
  }

  img {
    width: ${({ isSmall }) => `${isSmall ? "18px" : "22px"}`};
    margin-right: ${({ isSmall }) => `${isSmall ? "6px" : "10px"}`};
  }
`;

const CirlceWrap = ({ banker, player, tie, type }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Solid
          {...{
            width: type ? 20 : 26,
            height: type ? 20 : 26,
            background: theme.bank_color,
            borderRadius: type ? 10 : 13,
            marginRight: type ? 4 : 7,
          }}
          style={{ marginRight: type ? "4px" : "7px" }}
        >
          {t("game:lotteryTypes.banker")}
        </Solid>
        <span style={{ marginRight: type ? "10px" : "16px" }}>{banker}</span>
      </Row>
      <Row>
        <Solid
          {...{
            width: type ? 20 : 26,
            height: type ? 20 : 26,
            background: theme.player_color,
            borderRadius: type ? 10 : 13,
            marginRight: type ? 4 : 7,
          }}
          style={{ marginRight: type ? "4px" : "7px" }}
        >
          {t("game:lotteryTypes.player")}
        </Solid>
        <span style={{ marginRight: type ? "10px" : "16px" }}>{player}</span>
      </Row>
      <Row>
        <Solid
          {...{
            width: type ? 20 : 26,
            height: type ? 20 : 26,
            background: theme.tie_color,
            borderRadius: type ? 10 : 13,
            marginRight: type ? 4 : 7,
          }}
          style={{ marginRight: type ? "4px" : "7px" }}
        >
          {t("game:lotteryTypes.tie")}
        </Solid>
        <span style={{ marginRight: type ? "10px" : "16px" }}>{tie}</span>
      </Row>
    </>
  );
};

const logoMap = new Map([
  [1, logoOkada],
  [2, logoCOD],
  [3, logoHann],
  [4, logoResort],
  [5, logoSolaire],
]);

/**
 * 大厅桌台选择
 * @param param0
 * @returns
 */
const Index: FC<ContainerProps> = (props) => {
  const {
    isSmall,
    game_result_statistics,
    history_result,
    club_id,
    desk_no,
    desk_id,
    desk_status,
    game_mod,
    desk_limits = [],
    players_info,
    status,
    is_vip,
    style,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  /** 多台模式桌的index */
  const { currentDesk, currentChoose } = useAppSelector((state) => state.multi);

  /** 我的限红 */
  const { limit_array, isVisitor, account = "isVisitor", currentTradeType } = useAppSelector((state) => state.auth);
  const {
    deskInfo: { game_mod: currentMod, desk_no: deskNo, apply_basic_ing, club_id: clubId },
  } = useAppSelector((state) => state.bet);

  const { deskBetInfo } = useAppSelector((state) => state.luxury);
  const { locale, multiDeskConfig } = useAppSelector((state) => state.config);

  const [{ showLimit }, setState] = useSetState({ showLimit: false });

  // 进入游戏
  const enterGame = useEnterGame(String(desk_id));
  console.log(isSmall, "isSmallisSmallisSmall");

  const onMouseEnter: any = (e: MouseEvent) => {
    if (deskLimits.length && !isVisitor) setState({ showLimit: true });
  };

  const onMouseLeave: any = (e: MouseEvent) => setState({ showLimit: false });
  const myLimit = useMemo(() => {
    if (game_mod === 3) {
      let arr =
        (Array.isArray(limit_array) ? limit_array : []).find(
          (item) => item.currency === currentTradeType?.currency_type
        )?.limit_array || [];
      return arr;
    }
    return limit_array[0]?.limit_array || [];
  }, [limit_array, game_mod, currentTradeType]);
  // 桌台限红下拉展示
  const deskLimits = useMemo(() => {
    const tempArr =
      game_mod === 3
        ? desk_limits?.find((item) => item.currency === currentTradeType?.currency_type)?.limit_array || []
        : (desk_limits || [])[0]?.limit_array;
    if (game_mod === 3 && currentTradeType?.currency_type === CurrencyType.KRW) {
      return getLimitsArr(smallKrwLimitChange(tempArr || []), smallKrwLimitChange(myLimit));
    }
    return getLimitsArr(tempArr || [], myLimit);
  }, [desk_limits, myLimit, locale]);

  // 当局玩家是否已投注
  const hasBets = useMemo(
    () => deskBetInfo?.filter((betInfo) => betInfo.player_account === account).length > 0,
    [deskBetInfo, account]
  );

  // 多台选择
  const onChoose = (deskId) => {
    // if (isVisitor) {
    //   const temp = [...multiDeskConfig['isVisitor'] || [0, 0, 0, 0]];
    //   temp[currentChoose] = deskId;
    //   // 当前多台的desk_id集合
    //   dispatch(setCurrentDesk(temp));
    //   dispatch(
    //     setMultiDeskConfig({
    //       ...multiDeskConfig,
    //       isVisitor: temp,
    //     })
    //   );
    // } else {
    const tempCurrentDesk = [...(multiDeskConfig[account] || [0, 0, 0, 0])];
    tempCurrentDesk[currentChoose] = deskId;
    dispatch(
      setMultiDeskConfig({
        ...multiDeskConfig,
        [account]: tempCurrentDesk,
      })
    );
    // }

    // 当前桌台在开奖 切换桌台应该关闭开奖动画
    dispatch(setMultiIsLottery({ index: currentChoose, isLottery: 0 }));
    // 重置赌桌上的筹码
    // dispatch(resetChipsStack(currentChoose));
    dispatch(setShowTablePick(false));
    console.log("multiBet", currentChoose);
  };

  const startGame = () => {
    if (status === "MULTIPLE") {
      setTimeout(() => navigate("/multiBet"));
    } else {
      beforeEnter() && enterGame();
    }
  };

  // 进桌之前的检测
  const beforeEnter = (afterValidate?) => {
    const deskId = history.location.pathname.match(/\/(\d+)/) ? history.location.pathname.match(/\/(\d+)/)[1] : 0;

    // 维护
    if ([DeskStatus.MaintainDesk].includes(desk_status)) {
      message.error(t("errMsgs:DeskNotOpen"));
      return false;
    }

    if (
      game_mod === GameMod.FastGame &&
      deskId &&
      (apply_basic_ing === ApplyServiceStatus.Passed || apply_basic_ing === ApplyServiceStatus.Applying)
    ) {
      message.error(t("errMsgs:BasicPlayerCantPlayOtherMod"));
      return false;
    }

    // 包桌
    if (is_vip === PackedDeskStatus.Packed) {
      // 如果是传统模式 且玩家已在传统桌子中
      if (game_mod === GameMod.BasicGame && deskId && apply_basic_ing === ApplyServiceStatus.Passed) {
        showBaotai(afterValidate || enterGame, desk_id, () =>
          showSwitchDesk(club_id, desk_no, desk_id, deskId, club_id !== clubId, clubId, deskNo)
        );
        return false;
      } else {
        showBaotai(afterValidate || enterGame, desk_id);
        return false;
      }
    }

    // 当前处于传统模式 换桌到另一个传统桌子
    if (game_mod === GameMod.BasicGame && deskId && apply_basic_ing === ApplyServiceStatus.Passed) {
      if (hasBets) {
        message.error(t("SwitchDesk:gameInprogressEndCurrent"));
      } else {
        showSwitchDesk(club_id, desk_no, desk_id, deskId, clubId !== club_id);
      }

      //   showChangeTradition(enterGame, desk_no, deskNo);
      return false;
    }

    dispatch(setIsLottery(false));

    return true;
  };

  // 处理进入游戏
  const handleOnClick = () => {
    if (pathname === "/multiBet") {
      beforeEnter(() => onChoose(desk_id)) && onChoose(desk_id);
    } else {
      if (pathname === "/tablePick") {
        startGame();
      } else {
        // dispatch(setShowTablePick(false));
        startGame();
        // 当前桌台在开奖 切换桌台应该关闭开奖动画
      }
    }
  };

  return (
    <Container isSmall={isSmall} style={style} onClick={new Throttle(handleOnClick, 300).run}>
      {/* <TableInfo isSmall={isSmall}>
       <TableIcon {...{ isSmall, src: logoMap.get(club_id) }} />
       <TableColumn justify="space-between" isSmall={isSmall}>
       <Row justify="space-between" style={{ width: "100%" }}>
       <Solid
       {...{
       width: "fit-content",
       height: isSmall ? 18 : 29,
       background: "#717171",
       color: "#fff",
       borderRadius: 100,
       }}
       style={{ cursor: "pointer", padding: isSmall ? "0 8px" : "0 10px" }}
       >
       {game_mod === 1 && t("deskPick:gameModFast")}
       {game_mod === 2 && t("deskPick:gameModTradition")}
       {game_mod === 3 && t("deskPick:gameModPackage")}
       </Solid>
       <Solid
       {...{
       width: "fit-content",
       height: isSmall ? 18 : 29,
       background: "#252822",
       color: "#fff",
       borderRadius: 100,
       }}
       style={{ cursor: "pointer", padding: isSmall ? "0 8px" : "0 10px" }}
       onMouseEnter={onMouseEnter}
       onMouseLeave={onMouseLeave}
       >
       {deskLimits.length && !isVisitor ? deskLimits[0].intervalFmt : t("deskPick:warningMessage")}
       </Solid>
       </Row>

       <Row>
       <IconRow isSmall={isSmall}>
       <img src={table} alt=""/>
       <span>{desk_no}</span>
       </IconRow>

       <IconRow isSmall={isSmall}>
       <img src={user} alt=""/>
       <span>{(players_info || []).length}</span>
       </IconRow>

       <CirlceWrap
       {...{
       banker: game_result_statistics.bank,
       player: game_result_statistics.player,
       tie: game_result_statistics.tie,
       type: isSmall,
       }}
       />
       </Row>
       </TableColumn>
       </TableInfo> */}
      <div className={cn(scss.desk, { small: isSmall })}>
        <img className={scss.desk__logo} src={logoMap.get(club_id)} alt="" />
        <div className={scss.desk__right}>
          <div className={scss.desk__table}>
            <div className={scss.desk__tableType}>
              {game_mod === 1 && t("deskPick:gameModFast")}
              {game_mod === 2 && t("deskPick:gameModTradition")}
              {game_mod === 3 && t("deskPick:gameModSmall")}
            </div>
            <div className={scss.desk__tableCode}>
              <img className={scss.desk__icon} src={table} alt="" />
              <span>{desk_no}</span>
            </div>
          </div>
          <div className={scss.desk__user}>
            <img className={scss.desk__icon} src={user} alt="" />
            <span>{players_info?.length || 0}</span>
          </div>
          <div className={scss.desk__count}>
            {/* <div className={scss.desk__limitType} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              {deskLimits.length ? deskLimits[0].intervalFmt : t("deskPick:warningMessage")}
            </div> */}
            <div className={scss.desk__circleWrap}>
              <span className={scss.desk__banker}>{t("game:lotteryTypes.banker")}</span>
              <span className={scss.desk__countText}>{game_result_statistics.bank}</span>
              <span className={scss.desk__player}>{t("game:lotteryTypes.player")}</span>
              <span className={scss.desk__countText}>{game_result_statistics.player}</span>
              <span className={scss.desk__tie}>{t("game:lotteryTypes.tie")}</span>
              <span className={scss.desk__countText}>{game_result_statistics.tie}</span>
            </div>
          </div>
        </div>
      </div>
      {!showLimit ? (
        <RoadMap
          {...{
            results: history_result.split(""),
            config: isSmall ? smallConfig : config,
          }}
        />
      ) : (
        <SelectPicker isSmall={isSmall} deskLimits={deskLimits} />
      )}
      <StatusCard {...{ desk_status, game_mod, isSmall, is_vip }} />
    </Container>
  );
};

export default Index;
