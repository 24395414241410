/**
 * 昵称修改弹窗
 */
import { FC, useState } from "react";
import Dialog from "@/components/Dialog";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import styled from "styled-components";
import { Column, Row } from "@/components/flex";
import MyButton from "@/components/MyButton";
import OInput from "@/components/OInput";
import * as Modals from "@/components/Header/Modals"
import { useAppDispatch } from "@/store";
import { setNick } from "@/store/slices/auth.slice";
import { message } from '@/components/Message'
import { useTranslation } from "react-i18next";
import { countByte } from '@/utils/tool'

const Input = styled(OInput)`
  margin: 40px 0 10px;
`

interface Props {
  visible?: boolean,
  onClose?: () => void
}

const ModifyNick: FC<Props> = ({ visible, onClose }) => {
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose })
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [nickname, setNickname] = useState('')
  const [validate, setValidate] = useState(null)
  const onConfirm = () => {
    // todo
    if (validate === null) {
      message.error(t('changeUsername:usernameMessage'))
    }
    if (validate) {
      dispatch(setNick({
        new_nickname: nickname
      })).then(() => {
        Modals.showConfirm({
          title: t('changeUsername:title'),
          content: t('changeUsername:usernameModalMessage'),
          onConfirm () {
            console.log(t('changeUsername:buttonConfirm'))
          },
          onCancel () {
            console.log(t('changeUsername:buttonCancel'))
          }
        })
      }).catch(() => {
        message.error(t('changeUsername:usernameErrorMessage'))
      }).finally(() => {
        setIsShow(false)
      })
    }
  }
  const rules = [
    {
      required: true,
      message: t('changeUsername:usernameMessage'),
      trigger: 'blur',
    },
    {
      pattern: /^\S{3,20}$/,
      message: t('changeUsername:usernameValidationMessage'),
      trigger: 'blur',
    },
  ]

  const onValidate = (b: boolean) => {
    setValidate(b)
  }

  return (
    <Dialog
      title={t('changeUsername:title')}
      visible={isShow}
      buttons={[
        {
          text: t('changeUsername:buttonCancel'),
          action: () => setIsShow(false)
        },
        {
          text: t('changeUsername:buttonConfirm'),
          disabled: !validate,
          action: onConfirm
        }
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}>
      <Column style={{ width: '427px', padding: '0 42px' }}>
        <Input label={t('changeUsername:usernameLabel')} value={nickname} onChange={setNickname} rules={rules}
          onValidate={onValidate} isChangeValidate/>
      </Column>
      {/* <Row style={{ width: '325px', margin: '10px auto 18px', padding: '15px 10px' }} justify="center" ailgn="center"> */}
      {/*   <MyButton cancel name={t('changeUsername:buttonCancel')} width={140} onClick={() => setIsShow(false)}/> */}
      {/*   <MyButton disable={!validate} styles={{ marginLeft: '25px' }} width={140} */}
      {/*     name={t('changeUsername:buttonConfirm')} onClick={onConfirm}/> */}
      {/* </Row> */}
    </Dialog>
  )
}

export default ModifyNick
