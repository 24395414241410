/**
 * 多台选择界面
 */
import scss from "@/components/Dialog/index.module.scss";
import { Column, Row } from "@/components/flex";
import { history } from "@/routers";
import { quitTraditionMod } from "@/services/game";
import { useAppSelector } from "@/store";
import { authSelector } from "@/store/slices/auth.slice";
import { setShowTablePick } from "@/store/slices/bet.slice";
import { setshowQuickBet } from "@/store/slices/luxury.slice";
import { ApplyServiceStatus } from "@/store/types/bet.d";
import { IStorage } from "@/utils";
import { enterClassicDesk, enterLuxuryDesk, leaveDesk } from "@/utils/functions/enterDesk";
import useFormat from "@/utils/hooks/useFormat";
import classNames from "classnames/bind";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { message } from "../Message";
import * as Modals from "./Modals";

const cn = classNames.bind(scss);

const HeaderRightGamingSingle: FC = () => {
  const dispatch = useDispatch();
  const routerNav = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { deskId } = useParams();

  const authState = useAppSelector(authSelector);

  const {
    deskInfo: { apply_basic_ing, desk_no, game_no },
    hotRoadList,
  } = useAppSelector((state) => state.bet);

  const { openLuxury } = useAppSelector((state) => state.config);

  const { balance } = useAppSelector((state) => state.auth);

  const fmtBalance = useFormat(balance);

  const confirm = async (data) => {
    const betPreference = localStorage.getItem("betPreference");
    if (Number(betPreference) === data) {
      return;
    }

    const inGameDesk = history?.location.pathname.includes("/luxuryBet") || pathname.includes("/classic");

    if (pathname === "/multiBet") return;

    let leaveDeskSuccess = true;

    // 如果在桌台内 先离桌
    if (inGameDesk) {
      leaveDeskSuccess = await leaveDesk(deskId);
    }

    // 离桌失败
    if (!leaveDeskSuccess) return;

    if (data) {
      enterLuxuryDesk(deskId).then(() => {
        IStorage.setItem("betPreference", data);
      });
      // routerNav(`/luxuryBet/${deskId}`);
    } else {
      enterClassicDesk(deskId).then(() => {
        IStorage.setItem("betPreference", data);
      });
    }
  };
  const isShow = () => {
    return !(authState.isVisitor || pathname === "/multiBet");
  };

  const quitTradition = async () => {
    try {
      console.log(desk_no, "desk_nodesk_nodesk_no");
      const { msg } = await quitTraditionMod({ desk_no, _isNeedLoading: true });

      if (msg === "success") {
        const leaveSuccess = await leaveDesk(deskId);

        leaveSuccess &&
          message.success({
            message: t("game:playerExistTraditionSuccess"),
            onAnimationend: () => routerNav("/tablePick"),
          });
      }
    } catch (err) {}
  };

  const toTablePick = async () => {
    const { pathname } = history.location;
    // 当前好路提示的桌子id集合
    const deskIds = Array.from(new Set(hotRoadList.map((hotRoad) => hotRoad.desk_id)));
    const inGameDesk = pathname.includes("/luxuryBet") || pathname.includes("/classic");

    const deskId = inGameDesk ? pathname.match(/\/(\d+)/)[1] : "";

    // 如果是在单台投注页面
    if (inGameDesk) {
      for (const desk_id of [deskId, ...deskIds]) {
        await leaveDesk(desk_id);
      }
    }
    dispatch(setshowQuickBet(false));
    routerNav("/tablePick");
  };

  return (
    <Row style={{ paddingRight: "28px" }}>
      {isShow() && apply_basic_ing === ApplyServiceStatus.Passed && (
        <button
          style={{ minWidth: 104, marginLeft: 20 }}
          className={cn(scss.header_btn)}
          onClick={() => {
            Modals.showConfirm({
              title: t("exitTraditionMode"),
              content: (
                <Column>
                  <span>{t("exitTraditionModeOrNot")}</span>
                  <Row>
                    <span>{t("currentOfflineNumber")}</span>
                    <span>{fmtBalance}</span>
                  </Row>
                </Column>
              ),
              onConfirm() {
                console.log(t("changeUsername:buttonConfirm"));
                quitTradition();
              },
              cancel: true,
              onCancel() {
                console.log(t("changeUsername:buttonCancel"));
              },
            });
          }}
        >
          {t("exitTraditionMode")}
        </button>
      )}
      {openLuxury && isShow() && (
        <button
          style={{ minWidth: 104, marginLeft: 20 }}
          className={cn(scss.header_btn)}
          onClick={() => Modals.showSelectBetting({ confirm })}
        >
          {t("buttonTableStyle")}
        </button>
      )}
      {/* {!process.env.REACT_APP_POGO && isShow() && (
        <button
          style={{ minWidth: 104, marginLeft: 20 }}
          className={cn(scss.header_btn)}
          onClick={() => {
            if (game_no) {
              Modals.showTips();
            } else {
              message.error("当前局还未开始");
            }
          }}
        >
          {t("buttonTip")}
        </button>
      )} */}
      {/* {!process.env.REACT_APP_POGO && isShow() && (
        <button
          style={{ minWidth: 104, marginLeft: 20 }}
          className={cn(scss.header_btn)}
          onClick={Modals.showChipChose}
        >
          {t("buttonChipStyle")}
        </button>
      )} */}
      {isShow() && (
        <button
          style={{ minWidth: 104, marginLeft: 20 }}
          className={cn(scss.header_btn)}
          onClick={() => dispatch(setShowTablePick(true))}
        >
          {t("buttonChangeTable")}
        </button>
      )}
      {apply_basic_ing !== ApplyServiceStatus.Passed && (
        <button style={{ minWidth: 104, marginLeft: 20 }} className={cn(scss.header_btn)} onClick={toTablePick}>
          {t("buttonReturn")}
        </button>
      )}
    </Row>
  );
};

export default HeaderRightGamingSingle;
