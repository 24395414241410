export const THEME_KEY = 'themeKey';
export const THEME = 'theme';
export const TEST = 'test';
export const TEST2 = 'test2';
export const AUTH = 'auth';
export const VALID_TYPE = 'validType';
export const IS_LOGIN = 'isLogin';
export const USER_INFO = 'userInfo';
export const ASSIGNMENT = 'assignment';
export const ASSIGNMENT_LIST = 'assignmentList';
export const WORK = 'work';