import React, { FC, useEffect, useMemo } from "react";
import { Column, Row } from "@/components/flex";
import styled from "styled-components";
import { showCasino, showBetType, showBetLimit } from "@/components/Header/Modals";
import DeskPick from "@/components/deskPick";
import { useAppDispatch, useAppSelector } from "@/store";
import {
  fetchChangeDeskList,
  setDeskPickOptions2,
  setDeskPickOptions,
  setShowTablePick,
} from "@/store/slices/bet.slice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import scss from "@/components/Dialog/index.module.scss";
import classNames from "classnames/bind";
import { DeskStatus, GameMod } from "../deskPick/types.d";
const cn = classNames.bind(scss);
interface Props {
  showSelectMask: (category: string) => void;
  onChoose?: (data: any) => void;
  slide?: boolean;
}

const Container = styled(Column)`
  background: #2d2d2d;
`;

const Selector = styled(Row)`
  width: 374px;
  align-self: flex-start;
  margin-bottom: 12px;

  > div {
    width: 108px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #fff;
    background: #171717;
    border-radius: 8px;
    margin-right: 12px;

    &:hover {
      background: linear-gradient(37.21deg, #eeab47 -24.69%, #b99454 56.84%);
    }
  }
`;

const Content = styled(Column)`
  margin: 0 20px;
  width: 786px;

  &.tableWrap {
    height: calc(100vh - 155px);
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
`;

const TableRow = styled(Row)`
  > div {
    &:nth-of-type(odd) {
      margin-right: 20px !important;
    }
  }
`;

const TablePick: FC<Props> = ({ showSelectMask, slide = false }) => {
  const {
    deskInfo: { desk_id },
    changeDesksInfo,
    lobbyDesksInfo,
  } = useAppSelector((state) => state.bet);

  const { isVisitor, account = "isVisitor" } = useAppSelector((state) => state.auth);
  const { multiDeskConfig } = useAppSelector((state) => state.config);

  const { t } = useTranslation();
  const casinos = {
    0: { label: t("common:buttonAllCasino"), value: 0 },
    5: { label: t("common:buttonSolaireCasino"), value: 5 },
  };
  const betTypes = {
    "0": { label: t("betType:typeAllServicesLabel"), value: 0 },
    "1": { label: t("betType:typeFastLabel"), value: 1 },
    "2": { label: t("betType:typeTraditionalLabel"), value: 2 },
    // "3": { label: t("betType:typeSmallLabel"), value: 3 },
  };

  const sort = {
    "": { label: t("betLimit:betLimitNotSortedLabel"), value: "" },
    asc: { label: t("betLimit:betLimitLowToHighLabel"), value: "asc" },
    desc: { label: t("betLimit:betLimitHighToLowLabel"), value: "desc" },
  };
  // 当前多台选中的
  const { currentDesk } = useAppSelector((state) => state.multi);
  const { deskPickOptions2, deskPickOptions } = useAppSelector((state) => state.bet);
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  // 按条件过滤桌子

  // 过滤掉已选的

  const currentDeskSelected = useMemo(() => {
    const tempCurrentDesk = multiDeskConfig[account] || [0, 0, 0, 0];

    // return !isVisitor ? tempCurrentDesk : currentDesk;
    return tempCurrentDesk;
  }, [isVisitor, account, multiDeskConfig, currentDesk]);

  const desksList = useMemo(() => {
    // 多台

    const desk_vo: any[] = slide ? changeDesksInfo.desk_vo : lobbyDesksInfo.desk_vo;

    if (pathname.includes("multiBet")) {
      // 多桌
      return desk_vo
        ?.filter((item) => item.club_id === 5)
        ?.filter(
          (item) =>
            !currentDeskSelected.includes(item.desk_id) &&
            (item.game_mod === GameMod.FastGame || item.game_mod === GameMod.SmallGame) &&
            item.desk_status !== DeskStatus.VipGame &&
            item.is_vip !== 1
        )
        .filter((item) => {
          let temp = true;
          if (slide) {
            if (deskPickOptions2.club_id && item.club_id !== deskPickOptions2.club_id) {
              temp = false;
            }
            if (deskPickOptions2.game_mod && item.game_mod !== deskPickOptions2.game_mod) {
              temp = false;
            }
          } else {
            if (deskPickOptions.club_id && item.club_id !== deskPickOptions.club_id) {
              temp = false;
            }
            if (deskPickOptions.game_mod && item.game_mod !== deskPickOptions.game_mod) {
              temp = false;
            }
          }
          if (temp) {
            return item;
          }
        });
    } else {
      // 单桌
      return desk_vo?.filter((item) => item.club_id === 5)?.filter((item) => desk_id !== item.desk_id);
    }
  }, [slide, changeDesksInfo, lobbyDesksInfo, pathname, desk_id, currentDesk]);

  useEffect(() => {
    dispatch(fetchChangeDeskList(deskPickOptions2));
  }, [deskPickOptions2]);

  const handleCasinoChoose = (club_id) => {
    if (slide) {
      dispatch(setDeskPickOptions2({ ...deskPickOptions2, club_id }));
    } else {
      dispatch(setDeskPickOptions2({ ...deskPickOptions, club_id }));
    }
  };

  const handleBetTypeChoose = (game_mod) => {
    if (slide) {
      dispatch(setDeskPickOptions2({ ...deskPickOptions2, game_mod }));
    } else {
      dispatch(setDeskPickOptions({ ...deskPickOptions2, game_mod }));
    }
  };
  const handleBetLimitChoose = (sort_rule) => {
    if (slide) {
      dispatch(setDeskPickOptions2({ ...deskPickOptions2, sort_rule }));
    } else {
      dispatch(setDeskPickOptions({ ...deskPickOptions2, sort_rule }));
    }
  };

  return (
    <Container>
      <div style={{ width: "100%" }} className={scss.header}>
        <span>{t("tablePick:title")}</span>
        <a className={scss.header__close} onClick={() => dispatch(setShowTablePick(false))} />
      </div>
      <Content>
        <Selector style={{ margin: "18px 0" }} justify="flex-start">
          {/* <button
            style={{ minWidth: 104 }}
            className={cn(scss.button, deskPickOptions2.club_id && scss.primary)}
            onClick={() => showCasino(handleCasinoChoose)}
          >
            {casinos[slide ? deskPickOptions2.club_id : deskPickOptions.club_id].label}
          </button> */}
          {!pathname.includes("multiBet") && (
            <button
              style={{ minWidth: 104, marginLeft: 20 }}
              className={cn(scss.button, deskPickOptions2.game_mod && scss.primary)}
              onClick={() => showBetType(handleBetTypeChoose)}
            >
              {betTypes[slide ? deskPickOptions2.game_mod : deskPickOptions.game_mod]?.label}
            </button>
          )}
          <button
            style={{ minWidth: 104, marginLeft: 20 }}
            className={cn(scss.button, deskPickOptions2.sort_rule && scss.primary)}
            onClick={() => showBetLimit(handleBetLimitChoose)}
          >
            {sort[slide ? deskPickOptions2.sort_rule : deskPickOptions.sort_rule]?.label ||
              t("betLimit:betLimitNotSortedLabel")}
          </button>
        </Selector>
      </Content>
      <Content ailgn="flex-start" className="tableWrap">
        <TableRow justify="space-between" wrap="wrap">
          {desksList?.map((item, index) => (
            <DeskPick {...{ ...item, isSmall: true }} key={index} count={18} style={{ marginBottom: "15px" }} />
          ))}
        </TableRow>
      </Content>
    </Container>
  );
};

export default TablePick;
