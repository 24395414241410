import { FC, useState } from "react";
import Dialog from "@/components/Dialog";
import { useDlgHooks } from "@/components/Header/Modals/hooks";
import styled from "styled-components";
import { Column, Row } from "@/components/flex";
import MyButton from "@/components/MyButton";
import OInput from "@/components/OInput";
import { gameAPI } from "@/services";
import * as Modals from "@/components/Header/Modals/index";
import { useParams } from "react-router-dom";
import { message } from "@/components/Message";
import { useTranslation } from "react-i18next";

interface Props {
  visible?: boolean;
  onClose?: () => void;
  enterGame?: () => void;
  changeTradition?: () => void;
  deskId: number;
}

const Input = styled(OInput)`
  margin-top: 24px;
`;

const ModifyPassword: FC<Props> = ({ visible, onClose, enterGame, deskId, changeTradition }) => {
  const { t } = useTranslation();
  const { isShow, setIsShow, close } = useDlgHooks({ visible, onClose });
  const [password, setPass] = useState<string>("");
  const [correct, setCorrect] = useState<boolean>(false);

  const rules = [
    {
      required: true,
      message: t("packageTableModal:messageError"),
      trigger: "blur",
    },
    {
      pattern: /^[a-zA-Z0-9]{6,20}$/,
      message: t("packageTableModal:passwordRequirements"),
      trigger: "blur",
    },
  ];

  const confirm = async () => {
    if (!correct) return;
    try {
      const { msg } = await gameAPI.applyVipDesk({ desk_id: deskId, desk_pwd: password, _isNeedLoading: true });
      if (msg === "success") {
        message.success(t("packageTableModal:messageSuccess"));
        setIsShow(false);

        if (changeTradition) {
          changeTradition();
        } else {
          setTimeout(enterGame, 1500);
        }
      }
    } catch (e) {}
  };
  return (
    <Dialog
      title={t("packageTableModal:title")}
      visible={isShow}
      zIndex={1000}
      buttons={[
        {
          text: t("packageTableModal:btnCancel"),
          action: () => setIsShow(false),
        },
        {
          disabled: !password,
          text: t("packageTableModal:btnConfirm"),
          action: confirm,
        },
      ]}
      onClose={() => setIsShow(false)}
      onAnimationend={close}
    >
      <Column style={{ width: "428px", padding: "0 42px" }}>
        <Input
          label={t("packageTableModal:label")}
          type="password"
          rules={rules}
          onValidate={setCorrect}
          value={password}
          onChange={(password) => setPass(password)}
        />
      </Column>
      {/* <Row */}
      {/*   style={{ */}
      {/*     width: "325px", */}
      {/*     margin: "46px auto 18px", */}
      {/*     padding: "15px 10px", */}
      {/*   }} */}
      {/*   justify="center" */}
      {/*   ailgn="center" */}
      {/* > */}
      {/*   <MyButton cancel name={t('packageTableModal:btnCancel')} width={140} onClick={() => setIsShow(false)}/> */}
      {/*   <MyButton styles={{ marginLeft: "25px" }} width={140} name={t('packageTableModal:btnConfirm')} */}
      {/*     onClick={confirm}/> */}
      {/* </Row> */}
    </Dialog>
  );
};

export default ModifyPassword;
