import {FC, useEffect, useState} from "react";
import wifi_best from '@/assets/images/common/wifi-best.png'
import wifi_good from '@/assets/images/common/wifi-good.png'
import wifi_bad from '@/assets/images/common/wifi-bad.png'
import styled from "styled-components";

// 圆形按钮
interface CycleBtnStyledProps {
  icon?: string,
  iconHover?: string,
  red?: boolean
}
const CycleBtn = styled.button<CycleBtnStyledProps>`
  position: relative;
  width: 49px;
  height: 49px;
  background: linear-gradient(to bottom, rgba(23, 23, 23, 0) 0, rgba(23, 23, 23, 0) 49px, rgba(15, 14, 12, 0), rgba(15, 14, 12, 1)) no-repeat;
  background-size: 49px 98px;
  border: none;
  border-radius: 50%;
  transition: background-position 300ms ease-in-out;

  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(${props => props.icon}) no-repeat;
    background-size: contain;
  }

  &:after {
    position: absolute;
    top: 12px;
    right: 12px;
    display: ${props => props.red ? 'block' : 'none'};
    content: '';
    width: 4px;
    height: 4px;
    background-color: #CB5460;
    border-radius: 2px;
  }

  &:hover {
    //background: linear-gradient(180deg, rgba(15, 14, 12, 0) 0%, #0F0E0C 100%);
    // background-position: 0 100%;

    &:before {
      background: url(${props => props.iconHover}) no-repeat;
      background-size: contain;
    }
  }
`
const Wifi: FC = () => {
  const [wifiStatus,setWifiStatus] = useState(wifi_best)
  useEffect(() => {
    window.ononline = () => {
      setWifiStatus(wifi_best)
    }
    window.onoffline = () => {
      setWifiStatus(wifi_bad)
    }
  }, [])
  return (
    <CycleBtn icon={wifiStatus} iconHover={wifiStatus}></CycleBtn>
  )
}

export default Wifi
